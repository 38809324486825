import { Vendor } from '@hq-app/user-management/models/vendor';
import { ContactInformation } from '@hq-core/models/contact-information';

export class Buyer {
    id: number;
    name: string;
    companyId: number;
    distributionCenter: string;

    constructor(options?: Partial<Buyer>) {
        Object.assign(this, options);
    }
}

export class Broker {
    id: number;
    name: string;
    contactInformation: ContactInformation;
    vendorAssignmentId?: number;
    buyer?: Buyer;
    interchangeId: string;
    qualifierId: string;
    enableEmailAlerts: boolean;
    isDemoAccount = false;
    vendors: Array<Vendor>;
    orderSubmissionId: string;
    purchaseOrderConfiguration: BrokerOrderCopyConfiguration;
    invoiceConfiguration: BrokerOrderCopyConfiguration;
    dateDeactivated: Date;

    constructor(options?: Partial<Broker>) {
        Object.assign(this, options);
    }
}

export class BrokerOrderCopyConfiguration {
    babelwayGateway: string;
    alertEmails: Array<string>;

    constructor(options?: Partial<BrokerOrderCopyConfiguration>) {
        Object.assign(this, options);
    }
}

export class BrokerUserAssignment {
    userId: number;
    username: string;
    firstName: string;
    lastName: string;
    email: string;
    brokerId: number;
    territoryId: number;
    territoryName: string;

    constructor(options?: Partial<BrokerUserAssignment>) {
        Object.assign(this, options);
    }
}

export class BrokerAssignment {
    id: number;
    vendorId: number;
    buyer: Buyer;
    broker: Broker;
    purchaseOrderStartDate: Date;
    invoiceStartDate: Date;

    constructor(options?: Partial<BrokerAssignment>) {
        Object.assign(this, options);
    }
}

export class RemoveBrokerEvent {
    brokerAssignment: BrokerAssignment;

    constructor(options?: Partial<RemoveBrokerEvent>) {
        Object.assign(this, options);
    }
}

export class BrokerRow {
    brokerId: number;
    brokerName: string;
    brokerAssignments: Array<BrokerAssignment>;

    constructor(options?: Partial<BrokerRow>) {
        Object.assign(this, options);
    }
}

export class BrokerAssignmentDialogData {
    allBrokers: Array<Broker>;
    allBuyers: Array<Buyer>;
    assignedBrokers: Array<BrokerAssignment>;
    purchaseOrderStartDate: Date;
    invoiceStartDate: Date;

    constructor(options?: Partial<BrokerAssignmentDialogData>) {
        Object.assign(this, options);
    }
}

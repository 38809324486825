import { Injectable } from '@angular/core';
import { CustomerLead, EmailTo } from '@hq-app/free-trial/models/customer-lead';
import { FreeTrialCustomerLeadResource } from '@hq-app/free-trial/resources/free-trial-customer-lead.resource';
import { SupportRequestResource } from '@hq-app/help/resources/support-request.resource';
import { User } from '@hq-core/models/user';
import moment from 'moment';
import { Observable } from 'rxjs';
import { Feedback } from '../models/feedback';
import { FeedbackResource } from '../resources/feedback.resource';

@Injectable({
    providedIn: 'root'
})
export class FeedbackService {

    constructor(
        private feedbackResource: FeedbackResource,
        private supportRequestResource: SupportRequestResource,
        private freeTrialCustomerLeadResource: FreeTrialCustomerLeadResource
    ) {
    }

    createFeedback(feedback: Feedback): Observable<Feedback> {
        return this.feedbackResource.create(feedback);
    }

    createSupportRequest(feedback: Feedback): Observable<Feedback> {
        return this.supportRequestResource.create(feedback);
    }

    createCustomerLead(customerLead: CustomerLead, emailSubject = ''): Observable<CustomerLead> {
        return this.freeTrialCustomerLeadResource.create(customerLead, emailSubject);
    }

    sendCustomerLeadEmail(user: User, emailSubject = ''): Observable<CustomerLead> {
        const customerLead = new CustomerLead({
            name: user.profile?.firstName + ' ' + user.profile?.lastName,
            company: user.currentStore?.name,
            phone: user.currentStore?.contactInformation?.phone,
            email: user.profile?.email,
            address: user.profile?.address,
            dateCompleted: moment(new Date()).toString(),
            emailTo: EmailTo.Sales
        });
        return this.createCustomerLead(customerLead, emailSubject);
    }
}

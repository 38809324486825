const notAlphaNumeric = '[^A-Za-z0-9\s]';

export const atLeastOneSpecialCharacterRegex = new RegExp(`.*${notAlphaNumeric}.*`);
export const atLeastOneUppercaseRegex = new RegExp(`.*[A-Z].*`);
export const atLeastOneLowercaseRegex = new RegExp(`.*[a-z].*`);
export const atLeastOneDigitRegex = new RegExp(`.*[0-9].*`);

export type AutocompleteState = 'on' | 'off';

export class PasswordChangeForm {
    oldPassword: string;
    newPassword: string;

    constructor(options?: Partial<PasswordChangeForm>) {
        Object.assign(this, options);
    }
}

export class PasswordResetForm {
    username: string;
    password?: string;
    verificationCode?: string;

    constructor(options?: Partial<PasswordResetForm>) {
        Object.assign(this, options);
    }
}

export enum PasswordChangeErrorName {
    NotAuthorizedException = 'NotAuthorizedException',
    LimitExceededException = 'LimitExceededException',
    CodeMismatchException = 'CodeMismatchException',
    ExpiredCodeException = 'ExpiredCodeException',
    UserNotFoundException = 'UserNotFoundException'
}

export class PasswordChangeError {
    name: PasswordChangeErrorName;

    constructor(options?: Partial<PasswordChangeError>) {
        Object.assign(this, options);
    }
}

export class PasswordVerificationDetails {
    destination: string;

    constructor(options?: Partial<PasswordVerificationDetails>) {
        Object.assign(this, options);
    }
}

export const passwordChangeErrorMessage = new Map<PasswordChangeErrorName, string>([
    [PasswordChangeErrorName.NotAuthorizedException, 'Please verify your current password was entered correctly.'],
    [PasswordChangeErrorName.LimitExceededException, 'Too many password changes; please wait and try again in an hour.']
]);

export const passwordResetErrorMessage = new Map<PasswordChangeErrorName, string>([
    [PasswordChangeErrorName.NotAuthorizedException, 'Username is disabled.'],
    [PasswordChangeErrorName.UserNotFoundException, 'Username does not exist.'],
    [PasswordChangeErrorName.LimitExceededException, 'Too many password resets; please wait and try again in an hour.'],
    [PasswordChangeErrorName.CodeMismatchException, 'Your verification code is incorrect; please verify it in the password reset email.'],
    [PasswordChangeErrorName.ExpiredCodeException, 'Your verification code has expired; please request another password reset.'],
]);

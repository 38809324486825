import { Injectable } from '@angular/core';

import { User } from '@hq-core/models/user';
import { UserGroup } from '@hq-core/models/user-group';
import { ThemeService } from '@hq-core/theme/theme.service';

@Injectable({
    providedIn: 'root'
})
export class FreeTrialLogoResolver  {
    constructor(
        private themeService: ThemeService
    ) {
    }

    resolve(): void {
        this.themeService.updateUserTheme(new User({ userGroup: UserGroup.OrderCentral }));
    }
}

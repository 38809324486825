<div>
    <p>
        <strong>Caution! </strong> You are impersonating {{username}}.
    </p>
    <p>
        Minutes Remaining: <strong class="time-left">{{timeLeft}}</strong>
    </p>
</div>


<button (click)="onStopImpersonating()"
        color="accent"
        mat-flat-button>
    Stop Impersonating
</button>

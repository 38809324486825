<mat-card class="section-card">
    <div class="row">
        <div class="navigation">
            <a mat-icon-button
               class="return-button"
               type="button"
               [routerLink]="returnUrl"
               (click)="return.emit()"
               [queryParams]="queryParams">
                <mat-icon>arrow_back</mat-icon>
            </a>
            <h2 class="page-name">
                {{pageName}}
            </h2>
            <ng-content select=".sub-page-icon"></ng-content>
        </div>
        <ng-content select=".sub-page-form"></ng-content>
        <ng-content select=".sub-page-action"></ng-content>
    </div>
    <ng-content></ng-content>
</mat-card>

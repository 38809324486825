import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'hq-custom-tooltip',
  templateUrl: './custom-tooltip.component.html',
  styleUrls: ['./custom-tooltip.component.scss']
})
export class CustomTooltipComponent {
    @Input() text: string;
    @Input() contentTemplate: TemplateRef<any>;
    @Input() data: any;

    getContext() {
        return { $implicit: this.data, data: this.data };
    }
}

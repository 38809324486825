import { UnitOfMeasure } from '@hq-app/vendor-ordering/models/credit-adjustments';

export class LineItem {
    lineNumber: string;
    sku: string;
    upc: string;
    gtin: string;
    description: string;
    casePackSize: number;
    unitPrice: number;
    unitOfMeasure: UnitOfMeasure;
    quantityOrdered: number;
    size: number;
    additionalUnitOfMeasure: UnitOfMeasure;
    ean: string;
    idQualifier1: string;
    productId1: string;
    idQualifier2: string;
    productId2: string;
    idQualifier3: string;
    productId3: string;
    memo?: string;
    srp: number;
    isbn: string;

    constructor(options?: Partial<LineItem>) {
        Object.assign(this, options);
    }
}

export function isSameLineNumber(lineItem: LineItem, updated: LineItem) {
    let isSame = false;
    isSame = lineItem.lineNumber === updated.lineNumber;
    return isSame;
}

export function getLineItemKey(lineItem: LineItem) {
    let result = 'upc';
    if (lineItem.upc) {
        result = 'upc';
    } else if (lineItem.sku) {
        result = 'sku';
    } else if (lineItem.gtin) {
        result = 'gtin';
    } else if (lineItem.ean) {
        result = 'ean';
    } else if (lineItem.idQualifier1) {
        result = lineItem.idQualifier1;
    } else if (lineItem.idQualifier2) {
        result = lineItem.idQualifier2;
    } else if (lineItem.idQualifier3) {
        result = lineItem.idQualifier3;
    }

    return result;
}

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthImpersonationService } from '@hq-core/auth/auth-impersonation.service';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthorizationInterceptor implements HttpInterceptor {
    constructor(
        private authImpersonateService: AuthImpersonationService,
        private authService: AuthService
    ) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.getToken().pipe(
            switchMap(token => {
                let request = req.clone();

                if (token) {
                    request = req.clone({
                        setHeaders: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                }

                return next.handle(request);
            })
        );
    }

    private getToken(): Observable<string> {
        return this.authImpersonateService.isImpersonating()
            ? of(this.authImpersonateService.getImpersonationToken())
            : this.authService.getAuthToken();
    }
}

import { Moment } from 'moment';

export class TransitDetails {
    deliveryDate: string;
    pickupDate: string;
    shipmentDate: string;

    constructor(options?: Partial<TransitDetails>) {
        Object.assign(this, options);
    }
}

export class TransitDetailsForm {
    transitType: TransitDetailType;
    transitDate: Moment;

    constructor(options?: Partial<TransitDetailsForm>) {
        Object.assign(this, options);
    }
}

export type TransitDetailType = 'deliveryDate' | 'pickupDate' | 'shipmentDate';

export class TransitDetailTypeOption {
    type: TransitDetailType;
    text: string;
}

export const transitDetailTypeOptions: Array<TransitDetailTypeOption> = [
    { type: 'deliveryDate', text: 'Current Schedule Delivery' },
    { type: 'shipmentDate', text: 'Current Schedule Ship' },
    { type: 'pickupDate', text: 'Requested Pickup' }
];

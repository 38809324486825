import { environment } from '@hq-environments/environment';

export class AppSettings {
    apis: ApiUrlCollection;
    baseUrl: string;

    constructor(options?: Partial<AppSettings>) {
        Object.assign(this, { environment: {} }, options);
    }
}

class ApiUrlCollection {
    order: string;
    user: string;
    vendorData: string;
    search: string;
    promo: string;
    report: string;
    message: string;
    activity: string;

    constructor(options?: Partial<ApiUrlCollection>) {
        Object.assign(this, options);
    }
}

export const appSettings = new AppSettings({
    apis: new ApiUrlCollection({
        order: buildRemoteUrl('orderapi'),
        user: buildRemoteUrl('userapi'),
        vendorData: buildRemoteUrl('vendordataapi'),
        search: buildRemoteUrl('searchapi'),
        promo: buildRemoteUrl('promoapi'),
        report: buildRemoteUrl('reportapi'),
        message: buildRemoteUrl('messageapi'),
        activity: buildRemoteUrl('activityapi'),

        // Local API URLs - do not check these in uncommented
        // order: `http://localhost:3952`,
        // ordering: `http://localhost:45440`,
        // user: `http://localhost:7777`,
        // vendorData: `http://localhost:21448`,
        // search: `http://localhost:15019`,
        // promo: `http://localhost:7888`,
        // report: `http://localhost:5000`,
    }),
    baseUrl: environment.baseUrl
});

function buildRemoteUrl(subDomain: string): string {
    return `https://${subDomain}${environment.urlSubDomain}.geniuscentral.com`;
}

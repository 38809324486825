import { Injectable } from '@angular/core';
import { AnalyticsEvent, AnalyticsEventParameters } from '@hq-app/retailer-analytics/models/analytics-event';
import { ProductTrendApiResponse, ProductTrendParameters } from '@hq-app/retailer-analytics/models/product-trend';
import { ProductSalesSummary, SalesSummary, VendorSalesSummary } from '@hq-app/retailer-analytics/models/sales-summary';
import { AnalyticsEventResource } from '@hq-app/retailer-analytics/resources/analytics-event.resource';
import { ProductSalesSummaryResource } from '@hq-app/retailer-analytics/resources/product-sales-summary.resource';
import { ProductTrendResource } from '@hq-app/retailer-analytics/resources/product-trend.resource';
import { SalesSummaryResource } from '@hq-app/retailer-analytics/resources/sales-summary.resource';
import { VendorSalesSummaryResource } from '@hq-app/retailer-analytics/resources/vendor-sales-summary.resource';
import { AnalyticsAction, AnalyticsCategory } from '@hq-core/models/analytics';
import { UserService } from '@hq-core/user/user.service';
import { ApiCollectionResponse, SearchState } from '@cia-front-end-apps/shared/api-interaction';
import moment from 'moment';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class SalesAnalyticsService {
    constructor(
        private userService: UserService,
        private salesSummaryResource: SalesSummaryResource,
        private analyticsEventResource: AnalyticsEventResource,
        private productTrendsResource: ProductTrendResource,
        private vendorSalesSummaryResource: VendorSalesSummaryResource,
        private productSalesSummaryResource: ProductSalesSummaryResource
    ) {
    }

    getSalesSummary(): Observable<SalesSummary> {
        return this.salesSummaryResource.get();
    }

    getVendorSalesSummary(): Observable<Array<VendorSalesSummary>> {
        return this.vendorSalesSummaryResource.getAll();
    }

    getTopProducts(): Observable<Array<ProductSalesSummary>> {
        return this.productSalesSummaryResource.getAll();
    }

    getProductTrends(searchState: SearchState, productTrendParameters: ProductTrendParameters): Observable<ProductTrendApiResponse> {
        return this.productTrendsResource.getAll(searchState, productTrendParameters);
    }

    getSearchEvents(): Observable<Array<AnalyticsEvent>> {
        return this.userService.getCurrentUser().pipe(
            switchMap(user => {
                return this.analyticsEventResource.getAll(
                    new SearchState({
                        sortBy: 'eventCount',
                        sortDirection: 'desc',
                        pageSize: 10
                    }),
                    new AnalyticsEventParameters({
                        userIds: [user.id],
                        eventCategories: [AnalyticsCategory.Product],
                        eventActions: [AnalyticsAction.Search],
                        startDate: moment().add(-90, 'days').toDate()
                    })
                );
            }),
            map(({ items }: ApiCollectionResponse<AnalyticsEvent>) => items)
        );
    }
}

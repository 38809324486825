import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[gcSelectOnClick]'
})
export class SelectOnClickDirective {
    @Input('gcSelectOnClick') isEnabled = true;

    constructor(private element: ElementRef) { }

    @HostListener('click') onClick() {
        if (this.isEnabled) {
            const nativeElement = this.element.nativeElement;
            const hasSelectFunction = !!(nativeElement?.select);

            if (hasSelectFunction) {
                nativeElement.select();
            }
        }
    }
}

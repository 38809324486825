<mat-toolbar color="accent"
             class="toolbar">
    <mat-toolbar-row class="username"
                     tabindex="0">
        {{username}}
    </mat-toolbar-row>
</mat-toolbar>

<mat-nav-list class="nav-list">
    <mat-list-item *ngFor="let navigationItem of navigationItems"
                   [routerLink]="navigationItem.path"
                   (keyup.enter)="onKeyPressed(navigationItem)"
                   (click)="onClickNavigationItem(navigationItem)">
        <mat-icon matListItemIcon>{{navigationItem.icon}}</mat-icon>
        <a matListItemTitle>{{navigationItem.displayName}}</a>
    </mat-list-item>
</mat-nav-list>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '@hq-core/models/app-settings';
import { Feedback } from '@hq-core/models/feedback';
import { DataResource } from '@hq-custom-types';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FeedbackResource implements DataResource<Feedback> {
    private baseUrl: string;
    private endpointName = 'feedback';

    constructor(private http: HttpClient, private appSettings: AppSettings) {
        this.baseUrl = `${this.appSettings.apis.promo}/${this.endpointName}`;
    }

    create(feedback: Feedback): Observable<Feedback> {
        const url = this.baseUrl;
        return this.http.post<Feedback>(url, feedback);
    }
}

import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { ApiSortDirection } from '../../api-interaction';
import { FilterMenuChangeEvent, SortOption } from '../filter-menu';

@Component({
    selector: 'gc-sort-option',
    templateUrl: './sort-option.component.html',
    styleUrls: ['./sort-option.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SortOptionComponent implements OnInit, OnChanges {
    @Input() sortOption: SortOption;
    @Input() isSelected = false;
    @Input() initialSortDirection: ApiSortDirection;
    @Output() selectOption = new EventEmitter<FilterMenuChangeEvent>();

    isDescending = false;
    private sortDirection: ApiSortDirection;

    ngOnInit(): void {
        const initialSortDirection = this.isSelected ? this.initialSortDirection : null;
        this.sortDirection = initialSortDirection || this.sortOption?.defaultDirection || 'asc';
        this.updateIconDirection();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.isSelected && !changes.isSelected.firstChange) {
            this.sortDirection = this.sortOption?.defaultDirection || 'asc';
            this.updateIconDirection();
        }
    }

    @HostListener('click')
    onSelectOption(): void {
        if (this.isSelected) {
            this.sortDirection = this.sortDirection === 'desc' ? 'asc' : 'desc';
            this.updateIconDirection();
        }
        this.isSelected = true;

        const event = new FilterMenuChangeEvent({
            sortOption: this.sortOption,
            direction: this.sortDirection
        });
        this.selectOption.emit(event);
    }

    private updateIconDirection(): void {
        this.isDescending = this.sortDirection === 'desc';
    }
}

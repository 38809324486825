import { NgModule } from '@angular/core';
import { AppSettings, appSettings } from '../app-settings';

@NgModule({
    providers: [
        {
            provide: AppSettings,
            useValue: appSettings
        }
    ]
})
export class AppSettingsModule {
}

import { DropdownOption } from '@hq-shared/models/dropdown-option';

export enum DialogType {
    Simple,
    FullWidth
}

export const warningClassName = 'warning';

export class DialogConfig {
    component: any;
    data: any = {};
    disableClose = false;
    type = DialogType.Simple;
    isDestructive = false;
    returnRef = false;

    constructor(options?: Partial<DialogConfig>) {
        Object.assign(this, options);
    }
}

export class AutocompleteDialogData {
    actionButtonText: string;
    dropdownPlaceholder: string;
    options: Array<DropdownOption>;
    selectedOptionId: number | string;
    title: string;
    hint: string;

    constructor(options?: Partial<AutocompleteDialogData>) {
        Object.assign(this, options);
    }
}

export const dialogTypeToClassName = new Map<DialogType, string>([
    [DialogType.Simple, 'simple-dialog'],
    [DialogType.FullWidth, 'full-width-dialog']
]);

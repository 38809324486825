import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Observable, of, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';

export class TextFilterChangeEvent {
    text: string;

    constructor(options?: Partial<TextFilterChangeEvent>) {
        Object.assign(this, options);
    }
}

export class ApiFilter {
    key: string | number;
    description: string;

    constructor(options?: Partial<ApiFilter>) {
        Object.assign(this, options);
    }
}

export type ApiSortDirection = 'asc' | 'desc';

/**
 * @description Standardized search parameters used by the APIs. This class should be extended by
 *  any search parameter object that will be sent to an API. The API endpoint will expect the
 *  individual search properties as query parameters, not as an object
 *  pageIndex - the results page from which to start
 *  pageSize - the number of results to be returned in each response
 *  search - the term on which to search
 *  sortBy - the property on which to sort the data
 *  sortDirection - 'asc' for ascending; 'desc' for descending
 */
export class SearchState {
    pageIndex?: number;
    pageSize?: number;
    search?: string;
    sortBy?: string;
    sortDirection?: ApiSortDirection;

    constructor(options?: Partial<SearchState>) {
        Object.assign(this, options);
    }
}

export class ObservableSearchState extends SearchState {
    pageSizes: Array<number>;
    readonly defaultSortDirection: ApiSortDirection;
    readonly defaultPageIndex: number;
    readonly defaultSortBy: string;
    readonly valueChanges: Observable<SearchState>;

    private readonly stateSubject = new Subject<SearchState>();
    private readonly allPageSizes: Array<number>;
    private readonly defaultPageSizes: Array<number>;
    private readonly defaultPageSize: number;

    constructor(options?: {
        pageIndex?: number;
        pageSize?: number;
        search?: string;
        sortBy?: string;
        sortDirection?: ApiSortDirection;
        defaultSortBy: string;
        defaultSortDirection?: ApiSortDirection;
        defaultPageIndex?: number;
        defaultPageSize?: number;
        allPageSizes?: Array<number>;
    }) {
        super(options);
        Object.assign(this, options);

        this.defaultPageIndex = options?.defaultPageIndex || 0;
        this.pageIndex = options?.pageIndex || this.defaultPageIndex;

        this.defaultPageSize = options?.defaultPageSize || 25;
        this.pageSize = options?.pageSize || this.defaultPageSize;

        this.sortBy = options?.sortBy || this.defaultSortBy;

        this.allPageSizes = options?.allPageSizes || [25, 50, 100];
        this.defaultPageSizes = [this.defaultPageSize];
        this.pageSizes = this.defaultPageSizes;

        this.defaultSortDirection = options?.defaultSortDirection || 'asc';
        this.sortDirection =
            options?.sortDirection || this.defaultSortDirection;
        this.valueChanges = this.stateSubject.asObservable();
    }

    emitChanges(): void {
        const searchState = this.getStateSnapshot();
        this.stateSubject.next(searchState);
    }

    adjustPagingForItemCount(itemCount: number): void {
        const hasMultiplePages = itemCount > this.defaultPageSize;
        if (hasMultiplePages) {
            this.pageSizes = this.allPageSizes;
        } else {
            this.pageSize = this.defaultPageSize;
            this.pageSizes = this.defaultPageSizes;
        }
    }

    updateState(newState: Partial<SearchState>): void {
        // This will not affect properties which were not updated
        Object.assign(this, newState);

        this.emitChanges();
    }

    updatePaging(event: PageEvent): void {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;

        this.emitChanges();
    }

    updateSort(event: Sort): void {
        this.sortBy = event.active;
        this.sortDirection =
            event.direction === ''
                ? this.defaultSortDirection
                : event.direction;
        this.pageIndex = this.defaultPageIndex;

        this.emitChanges();
    }

    updateFilter(event: TextFilterChangeEvent): void {
        this.search = event.text;
        this.pageIndex = this.defaultPageIndex;

        this.emitChanges();
    }

    resetState(): void {
        this.search = '';
        this.pageIndex = this.defaultPageIndex;
        this.sortBy = this.defaultSortBy;
        this.sortDirection = this.defaultSortDirection;

        this.emitChanges();
    }

    getStateSnapshot(): SearchState {
        return new SearchState({
            search: this.search,
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            sortBy: this.sortBy,
            sortDirection: this.sortDirection,
        });
    }

    destroy(): void {
        this.stateSubject.complete();
    }
}

export class ApiCollectionMetaData {
    elapsedTimeMs?: number;
    hasNextPage?: boolean;
    hasPreviousPage?: boolean;
    isFirstPage?: boolean;
    isLastPage?: boolean;
    itemCount?: number;
    itemsOnPage?: number;
    pageSize?: number;
    pageIndex?: number;
    pageCount?: number;

    constructor(options?: Partial<ApiCollectionMetaData>) {
        Object.assign(this, options);
    }
}
export class ApiCollectionResponse<T> {
    meta = new ApiCollectionMetaData();
    items = new Array<T>();
    constructor(options?: Partial<ApiCollectionResponse<T>>) {
        Object.assign(this, options);
    }
}

export class ParameterBuilder {
    static buildArrayParameter(
        collection: Array<any> = [],
        parameterName: string,
        params: HttpParams
    ): HttpParams {
        collection.forEach((item) => {
            const stringifiedValue =
                item === null || item === undefined ? 'null' : item.toString();
            params = params.append(parameterName, stringifiedValue);
        });
        return params;
    }
}

export enum ApiErrorCode {
    BadRequest = 400,
    NotFound = 404,
    Conflict = 409,
    Internal = 500,
}

export class ModelPropertyError {
    name: string;
    value: any;
    reason: string;

    constructor(options?: Partial<ModelPropertyError>) {
        Object.assign(this, options);
    }
}

export class ApiErrorResponse {
    title: string;
    status: ApiErrorCode;
    detail: string;
    errors: Array<ModelPropertyError>;

    constructor(options?: Partial<ApiErrorResponse>) {
        Object.assign(this, options);
    }
}

/**
 * @description Generally most components will respond to an Observable errors in the same way;
 * in some instances, they will need to recover from or handle differently specific error
 * conditions. The function translates an HttpErrorResponse to an error response with details about
 * the type of error as well as individual validation errors for any invalid fields.
 *
 * @param response - the response returned from an HttpClient call
 * @example
 *   // Resource
 *   replace(order: Order) {
 *     http.put<Order>(url, order)
 *        .pipe(
 *          catchError((response: HttpErrorResponse) => {
 *         const error = parseErrorResponse(response);
 *         return throwError(error); // RxJS method
 *       })
 *      );
 *   }
 *
 *   // Consumer
 *   updateOrder(order)
 *     .subscribe({
 *       next: (successResponse) => {},
 *       error: (errorResponse) => {
 *          // errorResponse.errors contains errors relating to specific fields in a model. These can be used to create form errors
 *       }
 *     });
 */
export function parseErrorResponse(
    response: HttpErrorResponse
): ApiErrorResponse {
    // Accounts for errors which are just an error message
    const hasErrorObject = !(typeof response.error === 'string');
    const errorTitle = hasErrorObject
        ? response.error.title
        : response.statusText;
    const errorDetail = hasErrorObject ? response.error.detail : response.error;
    const validationErrors = hasErrorObject
        ? response.error.errors
        : new Array<ModelPropertyError>();

    return new ApiErrorResponse({
        title: errorTitle,
        detail: errorDetail,
        status: response.status,
        errors: validationErrors,
    });
}

/**
 * Adds a catchError which returns the provided value if the request fails. This helps remove some overhead
 * in adding an error handler to an observable
 *
 * @param request The observable request to be handled
 * @param defaultValue The value the observable should return if it fails
 */
export function emitValueOnError<T>(
    request: Observable<T>,
    defaultValue: T | null
): Observable<T | null> {
    return request.pipe(catchError(() => of(defaultValue)));
}

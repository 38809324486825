<form [formGroup]="formGroup"
      class="form">
    <mat-form-field>
        <textarea class="text-field"
                  matInput
                  formControlName="message"
                  [class.rejected]="isRejected"
                  [maxlength]="maxLength"
                  [placeholder]="label"></textarea>
        <mat-error *ngIf="formGroup.get('message').hasError('required')">
            A message is required.
        </mat-error>
    </mat-form-field>
    <div class="character-count">
        Characters: {{formGroup.get('message').value.length}}/{{maxLength}}
        <span class="ccNote">Do not send sensitive credit card information in the memo section.</span>
    </div>
</form>

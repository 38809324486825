<form [formGroup]="formGroup">
    <div class="form-section">
        <div class="row">
            <mat-form-field class="field">
                <mat-label>Contact</mat-label>
                <input matInput
                       formControlName="contact">
            </mat-form-field>
            <mat-form-field class="field">
                <mat-label>Email</mat-label>
                <input matInput
                       formControlName="email">
                <mat-error *ngIf="formGroup.get('email').hasError('email')">
                    Must be a valid email.
                </mat-error>
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field class="field">
                <mat-label>Address 1</mat-label>
                <input matInput
                       required
                       formControlName="address1">
                <mat-error *ngIf="formGroup.get('address1').hasError('required')">
                    Field is required.
                </mat-error>
            </mat-form-field>
            <mat-form-field class="field">
                <mat-label>Address 2</mat-label>
                <input matInput
                       formControlName="address2">
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field class="field">
                <mat-label>City</mat-label>
                <input matInput
                       required
                       formControlName="city">
                <mat-error *ngIf="formGroup.get('city').hasError('required')">
                    Field is required.
                </mat-error>
            </mat-form-field>
            <mat-form-field class="field">
                <mat-label>State</mat-label>
                <input matInput
                       required
                       maxlength="2"
                       formControlName="state">
                <mat-error *ngIf="formGroup.get('state').hasError('required')">
                    Field is required.
                </mat-error>
            </mat-form-field>
            <mat-form-field class="field">
                <mat-label>Postal Code</mat-label>
                <input matInput
                       required
                       formControlName="postalCode">
                <mat-error *ngIf="formGroup.get('postalCode').hasError('required')">
                    Field is required.
                </mat-error>
            </mat-form-field>
        </div>

        <div class="row">
            <mat-form-field class="field">
                <mat-label>Country</mat-label>
                <mat-select required
                            formControlName="country">
                    <mat-option *ngFor="let country of countryOptions"
                                [value]="country">
                        {{country}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="formGroup.get('country').hasError('required')">
                    Field is required.
                </mat-error>
            </mat-form-field>
            <mat-form-field class="field">
                <mat-label>Phone</mat-label>
                <input *ngIf="!maskPhone"
                       matInput
                       type="tel"
                       required
                       formControlName="phone">
                <input *ngIf="maskPhone"
                       matInput
                       hqPhoneMask
                       type="tel"
                       required
                       formControlName="phone">
                <mat-error *ngIf="formGroup.get('phone').hasError('required')">
                    Field is required.
                </mat-error>
                <mat-hint *ngIf="maskPhone">
                    Numbers only: (000) 000-0000
                </mat-hint>
            </mat-form-field>
            <mat-form-field class="field">
                <mat-label>Fax</mat-label>
                <input matInput
                       type="tel"
                       formControlName="fax">
            </mat-form-field>
        </div>
    </div>
</form>

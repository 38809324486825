import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { OrderFilters, ProductFilters } from '@hq-app/ordering/models/order-filter';
import { FilterChangeEvent, FilterGroup } from '@hq-core/models/search-filter';
import { TextFilterChangeEvent } from '@cia-front-end-apps/shared/api-interaction';
import { SortOption, SortOptionChangeEvent } from '../models/sort';

@Component({
    selector: 'hq-legacy-order-toolbar',
    templateUrl: './legacy-order-toolbar.component.html',
    styleUrls: ['./legacy-order-toolbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LegacyOrderToolbarComponent {
    @Output() clearFilters = new EventEmitter<void>();
    @Output() changeFilter = new EventEmitter<FilterChangeEvent>();
    @Output() changeTextFilter = new EventEmitter<TextFilterChangeEvent>();
    @Output() changeSortOption = new EventEmitter<SortOptionChangeEvent>();

    @Input() sortOptions: Array<SortOption>;
    @Input() defaultSortType: string;
    @Input() openFilterGroup: string;
    @Input() filterGroups: Array<FilterGroup>;
    @Input() filters: OrderFilters | ProductFilters;
    @Input() textOverride: string;
    @Input() canUseFilter: boolean;
    @Input() filterDisplayText: string;

    onClearFilters(): void {
        this.clearFilters.emit();
    }

    onChangeFilter(event: FilterChangeEvent): void {
        this.changeFilter.emit(event);
    }

    onChangeTextFilter(event: TextFilterChangeEvent): void {
        this.changeTextFilter.emit(event);
    }

    onChangeSortOption(event: SortOptionChangeEvent): void {
        this.changeSortOption.emit(event);
    }
}

import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'hq-sub-page-link',
    templateUrl: './sub-page-link.component.html',
    styleUrls: ['./sub-page-link.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubPageLinkComponent {
    @HostBinding('class.sub-page-link') subPageLinkCssClass = true;
    @HostBinding('class.text-contrast') textContrastCssClass = false;
    @Input() heading: string;
    @Input() subheading: string;
    @Input() route?: string | Array<any>;
}

import { Injectable } from '@angular/core';
import { UserRedirection } from '@hq-core/models/user-redirection';
import { RedirectionResource } from '@hq-core/resources/redirection.resource';
import { ApiCollectionResponse, SearchState } from '@cia-front-end-apps/shared/api-interaction';
import { Observable, of } from 'rxjs';
import { map, publishLast, refCount } from 'rxjs/operators';
import { BaseUser, Settings, UpsertUser, User, UserRegionalDistributor } from '../models/user';
import { SettingsResource } from '../resources/settings.resource';
import { UserRegionalDistributorsResource } from '../resources/user-regional-distributors.resource';
import { UserResource } from '../resources/user.resource';
import { injectQuery, injectQueryClient, toPromise } from '@ngneat/query';
import { FeatureFlag } from '@hq-core/models/feature-flag';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private user: User;
    private userRequest: Observable<User>;
    private query = injectQuery();
    private queryClient = injectQueryClient();

    constructor(
        private userResource: UserResource,
        private settingsResource: SettingsResource,
        private redirectionResource: RedirectionResource,
        private userRegionalDistributorsResource: UserRegionalDistributorsResource
    ) { }

    /**
     * @description Returns the user who is currently logged in. If the user information has already
     * been retrieved, it will returned from the cache; otherwise it will be retrieved from the
     * server.
     */
    getCurrentUser(): Observable<User> {
        const hasLoadedCurrentUser = !!(this.userRequest);

        if (!hasLoadedCurrentUser) {
            // publishLast().refCount() prevents multiple requests to the user resource (and by
            // extension, multiple calls to the API) by turning this request into a ReplaySubject.
            // Subsequent subscribers will get the result from the first call instead of querying
            // the API
            this.userRequest = this.userResource.get()
                .pipe(
                    publishLast(),
                    refCount(),
                    map(user => {
                        this.user = user;
                        // Map userRequest to cached user so any changes to the user object by the
                        // service are reflected in future calls to this function
                        this.userRequest = of(this.user);
                        return user;
                    })
                );
        }
        return this.userRequest;
    }

    /**
     * @description Returns the user information for the given user id
     * @param userId The user id of the user whose information will be returned
     */
    getDifferentUser(userId: number): Observable<User> {
        return this.userResource.get(userId);
    }

    getDifferentUserQuery(userId: number){
        return this.query({
            queryKey: ['user', userId] as const,
            queryFn: () => this.getDifferentUser(userId),
        });
    }

    prefetchDifferentUser(userId: number): void {
        this.queryClient.prefetchQuery({
            queryKey: ['user', userId] as const,
            queryFn: async () => {
                return toPromise({ source: this.getDifferentUser(userId) });
            }
        });
    }

    createUser(user: UpsertUser): Observable<User> {
        return this.userResource.create(user);
    }

    searchUser(parameters: SearchState): Observable<ApiCollectionResponse<BaseUser>> {
        return this.userResource.getAll(parameters);
    }

    replaceSettings(userId: number, settings: Settings): Observable<Settings> {
        return this.settingsResource.replace(userId, settings)
            .pipe(
                map((updatedSettings) => {
                    this.user.settings = updatedSettings;
                    return updatedSettings;
                })
            );
    }

    replaceRegionalDistributors(
        userId: number,
        distributors: Array<UserRegionalDistributor>
    ): Observable<Array<UserRegionalDistributor>> {
        return this.userRegionalDistributorsResource.replace(userId, distributors)
            .pipe(
                map((updatedDistributors) => {
                    this.user.distributors = updatedDistributors;
                    return updatedDistributors;
                })
            );
    }

    replaceUser(user: UpsertUser): Observable<User> {
        return this.userResource.replace(user);
    }

    changeSelfPassword(user: UpsertUser): Observable<User> {
        return this.userResource.replaceSelf(user);
    }

    clearCurrentUser(): void {
        this.user = null;
        this.userRequest = null;
    }

    getUserRedirection(id: string): Observable<UserRedirection> {
        return this.redirectionResource.get(id);
    }
}

<mat-form-field subscriptSizing="dynamic">
    <mat-label>Choose Action</mat-label>
    <mat-select [formControl]="selectControl"
                (selectionChange)="onActionSelected($event)">
        <mat-optgroup *ngFor="let group of groups"
                      [label]="group.name">
            <mat-option *ngFor="let action of group.actions"
                        [value]="action.value"
                        [disabled]="action.disabled">
                {{action.label}}
            </mat-option>
        </mat-optgroup>
    </mat-select>

    <mat-hint *ngIf="hint">{{hint}}</mat-hint>
</mat-form-field>

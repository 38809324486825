import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '@hq-core/models/app-settings';
import { BaseUser, UpsertUser, User } from '@hq-core/models/user';
import { DataResource } from '@hq-custom-types';
import { ApiCollectionResponse, parseErrorResponse, SearchState } from '@cia-front-end-apps/shared/api-interaction';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class UserResource implements DataResource<BaseUser> {
    private readonly baseUrl: string;
    private readonly selfUserId = 'self';

    constructor(private http: HttpClient, private appSettings: AppSettings) {
        this.baseUrl = `${this.appSettings.apis.user}`;
    }

    get(userId?: number): Observable<User> {
        const userIdSuffix = userId ? `${userId}` : this.selfUserId;
        const url = this.buildUrl(userIdSuffix);

        return this.http.get<User>(url);
    }

    getAll(parameters: SearchState): Observable<ApiCollectionResponse<BaseUser>> {
        const url = this.buildUrl();
        const requestParams = new HttpParams({
            fromObject: {
                pageIndex: (parameters.pageIndex || 0).toString(),
                pageSize: (parameters.pageSize || 25).toString(),
                search: parameters.search || '',
            }
        });

        return this.http.get<ApiCollectionResponse<BaseUser>>(url, { params: requestParams });
    }

    create(user: UpsertUser): Observable<User> {
        const url = this.buildUrl();
        return this.http.post<User>(url, user)
            .pipe(
                catchError(response => {
                    const error = parseErrorResponse(response);
                    return throwError(error);
                })
            );
    }

    replace(user: UpsertUser): Observable<User> {
        const url = this.buildUrl(user.id);

        return this.http.put<User>(url, user);
    }

    replaceSelf(user: UpsertUser): Observable<User> {
        const url = this.buildUrl(this.selfUserId);
        const params = new HttpParams({
            fromObject: {
                application: 'hq',
            }
        });

        return this.http.put<User>(url, user, { params });
    }

    private buildUrl(userId?: string | number): string {
        let url = `${this.baseUrl}/users`;

        if (userId) {
            url = `${url}/${userId}`;
        }

        return url;
    }
}

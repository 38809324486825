import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatSelectionListChange } from '@angular/material/list';
import { defaultFormChangeDebounceTime, uppercaseLetterRegex } from '@hq-core/models/forms';
import { FilterGroup, FilterKey, SearchFilter } from '@hq-core/models/search-filter';
import { denseFilterNumberGroupId, FilterGroupChangeEvent, alphanumericFilterGroups } from '@hq-shared/models/filter-menu';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'hq-dense-filter-menu',
    templateUrl: './dense-filter-menu.component.html',
    styleUrls: ['./dense-filter-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DenseFilterMenuComponent implements OnInit, OnChanges, OnDestroy {
    @Input() heading: string;
    @Input() filters: Array<SearchFilter>;
    @Input() selectedFiltersOverride: Array<SearchFilter>;
    @ViewChild('viewPort') viewPort: CdkVirtualScrollViewport;
    @Output() changeFilters = new EventEmitter<FilterGroupChangeEvent>();

    visibleFilters = new Array<SearchFilter>();
    selectedFilterGroupId = alphanumericFilterGroups[0].id;
    filterGroupRows: Array<Array<FilterGroup>>;
    formControl: FormControl;
    selectedFilters = new Array<SearchFilter>();
    selectedFilterIds = new Array<FilterKey>();

    private filterCache: Map<string, Array<SearchFilter>>;
    private readonly unsubscribe = new Subject<void>();
    private readonly selectedFilterChanges = new Subject<Array<SearchFilter>>();

    constructor(private cdRef: ChangeDetectorRef) { }

    ngOnInit(): void {
        const rowSize = 9;
        this.filterGroupRows = [
            alphanumericFilterGroups.slice(0, rowSize),
            alphanumericFilterGroups.slice(rowSize, rowSize * 2),
            alphanumericFilterGroups.slice(rowSize * 2)
        ];

        this.selectedFilters = this.selectedFiltersOverride ?? [];
        this.selectedFilterIds = this.selectedFilters.map(f => f.key);
        this.formControl = new FormControl(this.selectedFilters);

        this.selectedFilterChanges
            .pipe(
                debounceTime(defaultFormChangeDebounceTime),
                takeUntil(this.unsubscribe)
            )
            .subscribe((selectedFilters: Array<SearchFilter>) => {
                const filterChangeEvent = new FilterGroupChangeEvent({
                    selectedFilters: selectedFilters
                });
                this.changeFilters.emit(filterChangeEvent);
            });

        this.filterCache = this.buildFilterCache(this.filters);
        this.visibleFilters = this.getCachedFiltersForId(this.selectedFilterGroupId);
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.filters && !changes.filters.firstChange) {
            this.filterCache = this.buildFilterCache(this.filters);
            this.visibleFilters = this.getCachedFiltersForId(this.selectedFilterGroupId);
        }

        if (changes.selectedFiltersOverride && !changes.selectedFiltersOverride.firstChange) {
            if (this.selectedFiltersOverride) {
                this.selectedFilters = this.selectedFiltersOverride ?? [];
                this.selectedFilterIds = this.selectedFilters.map(f => f.key);
                this.formControl.setValue(this.selectedFilters, { emitEvent: false });

                this.selectedFilterChanges.next(this.selectedFilters);
            }

            this.visibleFilters = this.getCachedFiltersForId(this.selectedFilterGroupId);
        }
    }

    onChangeFilter(event: MatSelectionListChange): void {
        const changedListOption = event.options[0];
        const changedFilter = changedListOption.value as SearchFilter;

        if (changedListOption.selected) {
            this.selectedFilters = [
                ...this.selectedFilters,
                changedFilter
            ];
        }
        else {
            this.selectedFilters = this.selectedFilters.filter(filter => filter.key !== changedFilter.key);
        }

        this.selectedFilterIds = this.selectedFilters.map(f => f.key);
        this.selectedFilterChanges.next(this.selectedFilters);
    }

    onChangeFilterGroup(event: MatButtonToggleChange): void {
        this.selectedFilterGroupId = event.value;

        this.visibleFilters = this.getCachedFiltersForId(this.selectedFilterGroupId);
        this.formControl.setValue(this.selectedFilters, { emitEvent: false });

        // If the user has scrolled in the previous filter group, the scroll position needs to be reset or the new filters will load
        // at the same position as the previous list
        this.viewPort.scrollTo({ top: 0 });
    }

    onClearFilters(): void {
        this.selectedFilters = [];
        this.selectedFilterIds = [];
        this.formControl.setValue(this.selectedFilters, { emitEvent: false });

        this.selectedFilterChanges.next(this.selectedFilters);

        this.cdRef.detectChanges();
    }

    isSameFilter(filter1: SearchFilter, filter2: SearchFilter): boolean {
        return filter1.key === filter2.key;
    }

    filterTrackBy(index: number, filter: SearchFilter): FilterKey {
        return filter.key;
    }

    private getCachedFiltersForId(id: string): Array<SearchFilter> {
        return this.filterCache.get(id) ?? [];
    }

    private buildFilterCache(filters: Array<SearchFilter>): Map<string, Array<SearchFilter>> {
        return (filters ?? []).reduce((acc, current) => {
            const firstLetter = current.description[0];
            if (firstLetter) {
                let filterId = firstLetter.toUpperCase();
                if (!uppercaseLetterRegex.test(filterId)) {
                    filterId = denseFilterNumberGroupId;
                }

                const group = acc.get(filterId) ?? [];
                group.push(current);
                acc.set(filterId, group);
            }

            return acc;
        }, new Map<string, Array<SearchFilter>>());
    }
}

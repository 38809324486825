export const defaultFormChangeDebounceTime = 400;
export const slowerFormChangeDebounceTime = 1000;
export const wholeNumberRegex = /^[-]?\d+$/;
export const alphanumericRegex = /^[A-Za-z0-9]+$/;
export const nonSpaceCharacterRegex = /^\S+$/;
export const uppercaseLetterRegex = /[A-Z]/;

export type FormGroupValueFn = () => any;
export type FormUpdateTrigger = 'change' | 'submit' | 'blur';

export class FormFieldConfiguration {
    isRequired = false;
    isReadOnly = false;
    options: Array<string>;
    maxLength?: number;
    minLength?: number;
    placeholder: string;

    constructor(options?: Partial<FormFieldConfiguration>) {
        Object.assign(this, options);
    }
}

export class FormChangeEvent<T> {
    value: T;
    isValid: boolean;

    constructor(options?: Partial<FormChangeEvent<T>>) {
        Object.assign(this, options);
    }
}

export class FormSubmitEvent<T> {
    value: T;
    isValid: boolean;

    constructor(options?: Partial<FormSubmitEvent<T>>) {
        Object.assign(this, options);
    }
}

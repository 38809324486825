<a class="logo-container" [routerLink]="dashboardRoute">
    <img class="logo"
         [class.default-logo]="isDefaultLogo"
         [class.is-order-central]="isOrderCentral"
         [src]="logoUrl">
    <img class="smaller-logo"
         [class.is-order-central]="isOrderCentral"
         src="assets/sps-logo.svg">
</a>
<ng-container *ngIf="title">
    <span class="title-sep">|</span>
    <span class="title">{{title}}</span>
</ng-container>

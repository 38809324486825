export class UserAppModule {
    description: string;
    id: number;
    isSelected?: boolean;
    name: string;
    isDefaultModuleForRole: boolean;

    constructor(options?: Partial<UserAppModule>) {
        Object.assign(this, options);
    }
}

export enum AppModule {
    DetaSynq = 'detasynq',
    LocalVendors = 'local-vendor',
    CatalogManagement = 'catalog-management',
    Ordering = 'ordering',
    Search = 'search',
    Settings = 'settings',
    VendorMapping = 'vendor-mapping',
    UserManagement = 'user-management',
    SupplierGenius = 'supplier-genius',
    PromotionsManagement = 'promotions-management',
    BrokerCcOrders = 'broker-cc-orders',
    BrokerDataManagement = 'broker-data-management',
    BrokerServices = 'broker-services',
    Reports = 'reports',
    StoreManagementTools = 'store-management-tools',
    Analytics = 'analytics',
    SystemMessages = 'messages',
    Monitoring = 'monitoring',
    Advertising = 'advertising',
    EnterpriseSystems = 'enterprise-systems',
    TrainingResources = 'training-resources',
    InternalVendorMapping = 'internal-vendor-mapping',
    SupplierManagement = 'supplier-management',
    SavedItems = 'saved-items'
}

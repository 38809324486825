export enum ReleaseRole {
    Beta = 'beta',
    Developer = 'developer',
    Inside = 'inside',
    Released = 'released'
}

export const releaseRoleDisplayName = new Map<ReleaseRole, string>([
    [ReleaseRole.Beta, 'Beta'],
    [ReleaseRole.Developer, 'Developer'],
    [ReleaseRole.Inside, 'Inside'],
    [ReleaseRole.Released, 'Released']
]);

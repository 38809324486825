import { ContactInformation } from '@hq-core/models/contact-information';

export enum IdentificationQualifier {
    DunsNumber = '9',
    BuyerAssigned = '92'
}

export class ExtendedContactInformation extends ContactInformation {
    dunsNumber: string;
    buyerAssignedCode: string;
    identificationQualifier: IdentificationQualifier;

    constructor(options?: Partial<ExtendedContactInformation>) {
        super(options);
        Object.assign(this, options);
    }
}

import { FilterGroup, SearchFilter } from '@hq-core/models/search-filter';

export const denseFilterNumberGroupId = '0';

export class FilterGroupChangeEvent {
    selectedFilters: Array<SearchFilter>;
    groupId: string;

    constructor(options?: Partial<FilterGroupChangeEvent>) {
        Object.assign(this, options);
    }
}

const letterCodeForA = 'A'.charCodeAt(0);
const letterCodeForZ = 'Z'.charCodeAt(0);
const letterFilterGroups = Array.from(Array(letterCodeForZ - letterCodeForA + 1))
    .map((value, index) => {
        const letterValue = String.fromCharCode(index + letterCodeForA);
        return new FilterGroup({
            displayName: letterValue,
            id: letterValue
        });
    });

export const alphanumericFilterGroups = letterFilterGroups
    .concat(
        new FilterGroup({
            displayName: '0-9',
            id: denseFilterNumberGroupId
        })
    );

export const filterGroupId = {
    brand: 'brand',
    condition: 'condition',
    status: 'status'
};

import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { ProductStatus } from '@hq-app/search/models/product-search';
import { productStatusIcon } from '@hq-shared/models/product-status';

@Component({
    selector: 'hq-product-status-icon',
    templateUrl: './product-status-icon.component.html',
    styleUrls: ['./product-status-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductStatusIconComponent implements OnInit {
    @HostBinding('class.product-status-icon') cssClass = true;
    @Input() status: ProductStatus;
    @Input() tooltip: string;

    icon: string;
    isNewProduct: boolean;
    isDiscontinuedProduct: boolean;
    hasPriceChange: boolean;
    hasFocus: boolean;

    ngOnInit(): void {
        this.icon = productStatusIcon.get(this.status);
        this.isNewProduct = this.status === ProductStatus.New;
        this.isDiscontinuedProduct = this.status === ProductStatus.Discontinued;
        this.hasPriceChange = this.status === ProductStatus.PriceChange;
        this.hasFocus = this.status === ProductStatus.Focus;
    }
}

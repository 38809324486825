import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '@hq-core/models/app-settings';
import { UserRegionalDistributor } from '@hq-core/models/user';
import { DataResource } from '@hq-custom-types';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UserRegionalDistributorsResource implements DataResource<UserRegionalDistributor> {
    private baseUrl: string;
    private endpointName = 'regional-distributors';

    constructor(private http: HttpClient, private appSettings: AppSettings) {
        this.baseUrl = `${this.appSettings.apis.user}/users`;
    }

    replace(userId: number, distributors: Array<UserRegionalDistributor>): Observable<Array<UserRegionalDistributor>> {
        const url = `${this.baseUrl}/${userId}/${this.endpointName}`;
        return this.http.put<Array<UserRegionalDistributor>>(url, distributors);
    }
}

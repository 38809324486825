import { ActivatedRoute } from '@angular/router';
import { SearchState } from '@cia-front-end-apps/shared/api-interaction';
import { DropdownOption } from '@hq-shared/models/dropdown-option';
import { ContactInformation } from './contact-information';
import { EdiQualifierType } from '@hq-app/user-management/models/edi-qualifier';

export enum CatalogAccess {
    Full = 'full',
    Limited = 'limited',
    Closed = 'closed',
    SubscriptionOnly = 'subscriptionOnly'
}

export class ChainStore {
    legacyStoreId: number;
    id: number;
    name: string;
    isBuyiqEnabled: boolean;
    isDemoAccount = false;
    isBrokerSpecific: boolean;
    isSpsRetailer: boolean;
    catalogAccess: CatalogAccess;
    contactInformation = new ContactInformation();
    isFreeTrial: boolean;
    dateCreated: Date;
    dateDeactivated: Date;
    parentId: number;
    industryVerticals: Array<number>;
    senderId?: string;
    receiverId?: string;
    interchangeIdQualifier?: EdiQualifierType;

    constructor(options?: Partial<ChainStore>) {
        Object.assign(this, options);
    }
}

export class AssignChainStoreDialogData {
    chainName: string;
    route: ActivatedRoute;

    constructor(options?: Partial<AssignChainStoreDialogData>) {
        Object.assign(this, options);
    }
}

export class DepartmentDialogComponentData {
    chainId: number;
    storeId: number;
    id: number;
    departmentNumber: string;
    departmentDescription: string;
    action: string;

    constructor(options?: Partial<DepartmentDialogComponentData>) {
        Object.assign(this, options);
    }
}

export class ChainStoreSearchState extends SearchState {
    name?: string;
    address?: string;
    city?: string;
    state?: string;
    phone?: string;
    postalCode?: string;

    constructor(options?: Partial<ChainStoreSearchState>) {
        super(options);
        Object.assign(this, options);
    }

}

export const catalogAccessOptions: Array<DropdownOption<CatalogAccess>> = [
    new DropdownOption({
        displayName: 'Full',
        id: CatalogAccess.Full
    }),
    new DropdownOption({
        displayName: 'Subscription Only',
        id: CatalogAccess.SubscriptionOnly
    }),
    new DropdownOption({
        displayName: 'Limited',
        id: CatalogAccess.Limited
    }),
    new DropdownOption({
        displayName: 'Closed',
        id: CatalogAccess.Closed
    })
];

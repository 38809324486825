import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class FormValidators {
    static isNotWhitespace(control: AbstractControl): ValidationErrors | null {
        const hasValue = control.value && control.value.length;
        const isWhitespace = (control.value || '').trim().length === 0;
        return hasValue && isWhitespace ? { whitespace: true } : null;
    }

    static isNotDuplicate(valuesToCheck: Array<string> = []): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const isDuplicate = valuesToCheck.includes(control.value);
            return isDuplicate ? { duplicate: true } : null;
        };
    }
}

import { ApiSortDirection } from '../api-interaction';

export class SortOption {
    id: string;
    displayName: string;
    isSelected = false;

    /**
     * @description If true, this option will be selected when the sort is reset
     */
    isDefaultOption = false;

    /**
     * @description The direction this option will start on when selected. Defaults to 'asc'
     */
    defaultDirection: ApiSortDirection = 'asc';

    constructor(options?: Partial<SortOption>) {
        Object.assign(this, options);
    }
}

export type FilterOptionId = string | number;

export class FilterOption {
    id: FilterOptionId;
    displayName: string;
    isSelected = false;

    constructor(options?: Partial<FilterOption>) {
        Object.assign(this, options);
    }
}

export class FilterGroup {
    id: string;
    displayName: string;
    isExpanded = false;
    filters = new Array<FilterOption>();

    // Computed
    pixelHeight?: number;

    constructor(options?: Partial<FilterGroup>) {
        Object.assign(this, options);
    }
}

export class FilterMenuChangeEvent {
    selectedFilters: Map<string, Array<FilterOption>>;
    sortOption: SortOption;
    direction: ApiSortDirection;

    constructor(options?: Partial<FilterMenuChangeEvent>) {
        Object.assign(this, options);
    }
}

import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy, Renderer2, RendererFactory2 } from '@angular/core';
import { defaultThemeColor, defaultThemeLogoUrl, getUserLogoUrl, getUserThemeColor, Theme, ThemeColor } from '@hq-core/models/theme';
import { User } from '@hq-core/models/user';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ThemeService implements OnDestroy {
    private currentTheme: Theme;
    private readonly renderer: Renderer2;
    private readonly themeChanges: BehaviorSubject<Theme>;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        rendererFactory: RendererFactory2
    ) {
        this.renderer = rendererFactory.createRenderer(null, null);
        this.currentTheme = new Theme({
            color: defaultThemeColor,
            logoUrl: defaultThemeLogoUrl
        });
        this.themeChanges = new BehaviorSubject<Theme>(this.currentTheme);
    }

    ngOnDestroy(): void {
        this.themeChanges.complete();
        this.renderer.destroy();
    }

    updateUserTheme(user: User): void {
        const nextThemeColor = getUserThemeColor(user);
        const nextThemeCssClass = this.getThemeCssClass(nextThemeColor);
        const currentThemeCssClass = this.getThemeCssClass(this.currentTheme.color);
        const nextThemeLogo = getUserLogoUrl(user);

        this.setThemeCssClass(currentThemeCssClass, nextThemeCssClass);
        this.currentTheme = new Theme({
            color: nextThemeColor,
            logoUrl: nextThemeLogo
        });
        this.themeChanges.next(this.currentTheme);
    }

    getTheme(): Observable<Theme> {
        return this.themeChanges.asObservable();
    }

    getAccentColor(): string {
        const computedStyles = getComputedStyle(this.document.body);
        const accentColor = computedStyles.getPropertyValue('--accent-color');

        return accentColor;
    }

    getAccentColorMuted(): string {
        const computedStyles = getComputedStyle(this.document.body);
        const accentColor = computedStyles.getPropertyValue('--accent-color-muted');

        return accentColor;
    }

    private getThemeCssClass(theme: ThemeColor): string {
        return `theme-${theme}`;
    }

    private setThemeCssClass(currentClass: string, nextClass: string): void {
        this.renderer.removeClass(this.document.body, currentClass);
        this.renderer.addClass(this.document.body, nextClass);
    }
}

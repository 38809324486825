<h2 mat-dialog-title>{{title}}</h2>

<mat-dialog-content>
    {{content}}
</mat-dialog-content>

<mat-dialog-actions>
    <button *ngIf="cancelButtonText"
            mat-button
            mat-dialog-close
            color="accent"
            class="button">
        {{cancelButtonText}}
    </button>
    <button mat-flat-button
            *ngIf="actionButtonText"
            [mat-dialog-close]="true"
            [color]="isDestructive ? 'warn' : 'accent'"
            class="button">
        {{actionButtonText}}
    </button>
</mat-dialog-actions>

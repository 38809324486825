<div *ngIf="!route"
     class="link-container">
    <ng-container *ngTemplateOutlet="content"></ng-container>
</div>

<a *ngIf="route"
   [routerLink]="route"
   class="link-container anchor">
    <ng-container *ngTemplateOutlet="content"></ng-container>
</a>

<ng-template #content>
    <div class="label-container">
        <p class="heading">
            {{heading}}
        </p>
        <p class="subheading"
           *ngIf="subheading">
            {{subheading}}
        </p>
        <ng-content select=".subheading-information"></ng-content>
    </div>
    <ng-content select=".sub-page-information"></ng-content>
    <div class="button-container">
        <ng-content select=".sub-page-actions"></ng-content>
        <button mat-icon-button
                class="link-button">
            <mat-icon>arrow_right</mat-icon>
        </button>
    </div>
</ng-template>

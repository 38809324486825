<input [gcSelectOnClick]="true"
       class="input-field"
       type="number"
       step="1"
       [min]="min"
       [max]="max"
       [formControl]="formControl"
       [class.error]="hasError || isRejected">

<span *ngIf="totalPrice">{{totalPrice | currency}}</span>

<div class="validation-message-container">
    <p *ngIf="formControl.hasError('max')"
       class="validation-message">
        Max Qty is {{max}}
    </p>

    <p *ngIf="formControl.hasError('min')"
       class="validation-message">
        Min Qty is {{min}}
    </p>

    <p *ngIf="formControl.hasError('pattern')"
       class="validation-message">
        Whole numbers only
    </p>
</div>

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@hq-core/auth/auth.service';
import { AppModule } from '@hq-core/models/app-module';
import { routeParts } from '@hq-core/routes';
import { UserService } from '@hq-core/user/user.service';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AuthModuleGuard  {

    constructor(
        private authService: AuthService,
        private userService: UserService,
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.validateModule(route.data.appModule);
    }

    canLoad(route: Route): Observable<boolean> {
        return this.validateModule(route.data.appModule);
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.validateModule(childRoute.data.appModule);
    }

    /* Redirects to unauthorized page when the user does not have the appropriate module */
    private validateModule(appModule: AppModule): Observable<boolean> {
        return this.authService.isAuthenticated()
            .pipe(
                switchMap(isLoggedIn => {
                    return isLoggedIn ? this.checkUserHasModule(appModule) : of(isLoggedIn);
                })
            );
    }

    private checkUserHasModule(appModule: AppModule): Observable<boolean> {
        return this.userService.getCurrentUser()
            .pipe(
                map(user => {
                    const userHasModule = user.modules.includes(appModule);
                    if (!userHasModule) {
                        this.router.navigate([`/${routeParts.unauthorized}`]);
                    }

                    return userHasModule;
                })
            );
    }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { ActionItemGroup } from '@hq-shared/models/action-item';

@Component({
    selector: 'hq-action-items',
    templateUrl: './action-items.component.html',
    styleUrls: ['./action-items.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionItemsComponent implements OnChanges {
    @Input() groups: Array<ActionItemGroup>;
    @Input() disabled = false;
    @Input() hint: string;
    @Output() actionSelected = new EventEmitter();

    selectControl = new FormControl();

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.disabled && !changes.disabled.firstChange) {
            this.selectControl.enable();

            if (this.disabled) {
                this.selectControl.disable();
            }
        }
    }

    onActionSelected(event: MatSelectChange): void {
        this.actionSelected.emit(event.value);
        this.selectControl.reset();
    }
}

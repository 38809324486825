import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { VendorSalesSummary } from '@hq-app/retailer-analytics/models/sales-summary';
import { AppSettings } from '@hq-core/models/app-settings';
import { DataResource } from '@hq-custom-types';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class VendorSalesSummaryResource implements DataResource<VendorSalesSummary> {
    private baseUrl: string;

    constructor(private http: HttpClient, private appSettings: AppSettings) {
        this.baseUrl = `${this.appSettings.apis.report}/top-vendors`;
    }

    getAll(): Observable<Array<VendorSalesSummary>> {
        const url = this.baseUrl;
        return this.http.get<Array<VendorSalesSummary>>(url);
    }
}

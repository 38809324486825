import { LegacyVendor, SubscriptionType, SupplierType } from '@hq-core/models/legacy-vendor';

export class AssortmentVendor {
    id: number;
    name: string;
    catalogId: number;
    companyId: number;

    constructor(options?: Partial<AssortmentVendor>) {
        Object.assign(this, options);
    }
}

export class Vendor {
    id: number;
    legacyId: number;
    newVendorId: number;
    name: string;
    internalName: string;
    legacyVendor = new LegacyVendor();
    legacyVendorId: number;
    isDemoAccount = false;
    dateDeactivated: Date;
    assortmentVendor = new AssortmentVendor();
    totalCatalogs: number;

    constructor(options?: Partial<Vendor>) {
        Object.assign(this, options);
    }
}

export const supplierTypeDisplayName = new Map<SupplierType, string>([
    [SupplierType.Distributor, 'Distributor'],
    [SupplierType.DirectManufacturer, 'Direct Manufacturer'],
    [SupplierType.DistributedManufacturer, 'Distributed Manufacturer'],
    [SupplierType.DirectAndDistributedManufacturer, 'Direct and Distributed Manufacturer']
]);

export const subscriptionTypeDisplayName = new Map<SubscriptionType, string>([
    [SubscriptionType.PublicRequired, 'Public – Required Subscription'],
    [SubscriptionType.PublicOptional, 'Public – Optional Subscription'],
    [SubscriptionType.PrivateOptional, 'Private – Optional Subscription'],
    [SubscriptionType.PrivateRequired, 'Private – Required Subscription']
]);

import { Injectable } from '@angular/core';
import { environment } from '@hq-environments/environment';
import { datadogRum } from '@datadog/browser-rum';
import { User } from '@hq-core/models/user';

@Injectable({
    providedIn: 'root'
})
export class DatadogRumService {
    initialize(): void {
        datadogRum.init({
            applicationId: environment.dataDog.applicationId,
            clientToken: environment.dataDog.clientToken,
            site: environment.dataDog.site,
            service: 'hq',
            env: environment.useProductionMode ? 'production' : 'development',
            version: environment.buildVersion,
            sampleRate: 100,
            trackInteractions: true
        });
    }

    setUser(user: User): void {
        datadogRum.setUser({
            id: user.id.toString(),
            name: user.profile.username,
            email: user.profile.email,
            role: user.appRole,
            chain: user.chain,
            store: user.currentStore?.id,
            storeName: user.currentStore?.name,
        });
    }
}

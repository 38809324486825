export class ClientStatus {
    id: number;
    name: string;

    constructor(options?: Partial<ClientStatus>) {
        Object.assign(this, options);
    }
}

export enum OrderStatus {
    Open = 20,
    Submitted = 22,
    SubmittedAlternate = 5,
    Received = 2,
    Error = 4,
    Rejected = 201,
    Deleted = 28,
    Queued = 22,
    Merged = 26,
    MarkSubmitted = 27,
    Unsubscribed = 56
}

export enum ClientOrderStatus {
    None = 0,
    Open = 1,
    Submitted = 2,
    Received = 3,
    Accepted = 4,
    Shipped = 5,
    Invoiced = 6,
    Deleted = 7,
    Merged = 8,
    MarkedAsSubmitted = 9,
    Rejected = 10,
    New = 11,
    BrokerProcessingStarted = 12,
    BrokerProcessingCompleted = 13,
    Queued = 14,
    Failed = 15,
    PostedForSubmission = 16,
    Pending = 17,
    Replaced = 18,
    Cancelled = 19,
    InvoiceSentToBroker = 20,
    Processing = 21,
    Creating = 22,
    MarkedDeleted = 23,
    Unconfirmed = 24,
    Unsubscribed = 25
}

export const statusToDisplayName = new Map<number, string>([
    [ClientOrderStatus.None, 'Unknown'],
    [ClientOrderStatus.Open, 'Open'],
    [ClientOrderStatus.Submitted, 'Submitted'],
    [ClientOrderStatus.Received, 'Received'],
    [ClientOrderStatus.Accepted, 'Accepted'],
    [ClientOrderStatus.Shipped, 'Shipped'],
    [ClientOrderStatus.Invoiced, 'Invoiced'],
    [ClientOrderStatus.Deleted, 'Deleted'],
    [ClientOrderStatus.MarkedDeleted, 'Marked Deleted'],
    [ClientOrderStatus.Merged, 'Merged'],
    [ClientOrderStatus.MarkedAsSubmitted, 'Marked as Submitted'],
    [ClientOrderStatus.Rejected, 'Rejected'],
    [ClientOrderStatus.New, 'New'],
    [ClientOrderStatus.BrokerProcessingStarted, 'In Process'],
    [ClientOrderStatus.BrokerProcessingCompleted, 'Completed'],
    [ClientOrderStatus.Queued, 'Queued'],
    [ClientOrderStatus.Failed, 'Error'],
    [ClientOrderStatus.PostedForSubmission, 'Posted For Submission'],
    [ClientOrderStatus.Pending, 'Pending'],
    [ClientOrderStatus.Replaced, 'Replaced'],
    [ClientOrderStatus.Cancelled, 'Cancelled'],
    [ClientOrderStatus.Processing, 'Processing'],
    [ClientOrderStatus.Creating, 'Creating'],
    [ClientOrderStatus.InvoiceSentToBroker, 'Invoiced'],
    [ClientOrderStatus.Unconfirmed, 'Unconfirmed'],
    [ClientOrderStatus.Unsubscribed, 'Unsubscribed']
]);

export function getOrderStatusDisplayName(clientStatus: ClientStatus): string {
    const statusId = statusToDisplayName.has(clientStatus.id) ? clientStatus.id : ClientOrderStatus.None;
    return statusToDisplayName.get(statusId);
}

export class StatusOption {
    id: ClientOrderStatus;
    label: string;

    constructor(options?: Partial<StatusOption>) {
        Object.assign(this, options);
    }
}

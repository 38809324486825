import { Injectable, OnDestroy } from '@angular/core';
import * as LaunchDarkly from 'launchdarkly-js-client-sdk';
import { LDFlagValue, LDSingleKindContext } from 'launchdarkly-js-client-sdk';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { User as OCUser } from '@hq-core/models/user';
import { User as InAisleUser } from '@buyiq-core/models/user';
import { environment } from '@cia-front-end-apps/shared/environment';

@Injectable({
    providedIn: 'root'
})
export class FeatureFlagService implements OnDestroy {
    client: LaunchDarkly.LDClient;

    async ngOnDestroy() {
        await this.client.close();
    }

    initialize(): void {
        const user = {
            anonymous: true
        } as LDSingleKindContext;
        this.client = LaunchDarkly.initialize(environment.launchDarklyClientId, user);
    }

    identifyUser(user: OCUser | InAisleUser): void {
        const ldContext: LDSingleKindContext = {
            kind: 'user',
            key: `${user.id}`,
            anonymous: false,
            email: user.profile.email,
            name: user.profile.username,
            storeName: user.currentStore?.name ?? '',
            storeId: user.currentStore?.id ?? '',
            chain: user.chain?.name ?? '',
            chainId: user.chain?.id ?? '',
            fullName: user.profile?.firstName && user.profile?.lastName ? `${user.profile.firstName} ${user.profile.lastName}` : '',
            broker: this.isOrderCentralUser(user) ? user.broker?.name ?? '' : '',
            brokerId: this.isOrderCentralUser(user) ? user.broker?.id ?? '' : '',
            vendor: this.isOrderCentralUser(user) ? user.vendor?.name ?? '' : '',
            vendorId: this.isOrderCentralUser(user) ? user.vendor?.id ?? '' : '',
            _meta: {
                privateAttributes: []
            }
        };
        this.client.identify(ldContext);
    }

    getFlag(flagKey: string, defaultValue: LDFlagValue = false): Observable<LDFlagValue> {
        const fetchFlag = new Subject<void>();
        this.client.on(`change:${flagKey}`, () => {
            fetchFlag.next();
        });
        this.client.waitUntilReady().then(() => {
            fetchFlag.next();
        });
        return fetchFlag.pipe(
            map(() => {
                return this.client.variation(flagKey, defaultValue);
            })
        );
    }

    private isOrderCentralUser(user: OCUser | InAisleUser): user is OCUser {
        return (user as OCUser).broker !== undefined || (user as OCUser).vendor !== undefined;
    }
}

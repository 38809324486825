import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '@hq-core/models/app-settings';
import { DataResource } from '@hq-custom-types';
import { Observable } from 'rxjs';
import { UserRedirection } from '../models/user-redirection';

@Injectable({
    providedIn: 'root'
})
export class RedirectionResource implements DataResource<UserRedirection> {
    private readonly baseUrl: string;

    constructor(private http: HttpClient, private appSettings: AppSettings) {
        this.baseUrl = `${this.appSettings.apis.user}/redirection`;
    }

    get(id: string): Observable<UserRedirection> {
        const url = `${this.baseUrl}/${id}`;
        return this.http.get<UserRedirection>(url);
    }
}

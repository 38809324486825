import { animate, state, style, transition, trigger } from '@angular/animations';

/**
 * @description Provides a reusable way to animate a progress bar inside of components. The progress
 * bar will add a 5px padding-bottom so that its transition on and off the screen is smooth and
 * doesn't affect other elements.
 * @example
 * // component.html
 * <mat-progress-bar [@progressBarState]="loadState"></mat-progress-bar>
 *
 * // component.ts
 * @Component({
 *   animations: [
 *    progressBarAnimation
 *  ]
 * })
 * export class MyComponent {
 *   loadState: LoadState = 'done';
 *   // Start animation
 *   this.loadState = 'loading';
 *   // End animation
 *   this.loadState = 'done';
 */

export type ProgressBarState = 'active' | 'idle';

const progressBarState = 'progressBarState';
const progressBarHeight = '5px';
const activeState: ProgressBarState = 'active';
const idleState: ProgressBarState = 'idle';

const transitionDoneToLoading = transition(`${idleState} => ${activeState}`, animate('100ms ease-in'));
const transitionLoadingToDone = transition(`${activeState} => ${idleState}`, animate('100ms ease-out'));

// Use this one for inline or custom progress bars that don't use the global version
export const inlineProgressBarAnimation = trigger(progressBarState, [
    state(activeState, style({
        opacity: 1,
        height: '*',
        paddingBottom: 0
    })),
    state(idleState, style({
        opacity: 0,
        height: 0,
        paddingBottom: progressBarHeight
    })),
    transitionDoneToLoading,
    transitionLoadingToDone
]);

<mat-card [class.text-contrast]="color"
          [class.can-hover]="canHover"
          [ngClass]="cardColorClass"
          (click)="onClickCard()">
    <mat-icon>{{icon}}</mat-icon>
    <div>
        <h1 class="items">{{items}}</h1>
        <h3 class="caption">{{caption}}</h3>
    </div>
</mat-card>
<p *ngIf="subtext"
   class="subtext">
    {{subtext}}
</p>

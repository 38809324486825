import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataResource } from '@hq-custom-types';
import { Observable } from 'rxjs';
import { AssortmentImage } from '@cia-front-end-apps/shared/product-image-provider/assortment-image';
import { AppSettings } from '@cia-front-end-apps/shared/app-settings';

@Injectable({
    providedIn: 'root'
})
export class AssortmentImageResource implements DataResource<AssortmentImage> {
    private baseUrl: string;

    constructor(private http: HttpClient, private appSettings: AppSettings) {
        this.baseUrl = `${this.appSettings.apis.search}`;
    }

    get(imageUrl: string): Observable<AssortmentImage> {
        const url = `${this.baseUrl}/media-url`;
        const params = new HttpParams({
            fromObject: {
                imageUrl: encodeURIComponent(imageUrl)
            }
        });
        return this.http.get<AssortmentImage>(url, { params });
    }
}

import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ProgressBarState } from './progress-bar';

@Injectable({
    providedIn: 'root'
})
export class ProgressBarService implements OnDestroy {
    private readonly state = new Subject<ProgressBarState>();

    ngOnDestroy(): void {
        this.state.complete();
    }

    getState(): Observable<ProgressBarState> {
        return this.state.asObservable();
    }

    startLoadingAnimation(): void {
        this.state.next('active');
    }

    finishLoadingAnimation(): void {
        this.state.next('idle');
    }
}

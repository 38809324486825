export class DropdownOption<T = number | string> {
    displayName: string;
    id: T;
    subtext?: string;
    disabled?: boolean;
    selected?: boolean;

    constructor(options?: Partial<DropdownOption<T>>) {
        Object.assign(this, options);
    }
}

/**
 * @description Used in MatAutocomplete to display the value of a selected option
 * @param option The dropdown option from which to extract a display value
 */
export function displayByName(option: DropdownOption): string {
    return option ? option.displayName : '';
}

/**
 * @description Performs a case-insensitive filter on the displayName of collection of dropdown options
 * @param options The collection of options to filter
 * @param textFilter The value to filter the options on
 */
export function filterByDisplayName(options: Array<DropdownOption> = [], textFilter = ''): Array<DropdownOption> {
    return options.filter(option => {
        return option.displayName.toLowerCase().includes(textFilter.toLowerCase());
    });
}

/**
 * @description Maps an object to a DropdownOption
 * @param id The value to map to id
 * @param displayName The value to map to displayName
 */
export function mapToDropdownOption<T>(id: T, displayName: string): DropdownOption<T> {
    return new DropdownOption<T>({
        displayName: displayName,
        id: id
    });
}

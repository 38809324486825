// The default environment represents local development. The contents of this file will be replaced
// during the build/serve process with the specified environment.

const isLocalHost = window.location.hostname.indexOf('localhost') > -1;
const isDevelopment = isLocalHost
    || window.location.hostname.indexOf('dev') > -1
    || (window.location.hostname.split('.').slice(-2).join('.') === 'spsc.io');

const useProductionMode = !isDevelopment;
const urlSubDomain = isDevelopment
    ? '.dev'
    : '';
const baseUrl = `${window.location.protocol}://${window.location.host}`;
const launchDarklyClientId = isDevelopment
    ? '61df0e8e10ca3613630c1dc6'
    : '61df0e8e10ca3613630c1dc7';


export const environment = {
    useProductionMode,
    urlSubDomain,
    baseUrl,
    launchDarklyClientId
};

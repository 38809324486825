<div class="drop-zone"
     hqDragAndDrop
     (dropFile)="onDropFile($event)">
    <p class="title">
        Drag and Drop a File or
        <label for="file-upload"
               class="upload">Import</label>
        <input id="file-upload"
               #fileInput
               class="file"
               type="file"
               multiple="multiple"
               (change)="onSelectFile($event)" />
    </p>
    <p *ngFor="let requirement of fileRequirements"
       class="requirement">
        {{requirement}}
    </p>
</div>

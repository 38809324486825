import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { AnalyticsService } from '@hq-core/analytics/analytics.service';
import { CustomIconService } from '@hq-core/custom-icon/custom-icon.service';
import { FeatureFlagService } from '@cia-front-end-apps/shared/feature-flag/feature-flag.service';
import { NavigationService } from '@hq-core/navigation/navigation.service';
import { routeParts } from '@hq-core/routes';
import { filter, Subject, switchMap, tap } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { AuthService } from '@hq-core/auth/auth.service';
import { UserService } from '@hq-core/user/user.service';
import { injectQueryClient } from '@ngneat/query';

@Component({
    selector: 'hq-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {
    isLoginPage: boolean;
    private readonly unsubscribe = new Subject<void>();
    private queryClient = injectQueryClient();

    constructor(
        private featureFlagService: FeatureFlagService,
        private navigationService: NavigationService,
        private analyticsService: AnalyticsService,
        private customIconService: CustomIconService,
        private authService: AuthService,
        private userService: UserService
    ) {
    }

    ngOnInit(): void {
        this.navigationService.startNavigationMonitor();
        this.analyticsService.initialize();
        this.customIconService.initialize();
        this.featureFlagService.initialize();

        this.navigationService.currentState
            .pipe(
                takeUntil(this.unsubscribe)
            )
            .subscribe(navigationState => {
                const currentUrl = navigationState.sanitizedUrl || '';
                this.isLoginPage = currentUrl.includes(routeParts.login);
            });

        this.authService.getLoginState()
            .pipe(
                distinctUntilChanged(),
                tap(hasLoggedIn => {
                    if (!hasLoggedIn) {
                        this.queryClient.removeQueries();
                    }
                }),
                filter(hasLoggedIn => hasLoggedIn),
                switchMap(() => this.userService.getCurrentUser()),
                takeUntil(this.unsubscribe)
            )
            .subscribe(user => this.featureFlagService.identifyUser(user));
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}

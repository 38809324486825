<mat-toolbar color="primary">
    <mat-toolbar-row>
        <button type="button"
                mat-icon-button
                [disabled]="!isAuthorized"
                (click)="onOpenNavigationMenu()">
            <mat-icon>
                menu
            </mat-icon>
        </button>
        <hq-page-title></hq-page-title>
    </mat-toolbar-row>
</mat-toolbar>

<hq-impersonation-bar *ngIf="isImpersonating">
</hq-impersonation-bar>

<mat-progress-bar [mode]="progressBarMode"
                  [value]="100"
                  color="accent"
                  class="progress-bar">
</mat-progress-bar>

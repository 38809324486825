import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { User } from '../models/user';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root'
})
export class UserResolver  {

    constructor(private userService: UserService) { }

    resolve(): Observable<User> {
        return this.userService.getCurrentUser();
    }
}

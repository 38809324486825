import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

@Component({
    selector: 'hq-toggle-form-field',
    templateUrl: './toggle-form-field.component.html',
    styleUrls: ['./toggle-form-field.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToggleFormFieldComponent implements OnInit, OnChanges {
    @Input() label: string;
    @Input() hasError?: boolean;
    @Input() errorMessage?: string;
    @Input() disabled?: boolean;
    @Input() value?: boolean;
    @Output() markAsInitialized = new EventEmitter<AbstractControl>();

    formControl: FormControl;

    ngOnInit(): void {
        const formControl = new FormControl({
            value: this.value || false,
            disabled: this.disabled || false
        });

        this.formControl = formControl;
        this.markAsInitialized.emit(formControl);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.disabled && !changes.disabled.firstChange) {
            this.disabled ?
                this.formControl?.disable() :
                this.formControl?.enable();
        }

        if (changes.stateOverride && !changes.stateOverride.firstChange) {
            this.formControl?.patchValue(this.value);
        }
    }
}

<form [formGroup]="formGroup">
    <div class="row">
        <mat-form-field class="field">
            <mat-label>Supplier Name</mat-label>
            <input matInput
                   formControlName="name"
                   maxlength="50">
            <mat-error *ngIf="formGroup.get('name').hasError('required')">
                This field is required
            </mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="hasExternalId"
                        class="field">
            <mat-label>Supplier ID</mat-label>
            <input matInput
                   formControlName="externalVendorId"
                   maxlength="50">
            <mat-error *ngIf="formGroup.get('externalVendorId').hasError('required')">
                This field is required
            </mat-error>
            <mat-error *ngIf="formGroup.get('externalVendorId').hasError('pattern')">
                Supplier ID can only contain numbers, letters, or spaces
            </mat-error>
        </mat-form-field>
    </div>
    <div class="row">
        <mat-form-field class="field">
            <mat-label>Contact</mat-label>
            <input matInput
                   formControlName="contact">
        </mat-form-field>
        <mat-form-field class="field">
            <mat-label>Email (orders will be delivered here)</mat-label>
            <input matInput
                   formControlName="email">
            <mat-error *ngIf="formGroup.get('email').hasError('required')">
                This field is required
            </mat-error>
            <mat-error *ngIf="formGroup.get('email').hasError('email') && !formGroup.get('email').hasError('required')">
                Please enter a valid email address
            </mat-error>
        </mat-form-field>
        <mat-form-field class="field">
            <mat-label>CC Email</mat-label>
            <input matInput
                   formControlName="emailCC">
            <mat-error *ngIf="formGroup.get('emailCC').hasError('email')">
                Please enter a valid email address
            </mat-error>
        </mat-form-field>
    </div>
    <div class="row">
        <mat-form-field class="field">
        <mat-label>Address 1</mat-label>
            <input matInput
                   formControlName="address1">
            <mat-error *ngIf="formGroup.get('address1').hasError('required')">
                This field is required
            </mat-error>
        </mat-form-field>
        <mat-form-field class="field">
            <mat-label>Address 2</mat-label>
            <input matInput
                   formControlName="address2">
        </mat-form-field>
    </div>
    <div class="row">
        <mat-form-field class="field">
        <mat-label>City</mat-label>
            <input matInput
                   formControlName="city">
            <mat-error *ngIf="formGroup.get('city').hasError('required')">
                This field is required
            </mat-error>
        </mat-form-field>
        <mat-form-field class="field">
            <mat-label>State</mat-label>
            <input matInput
                   formControlName="state">
            <mat-error *ngIf="formGroup.get('state').hasError('required')">
                This field is required
            </mat-error>
        </mat-form-field>
        <mat-form-field class="field">
            <mat-label>Zip Code</mat-label>
            <input matInput
                   formControlName="zip">
            <mat-error *ngIf="formGroup.get('zip').hasError('required')">
                This field is required
            </mat-error>
        </mat-form-field>
        <mat-form-field class="field">
            <mat-label>Phone</mat-label>
            <input matInput
                   formControlName="phone">
        </mat-form-field>
    </div>
    <div class="row">
        <mat-form-field class="field">
            <mat-label>Min Order Amount</mat-label>
            <input matInput
                   formControlName="minimumOrderAmount"
                   type="number"
                   min="0">
        </mat-form-field>
        <mat-form-field class="field">
            <mat-label>Free Shipping Threshold</mat-label>
            <input matInput
                   formControlName="freeShippingAmount"
                   type="number"
                   min="0">
        </mat-form-field>
    </div>
</form>

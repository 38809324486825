import { ApiSortDirection } from '@cia-front-end-apps/shared/api-interaction';

export class SortOption {
    displayName: string;
    sortType: string;
    isSelected: boolean;
    sortDirection: ApiSortDirection;
    defaultSortDirection?: ApiSortDirection;

    constructor(options?: Partial<SortOption>) {
        Object.assign(this, options);
        this.defaultSortDirection = options.defaultSortDirection || options.sortDirection;
    }
}

export class SortOptionChangeEvent {
    sortOption: SortOption;

    constructor(options?: Partial<SortOptionChangeEvent>) {
        Object.assign(this, options);
    }
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AnalyticsEvent, AnalyticsEventParameters } from '@hq-app/retailer-analytics/models/analytics-event';
import { AppSettings } from '@hq-core/models/app-settings';
import { DataResource } from '@hq-custom-types';
import { ApiCollectionResponse, ParameterBuilder, SearchState } from '@cia-front-end-apps/shared/api-interaction';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AnalyticsEventResource implements DataResource<AnalyticsEvent> {
    private baseUrl: string;

    constructor(private http: HttpClient, private appSettings: AppSettings) {
        this.baseUrl = `${this.appSettings.apis.report}/analytics/events`;
    }

    getAll(
        searchState: SearchState,
        analyticsEventParameters: AnalyticsEventParameters
    ): Observable<ApiCollectionResponse<AnalyticsEvent>> {
        const url = this.baseUrl;
        let params = new HttpParams({
            fromObject: {
                pageIndex: (searchState.pageIndex || 0).toString(),
                pageSize: (searchState.pageSize || 25).toString(),
                sortBy: searchState.sortBy || '',
                sortDirection: searchState.sortDirection || 'asc',
                search: searchState.search || '',
                startDate: (analyticsEventParameters.startDate?.toISOString() || ''),
                endDate: (analyticsEventParameters.endDate?.toISOString() || ''),
            }
        });
        params = ParameterBuilder.buildArrayParameter(analyticsEventParameters.userIds, 'userIds', params);
        params = ParameterBuilder.buildArrayParameter(analyticsEventParameters.eventCategories, 'eventCategories', params);
        params = ParameterBuilder.buildArrayParameter(analyticsEventParameters.eventActions, 'eventActions', params);
        params = ParameterBuilder.buildArrayParameter(analyticsEventParameters.eventLabels, 'eventLabels', params);
        return this.http.get<ApiCollectionResponse<AnalyticsEvent>>(url, { params });
    }
}

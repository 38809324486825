<mat-sidenav-container class="sidenav-container">
    <mat-sidenav mode="over"
                 fixedInViewport="true"
                 #sidenav>
        <nav class="navigation">
            <hq-navigation (clickNavigationItem)="sidenav.close()"></hq-navigation>
        </nav>
    </mat-sidenav>

    <header class="app-header">
        <hq-toolbar class="toolbar"
                    (openNavigationMenu)="sidenav.open()">
        </hq-toolbar>
    </header>

    <main class="main-content"
          [class.login]="isLoginPage">
        <router-outlet></router-outlet>
    </main>
</mat-sidenav-container>

import { ResolveFn } from '@angular/router';
import { inject } from '@angular/core';
import { FeatureFlagService } from '@cia-front-end-apps/shared/feature-flag/feature-flag.service';
import { LaunchDarklyFeatureFlag } from '@hq-core/models/feature-flag';
import { map } from 'rxjs/operators';
import { pageTitles } from '@hq-core/routes';

export const analyticsTitleResolver: ResolveFn<string> = (route, state) => {
  return inject(FeatureFlagService).getFlag(LaunchDarklyFeatureFlag.ReleaseRetailerReporting).pipe(
        map(hasRetailerReporting => hasRetailerReporting
            ? `${pageTitles.analytics}/Reports`
            : pageTitles.analytics
        )
    );
};


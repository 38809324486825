import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '@hq-core/models/app-settings';
import { Settings } from '@hq-core/models/user';
import { DataResource } from '@hq-custom-types';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SettingsResource implements DataResource<Settings> {
    private baseUrl: string;
    private endpointName = 'users';

    constructor(private http: HttpClient, private appSettings: AppSettings) {
        this.baseUrl = `${this.appSettings.apis.user}/${this.endpointName}`;
    }

    replace(userId: number, settings: Settings): Observable<Settings> {
        const url = `${this.baseUrl}/${userId}/settings`;
        const requestBody = settings;

        return this.http.put<Settings>(url, requestBody);
    }
}

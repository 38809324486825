import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'hq-accent-card-header',
    templateUrl: './accent-card-header.component.html',
    styleUrls: ['./accent-card-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccentCardHeaderComponent {
}

import { BillingTerms } from '@hq-app/vendor-ordering/models/billing-terms';
import { Carrier } from '@hq-app/vendor-ordering/models/carrier';
import { ExtendedContactInformation } from '@hq-app/vendor-ordering/models/contact-information';
import { InternalIdentifiers } from '@hq-app/vendor-ordering/models/internal-identifiers';
import { LineItem } from '@hq-app/vendor-ordering/models/line-item';
import { Note } from '@hq-app/vendor-ordering/models/note';
import { TransitDetails } from '@hq-app/vendor-ordering/models/transit-details';
import { ContactInformation } from '@hq-core/models/contact-information';
import { UnitOfMeasure } from './credit-adjustments';
import { ReadyToShip } from './ready-to-ship';

export class AcknowledgementBuyerIdentifiers {
    purchaseOrderNumber: string;
    storeNumber: string;
    vendorNumber: string;

    constructor(options?: Partial<AcknowledgementBuyerIdentifiers>) {
        Object.assign(this, options);
    }
}

export class AcknowledgementVendorIdentifiers {
    accountNumber: string;

    constructor(options?: Partial<AcknowledgementVendorIdentifiers>) {
        Object.assign(this, options);
    }
}

export class AcknowledgementLineItem {
    original = new LineItem();
    replacement = null;
    transitChange = new TransitDetails();
    isRejected: boolean;
    rejectionMessage: string;
    memo: string;
    quantityAcknowledged: number;
    unitOfMeasureAcknowledged: UnitOfMeasure;
    quantityOrdered: number;
    originalUnitPrice: number;
    statusCode: string;

    constructor(options?: Partial<AcknowledgementLineItem>) {
        Object.assign(this, options);
    }
}

export class Acknowledgement {
    id: number;
    dateOrdered: Date;
    isRejected: boolean;
    dateAcknowledged: Date;
    type: string;
    buyer = new AcknowledgementBuyerIdentifiers();
    carrier = new Carrier();
    readyToShip = new ReadyToShip();
    geniusCentral = new InternalIdentifiers();
    transitDetails = new TransitDetails();
    vendor = new AcknowledgementVendorIdentifiers();
    adminContact = new ContactInformation();
    lineItems = new Array<AcknowledgementLineItem>();
    billingTerms = new Array<BillingTerms>();
    notes = new Array<Note>();
    shipFrom = new ExtendedContactInformation();
    shipTo = new ExtendedContactInformation();
    billTo = new ExtendedContactInformation();
    buyingParty = new ExtendedContactInformation();

    constructor(options?: Partial<Acknowledgement>) {
        Object.assign(this, options);
    }
}

export class LineItemChangeEvent {
    upc: string;
    qty: number;
    memo: string;
    unitPrice: number;
    isValid: boolean;

    constructor(options?: Partial<LineItemChangeEvent>) {
        Object.assign(this, options);
    }
}

export class LineItemConfig {
    qtyField: string;
    alternativePriceField: string;
    displayCheckBox = false;
    displayOrdered = false;
    displayQty = false;
    displaySalePrice = false;
    displayAlternativePrice = false;
    displayTotal = false;
    displayNotes = false;
    qtyFieldLabel: string;

    constructor(options?: Partial<LineItemConfig>) {
        Object.assign(this, options);
    }
}

<mat-card class="contact-card">
    <mat-card-header>
        <mat-card-title class="title">
            Didn't find what you're looking for?
        </mat-card-title>
    </mat-card-header>

    <mat-card-content class="content">
        <p>The item you are searching for may be available in a catalog not visible to you.</p>

        <p>Click below to have a sales rep contact you.</p>
    </mat-card-content>

    <mat-card-actions class="actions">
        <button (click)="onRequestUpgrade()"
                class="button"
                color="accent"
                mat-button>
            Contact
        </button>
    </mat-card-actions>
</mat-card>

export class VendorForm {
    address1: string;
    address2: string;
    city: string;
    contact: string;
    email: string;
    emailCC: string;
    externalVendorId: string;
    freeShippingAmount: number;
    id: number;
    isLocal: boolean;
    privateCatalog: boolean;
    minimumOrderAmount: number;
    name: string;
    phone: string;
    state: string;
    zip: string;

    constructor(options?: Partial<VendorForm>) {
        Object.assign(this, options);
    }
}

export class VendorFormChangeEvent {
    isValid: boolean;
    vendor: VendorForm;

    constructor(options?: Partial<VendorFormChangeEvent>) {
        Object.assign(this, options);
    }
}

export const alphaNumericSpacesRegex = /^[a-zA-Z0-9\s]+$/;

export class NavigationItem {
    displayName: string;
    icon: string;
    path: string;
    requiredModule?: string;

    constructor(options?: Partial<NavigationItem>) {
        Object.assign(this, options);
    }
}

export class NavigationState {
    rawUrl: string;
    sanitizedUrl: string;

    constructor(options?: Partial<NavigationState>) {
        Object.assign(this, options);
    }
}

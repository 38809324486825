<div gcPreventMenuClose
     class="toolbar-header">
    <button mat-button
            class="clear-button"
            color="accent"
            (click)="onClearFilters()">
        Clear
    </button>
</div>

<mat-accordion gcPreventMenuClose
               displayMode="flat"
               [multi]="true">
    <mat-expansion-panel *ngIf="hasSortOptions">
        <mat-expansion-panel-header class="section-heading"
                                    [expandedHeight]="panelHeaderHeight">
            <mat-panel-title class="section-title">Sort By</mat-panel-title>
        </mat-expansion-panel-header>

        <ng-template matExpansionPanelContent>
            <gc-sort-option *ngFor="let option of sortOptions"
                            mat-ripple
                            [initialSortDirection]="initialSortDirection"
                            [sortOption]="option"
                            [isSelected]="option.isSelected"
                            (selectOption)="onSelectSortOption($event)">
            </gc-sort-option>
        </ng-template>
    </mat-expansion-panel>

    <ng-container *ngFor="let group of filterGroups; trackBy: filterGroupTrackBy">
        <mat-expansion-panel [expanded]="group.isExpanded">
            <mat-expansion-panel-header class="section-heading"
                                        [expandedHeight]="panelHeaderHeight">
                <mat-panel-title class="section-title">{{ group.displayName }} Filters</mat-panel-title>
            </mat-expansion-panel-header>

            <ng-template matExpansionPanelContent>
                <mat-selection-list color="accent"
                                    class="filter-list"
                                    [compareWith]="isSameFilter"
                                    (selectionChange)="onChangeFilter($event, group.id)"
                                    [style.height.px]="group.pixelHeight"
                                    cdkVirtualScrollingElement>
                    <cdk-virtual-scroll-viewport [itemSize]="filterOptionPixelHeight"
                                                 [minBufferPx]="filterScrollMinBuffer"
                                                 [maxBufferPx]="filterScrollMaxBuffer"
                                                 [style.height.px]="group.pixelHeight">
                        <!-- If templates are cached, the state of the selection might be lost during scrolling -->
                        <mat-list-option
                            *cdkVirtualFor="let filter of group.filters; trackBy: filterTrackBy; templateCacheSize: 0"
                            [value]="filter"
                            [selected]="filter.isSelected">
                            <span matListItemTitle
                                  class="filter-description">
                                {{ filter.displayName }}
                            </span>
                        </mat-list-option>
                    </cdk-virtual-scroll-viewport>
                </mat-selection-list>
            </ng-template>
        </mat-expansion-panel>
    </ng-container>
</mat-accordion>

import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { User } from '@hq-core/models/user';
import { UserGroup } from '@hq-core/models/user-group';
import moment from 'moment';

@Component({
    selector: 'hq-time-left-banner',
    templateUrl: './time-left-banner.component.html',
    styleUrls: ['./time-left-banner.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimeLeftBannerComponent implements OnInit {
    @Input() user: User;
    @Output() clickContactSales = new EventEmitter<void>();

    timeRemaining: string;
    trainingUrl: string;

    ngOnInit(): void {
        const createdDate = this.user.currentStore?.dateCreated || new Date();
        this.timeRemaining = `${moment.utc().to(moment(createdDate).add(14, 'days'))}`;
        this.trainingUrl = this.getTrainingUrlForUserGroup(this.user.userGroup);
    }

    private getTrainingUrlForUserGroup(userGroup: UserGroup): string {
        let url = 'https://www.geniuscentral.com';

        if (userGroup === UserGroup.OrderCentral) {
            url = 'https://www.geniuscentral.com/retailertraining';
        }
        else if (userGroup === UserGroup.Edi) {
            url = 'https://www.geniuscentral.com/suppliertraining';
        }
        else if (userGroup === UserGroup.SalesGenius) {
            url = 'https://www.geniuscentral.com/salesgeniustraining';
        }

        return url;
    }
    onContactSales(): void {
        this.clickContactSales.emit();

    }
}

<h2 mat-dialog-title>{{title}}</h2>

<mat-dialog-content>
    <p *ngIf="content"
       class="content">
        {{content}}
    </p>

    <mat-form-field>
        <ng-container *ngIf="isSingleLine else multiLine">
            <input matInput
                   [placeholder]="placeholder"
                   [formControl]="formControl"
                   [maxlength]="maxLength"
                   [minlength]="minLength">
        </ng-container>

        <ng-template #multiLine>
            <textarea class="multi-line"
                matInput
                [placeholder]="placeholder"
                [formControl]="formControl"
                [maxlength]="maxLength"
                [minlength]="minLength">
            </textarea>
        </ng-template>

        <mat-error *ngIf="formControl.hasError('required')">
            Field is required
        </mat-error>
        <mat-hint *ngIf="hasMinLength"
                  align="start"
                  class="minimum-characters">
            Requires at least {{minLength}} {{characterLabel}}
        </mat-hint>
        <mat-hint *ngIf="hasMaxLength"
                  align="end"
                  class="maximum-characters">
            Characters: {{formControl.value.length}}/{{maxLength}}
        </mat-hint>
    </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button
            mat-dialog-close
            class="button"
            color="accent">
        Cancel
    </button>

    <button mat-button
            color="accent"
            class="button"
            [disabled]="isDisabled"
            (click)="onTriggerAction()">
        {{actionButtonText}}
    </button>
</mat-dialog-actions>

<ng-content select=".extended-toolbar-actions"></ng-content>

<hq-text-filter [placeholder]="filterDisplayText"
                [textOverride]="textOverride"
                [isDisabled]="!canUseFilter"
                (changeFilter)="onChangeTextFilter($event)">
</hq-text-filter>

<hq-filter-menu class="filter-menu"
                [filters]="filters"
                [filterGroups]="filterGroups"
                [sortOptions]="sortOptions"
                [defaultSortType]="defaultSortType"
                [openFilterGroup]="openFilterGroup"
                (changeFilter)="onChangeFilter($event)"
                (changeSortOption)="onChangeSortOption($event)"
                (clearFilters)="onClearFilters()">
</hq-filter-menu>

import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, Data, Event, NavigationEnd, Router } from '@angular/router';
import { AppRole } from '@hq-core/models/app-role';
import { NavigationState } from '@hq-core/models/navigation';
import { User } from '@hq-core/models/user';
import { UserGroup } from '@hq-core/models/user-group';
import { routeParts } from '@hq-core/routes';
import { BehaviorSubject, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class NavigationService implements OnDestroy {
    currentState: BehaviorSubject<NavigationState>;
    private previousUrl = '';
    private currentUrl = '';
    private readonly unsubscribe = new Subject<void>();

    constructor(private router: Router) {
        this.currentState = new BehaviorSubject<NavigationState>(new NavigationState());
        this.currentUrl = this.router.url;
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.currentState.complete();
    }

    /* Starts the service monitoring for page changes in the app and updating the state observable */
    startNavigationMonitor(): void {
        this.router.events
            .pipe(
                filter((current: Event) => {
                    return current instanceof NavigationEnd;
                }),
                map((event: Event) => event as NavigationEnd),
                tap((current: NavigationEnd) => {
                    this.previousUrl = this.currentUrl;
                    this.currentUrl = current.url;
                }),
                distinctUntilChanged((previous: NavigationEnd, current: NavigationEnd) => {
                    const currentUrl = current.urlAfterRedirects || current.url;
                    return this.sanitizeUrl(previous.url) === this.sanitizeUrl(currentUrl);
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe((current: NavigationEnd) => {
                const navigationState = new NavigationState({
                    rawUrl: current.url,
                    sanitizedUrl: this.sanitizeUrl(current.url)
                });
                this.currentState.next(navigationState);
            });
    }

    getRedirectUrl(user: User, urlBeforeLogin?: string): string {
        const hasRedirectUrl = !!(urlBeforeLogin);
        const isRedirectingToDashboard = urlBeforeLogin === routeParts.dashboard;
        const useDefaultRedirectUrl = !hasRedirectUrl || isRedirectingToDashboard;

        return useDefaultRedirectUrl ? this.getDefaultRedirectUrl(user) : urlBeforeLogin;
    }

    getPreviousUrl(): string {
        return this.previousUrl;
    }

    /** @description Removes identifiers and parameters from a given URL. This includes any id
     *  embedded between slashes, query parameters, UIDs, and hash tag links
     *  @example /vendor/1/edit -> /vendor/edit
     *  @example /vendor?id=1 -> /vendor
     *  @example /vendor#section -> /vendor
     */
    sanitizeUrl(url: string): string {
        const digitOrUidRegex = /^\d+$|^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
        return url
            .split('/')
            .map(part => part.split('?')[0]) // Remove query parameters
            .map(part => part.split('#')[0]) // Remove id token
            .filter(part => !part.match(digitOrUidRegex)) // Remove digit identifiers and UIDs
            .join('/');
    }

    /**
     * Check out the following links to see why it was implemented this way:
     *
     * @link https://github.com/angular/angular/issues/19420
     * @link https://github.com/angular/angular/issues/11812#issuecomment-346637722
     */
    findRouteData(snapshot: ActivatedRouteSnapshot): Data {
        let data = snapshot.data;
        if (snapshot.children?.length > 0) {
            data = {
                ...data,
                ...this.findRouteData(snapshot.children[0])
            };
        }
        return data;
    }

    private getDefaultRedirectUrl(user: User) {
        switch (user.appRole) {
            case AppRole.Broker:
                return this.getBrokerRedirectUrl(user.userGroup);
            default:
                return routeParts.dashboard;
        }
    }

    private getBrokerRedirectUrl(userGroup: UserGroup): string {
        let url = routeParts.brokerCcOrders;

        if (userGroup === UserGroup.SalesGenius) {
            url = routeParts.dashboard;
        }

        return url;
    }
}

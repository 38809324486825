export class Address {
    address1: string;
    address2: string;
    city: string;
    state: string;
    country: string;
    postalCode: string;

    constructor(options?: Partial<Address>) {
        Object.assign(this, options);
    }
}

export class ContactInformation {
    name: string;
    companyName: string;
    phone: string;
    fax: string;
    email: string;
    emailCC: string;
    address = new Address();

    constructor(options?: Partial<ContactInformation>) {
        Object.assign(this, options);
    }
}

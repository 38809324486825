export enum AppRole {
    Internal = 'internal',
    Retailer = 'retailer',
    Vendor = 'vendor',
    Broker = 'broker',
    Buyer = 'buyer'
}

export const appRoleDisplayName = new Map<AppRole, string>([
    [AppRole.Internal, 'Internal'],
    [AppRole.Retailer, 'Retailer'],
    [AppRole.Vendor, 'Vendor'],
    [AppRole.Broker, 'Broker'],
    [AppRole.Buyer, 'Buyer']
]);

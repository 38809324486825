import { Vendor } from '@hq-app/user-management/models/vendor';
import { ContactInformation } from '@hq-core/models/contact-information';
import { FilterKey, SearchFilter } from '@hq-core/models/search-filter';
import { ApiCollectionResponse } from '@cia-front-end-apps/shared/api-interaction';
import { AssortmentAttributes } from '@cia-front-end-apps/shared/assortment/assortment';

export class Product {
    attributes: Array<string>;
    brandId: number;
    brandName: string;
    casePackSize: number;
    category: string;
    categoryId: number;
    department: string;
    departmentId: number;
    description: string;
    extendedDescription: string;
    form: string;
    image: string;
    thumbnail: string;
    itemSize: string;
    orderUnits: string;
    subCategory: string;
    subCategoryId: number;
    upc: string;
    website: string;
    vendorAttributes: Array<VendorAttribute>;
    nutritionalInformation: NutritionalInformation;
    isStocked: boolean;
    isAssortmentHostedImg: boolean;

    constructor(options?: Partial<Product>) {
        Object.assign(this, options);
    }
}

export class NutritionalInformation {
    doesNotContain: string;
    allergens: string;
    storageInstructions: string;
    ingredients: string;
    type: string;

    constructor(options?: Partial<NutritionalInformation>) {
        Object.assign(this, options);
    }
}

export class VendorAttribute {
    groupId: number;
    sku: string;
    isbn: string;
    gtin: string;
    ean: string;
    upc: string;
    casePackSize: number;
    orderUnits: string;
    orderMultiple: number;
    minimumOrder: number;
    wsp: number;
    srp: number;
    freeShippingAmount: number;
    minimumOrderAmount: number;
    distributor: boolean;
    datePriceChanged: Date;
    vendorDiscontinueDate: Date;
    dateNew: Date;
    estimatedRestockDate: Date;
    contactInformation: ContactInformation;
    vendor: Vendor;
    isStocked: boolean;
    assortmentAttributes: AssortmentAttributes;

    // HQ field
    vendorDiscountPrice: number;
    mainIdentifier?: 'upc' | 'ean' | 'gtin' | 'isbn';

    constructor(options?: Partial<VendorAttribute>) {
        Object.assign(this, options);
    }
}

export enum ProductStatus {
    New = 1,
    Discontinued,
    PriceChange,
    Sale,
    FutureSale,
    Focus,
    OutOfStock,
    QuantityError,
    Stocked
}

export class ProductSearchFilters {
    brands = new Array<SearchFilter>();
    categories = new Array<SearchFilter>();
    suppliers = new Array<SearchFilter>();
    productStatuses = new Array<SearchFilter>();

    constructor(options?: Partial<ProductSearchFilters>) {
        Object.assign(this, options);
    }
}

export class ProductSearchApiResponse extends ApiCollectionResponse<Product> {
    filters = new ProductSearchFilters();
    spellCheckSuggestions = new Array<string>();

    constructor(options?: Partial<ProductSearchApiResponse>) {
        super();
        Object.assign(this, options);
    }
}

export class SearchTextChangeEvent {
    searchText: string;
    isExactPhrase?: boolean;

    constructor(options?: Partial<SearchTextChangeEvent>) {
        Object.assign(this, options);
    }
}

export class ProductParameters {
    vendors: Array<number>;
    brands: Array<FilterKey>;
    newDayRange: number;
    discontinuedDayRange: number;
    priceChangeDayRange: number;
    futureSpecials: number;
    filterBySpecial: boolean;
    searchForPartial: boolean;
    categories: Array<number>;
    distributors: Array<number>;
    includeBrandFacet = true;
    includeSupplierFacet = true;
    includeCategoryFacet = true;
    includeCurrentSpecials: boolean;
    isExactPhrase?: boolean;
    isOutOfStock?: boolean;
    filterByPreviouslyOrdered: boolean;
    storeId: number;
    includeStockedProducts: boolean;
    filterByStockedProducts: boolean;
    orderId = 0;
    includeProductsForAdvertiser: number;
    includeItemsInOrder: boolean;
    includeItemsNotInOrder: boolean;

    constructor(options?: Partial<ProductParameters>) {
        Object.assign(this, options);
    }
}

export enum DetailLineName {
    Department = 'Department',
    Category = 'Category',
    SubCategory = 'Sub-Category',
    FlavorType = 'Flavor or Type',
    DoesNotContain = 'Does not Contain',
    Allergens = 'Allergens',
    StorageInstructions = 'Storage Instructions',
    Website = 'Website',
    AdditionalDetails = 'Additional Details',
    Ingredients = 'Ingredients',
    SubAttributes = 'Sub-Attributes'
}

export class DetailLine {
    name: DetailLineName;
    text: string;
    safeHtml?: string;
    hasValue: boolean;

    constructor(options?: Partial<DetailLine>) {
        Object.assign(this, options);
    }
}

import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { customIcons } from '@hq-core/models/icon-registry';
import { ProductSpecialStatus } from '@hq-core/models/specials';

@Component({
    selector: 'hq-product-special-icon',
    templateUrl: './product-special-icon.component.html',
    styleUrls: ['./product-special-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductSpecialIconComponent implements OnInit {
    @HostBinding('class.product-special-icon') cssClass = true;
    @Input() status: ProductSpecialStatus;
    @Input() tooltip: string;

    isCurrent: boolean;
    isFuture: boolean;
    isExpired: boolean;
    icon: string;

    ngOnInit(): void {
        this.icon = customIcons.dollarStarBurst.name;
        this.isCurrent = this.status === ProductSpecialStatus.Current;
        this.isFuture = this.status === ProductSpecialStatus.Future;
        this.isExpired = this.status === ProductSpecialStatus.Expired;
    }
}

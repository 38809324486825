import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Params } from '@angular/router';

@Component({
    selector: 'hq-sub-page',
    templateUrl: './sub-page.component.html',
    styleUrls: ['./sub-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubPageComponent {
    private backOneLevel = '../';

    @Input() pageName: string;
    @Input() returnUrl = this.backOneLevel;
    @Input() queryParams: Params = null;
    @Output() return = new EventEmitter<void>();
}

<div class="mat-mdc-simple-snackbar"
     matSnackBarLabel>
    <p>{{data.message}} <a class="navigate" (click)="click()" [href]="this.data.url">{{data.linkText}}</a></p>

    <span matSnackBarActions>
        <button class="mat-simple-snackbar-action"
                matSnackBarAction
                (click)="close()">
            Dismiss
        </button>
    </span>
</div>

import { NgModule } from '@angular/core';
import { SelectOnClickDirective } from './select-on-click.directive';

@NgModule({
    exports: [
        SelectOnClickDirective
    ],
    declarations: [
        SelectOnClickDirective
    ]
})
export class SelectOnClickModule {
}

import { inject, Injectable } from '@angular/core';
import { Product } from '@hq-app/search/models/product-search';
import { map } from 'rxjs/operators';
import { injectQuery } from '@ngneat/query';
import { AssortmentImageResource } from './assortment-image.resource';

@Injectable({
    providedIn: 'root'
})
export class ProductImageService {
    private query = injectQuery();
    private assortmentImageResource = inject(AssortmentImageResource);


    getAssortmentImage(product: { isAssortmentHostedImg: boolean, image: string }) {
        return this.query({
            queryKey: ['assortment-image', product.image],
            queryFn: () => {
                return this.assortmentImageResource.get(product.image).pipe(
                    map(assortmentImage => assortmentImage.fileData)
                );
            },
            // 1 hr
            staleTime: 1000 * 60 * 60
        });
    }

}

import { Component, ChangeDetectionStrategy, Inject, NgZone } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
    selector: 'hq-link-snack-bar',
    templateUrl: './link-snack-bar.component.html',
    styleUrls: ['./link-snack-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkSnackBarComponent {
    constructor(
        private ngZone: NgZone,
        private router: Router,
        @Inject(MAT_SNACK_BAR_DATA) public data: OrderActionSnackBarData,
        private snackBarRef: MatSnackBarRef<LinkSnackBarComponent>
    ) { }

    click(): void {
        this.ngZone.run(() => {
            this.router.navigate([this.data.url]);
            this.snackBarRef.dismiss();
        });
    }

    close(): void {
        this.snackBarRef.dismiss();
    }
}

interface OrderActionSnackBarData {
    url: string;
    linkText: string;
    message: string;
}

import { Injectable } from '@angular/core';
import { ApiFilter } from '../api-interaction';
import { FilterOption, FilterOptionId } from './filter-menu';

@Injectable({
    providedIn: 'root'
})
export class FilterMenuService {

    getFilterIds(filters: Array<FilterOption>): Array<FilterOptionId> {
        return (filters ?? []).map(f => f.id);
    }

    buildFilterOptions(apiFilters: Array<ApiFilter>, selectedFilters: Array<FilterOption>): Array<FilterOption> {
        const filters = selectedFilters ?? [];

        // We only need to map filters which were not previously selected. This allows us to display the previously selected filters
        // even if the API returns no filters (e.g. for an empty search result).
        return (apiFilters ?? [])
            .filter(apiFilter => {
                return this.hasValidKeyAndDescription(apiFilter) && !filters.find(filter => apiFilter.key === filter.id);
            })
            .map(apiFilter =>
                new FilterOption({
                    id: apiFilter.key,
                    displayName: apiFilter.description
                })
            )
            .concat(filters)
            .sort((option1: FilterOption, option2: FilterOption) => option1.displayName.localeCompare(option2.displayName));
    }

    private hasValidKeyAndDescription(apiFilter: ApiFilter): boolean {
        // prevents null, undefined, or empty string as keys, but allows 0
        const hasValidKey = apiFilter.key === 0 || !!apiFilter.key;
        const hasValidDescription = !!(apiFilter.description);

        return hasValidKey && hasValidDescription;
    }
}

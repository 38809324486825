import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TrackByFunction, ViewChild } from '@angular/core';
import { MatSelectionListChange } from '@angular/material/list';
import { MatMenuTrigger } from '@angular/material/menu';
import { BrokerStoreFilters } from '@hq-app/broker-stores/models/broker-store-response';
import { TransactionStaticFilters } from '@hq-app/monitoring/models/transaction';
import { OrderFilters, ProductFilters } from '@hq-app/ordering/models/order-filter';
import { ProductSearchFilters } from '@hq-app/search/models/product-search';
import { FilterChangeEvent, FilterGroup, SearchFilter } from '@hq-core/models/search-filter';
import { SortOption, SortOptionChangeEvent } from '@hq-shared/models/sort';
import { Subject } from 'rxjs';

@Component({
    selector: 'hq-filter-menu',
    templateUrl: './filter-menu.component.html',
    styleUrls: ['./filter-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterMenuComponent {
    @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;
    @Output() clearFilters = new EventEmitter<void>();
    @Output() changeFilter = new EventEmitter<FilterChangeEvent>();
    @Output() changeSortOption = new EventEmitter<SortOptionChangeEvent>();
    @Output() closed = new EventEmitter<void>();

    @Input() sortOptions: Array<SortOption>;
    @Input() defaultSortType: string;
    @Input() filterGroups: Array<FilterGroup>;
    @Input() openFilterGroup: string;
    @Input() filters: OrderFilters
        | ProductFilters
        | ProductSearchFilters
        | TransactionStaticFilters
        | BrokerStoreFilters;
    @Input() showApplyButton = false;

    clearSort = new Subject<boolean>();
    filterTrackBy: TrackByFunction<SearchFilter> = (index: number, filter: SearchFilter) => filter.key;

    onClearFilters(): void {
        this.clearSort.next(true);
        this.clearFilters.emit();
    }

    onChangeSortOption(event: SortOptionChangeEvent): void {
        this.changeSortOption.emit(event);
    }

    onChangeFilter(event: MatSelectionListChange, groupId: string): void {
        const changedFilter = event.options[0];
        const filterChangeEvent = new FilterChangeEvent({
            isSelected: changedFilter.selected,
            filterId: changedFilter.value,
            groupId
        });

        this.changeFilter.emit(filterChangeEvent);
    }

    onClose(): void {
        this.closed.emit();
        this.menuTrigger.closeMenu();
    }

    getFilterGroupScrollHeight(group: Array<any>): number {
        // should max out at 6 items, but be dynamic up to that point. Each item is 48px tall.
        return (group.length > 6 ? 6 : group.length) * 48;
    }
}

import { ProductStatus } from '@hq-app/search/models/product-search';

export const productStatusIcon = new Map<ProductStatus, string>([
    [ProductStatus.New, 'fiber_new'],
    [ProductStatus.PriceChange, 'autorenew'],
    [ProductStatus.Discontinued, 'warning'],
    [ProductStatus.Focus, 'track_changes'],
    [ProductStatus.OutOfStock, 'remove_shopping_cart'],
    [ProductStatus.Stocked, 'inventory'],
]);

export const productStatusPrefix = new Map<ProductStatus, string>([
    [ProductStatus.New, 'New'],
    [ProductStatus.PriceChange, 'Price Change'],
    [ProductStatus.Discontinued, 'Discontinued'],
    [ProductStatus.Focus, 'Focus'],
    [ProductStatus.OutOfStock, 'Out of Stock'],
]);

export class ProductStatusAlert {
    status: ProductStatus;
    toolTip: string;
    text: string;

    constructor(options?: Partial<ProductStatusAlert>) {
        Object.assign(this, options);
    }
}

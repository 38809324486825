import {
    Directive,
    Input,
    ElementRef,
    HostListener,
    TemplateRef,
    OnInit,
    OnDestroy,
    ComponentRef
} from '@angular/core';
import {
    Overlay,
    OverlayRef,
    OverlayPositionBuilder,
    ScrollStrategyOptions,
    ConnectionPositionPair
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { CustomTooltipComponent } from './custom-tooltip.component';

@Directive({
    selector: '[hqCustomTooltip]'
})
export class TooltipRendererDirective implements OnInit, OnDestroy {
    @Input() showToolTip = true;
    @Input('hqCustomTooltip') text: string;
    @Input() contentTemplate: TemplateRef<any>
    @Input() data: any;
    @Input() position: 'above' | 'below' | 'left' | 'right' = 'left';

    private overlayRef: OverlayRef;

    constructor(
        private overlay: Overlay,
        private overlayPositionBuilder: OverlayPositionBuilder,
        private elementRef: ElementRef,
        private scrollStrategyOptions: ScrollStrategyOptions
    ) {
    }

    ngOnInit() {
        if (!this.showToolTip) {
            return;
        }

        const positionStrategy = this.overlayPositionBuilder
            .flexibleConnectedTo(this.elementRef)
            .withPositions(this.getPositions());

        const scrollStrategy = this.scrollStrategyOptions.reposition();

        this.overlayRef = this.overlay.create({
            positionStrategy,
            scrollStrategy
        });
    }


    @HostListener('mouseenter')
    show() {
        if (this.overlayRef && !this.overlayRef.hasAttached()) {
            const tooltipRef: ComponentRef<CustomTooltipComponent> = this.overlayRef.attach(new ComponentPortal(CustomTooltipComponent));
            tooltipRef.instance.text = this.text;
            tooltipRef.instance.contentTemplate = this.contentTemplate;
            tooltipRef.instance.data = this.data;
            setTimeout(() => {
                tooltipRef.location.nativeElement.classList.add('shown');
            }, 0);
        }
    }

    @HostListener('mouseleave')
    hide() {
        this.closeToolTip();
    }

    ngOnDestroy() {
        this.closeToolTip();
    }

    private closeToolTip() {
        if (this.overlayRef) {
            this.overlayRef.detach();
        }
    }

    getPositions(): ConnectionPositionPair[] {
        switch (this.position) {
            case 'above':
                return [{
                    originX: 'center', originY: 'top',
                    overlayX: 'center', overlayY: 'bottom',
                }];
            case 'below':
                return [{
                    originX: 'center', originY: 'bottom',
                    overlayX: 'center', overlayY: 'top',
                }];
            case 'left':
                return [
                    {
                        // Position the tooltip on the left side of the element
                        originX: 'start',
                        originY: 'center',
                        overlayX: 'end',
                        overlayY: 'center',
                        offsetX: -10
                    },
                    {
                        // Fallback position if there is no room on the left
                        originX: 'center',
                        originY: 'bottom',
                        overlayX: 'center',
                        overlayY: 'top',
                    }
                ];
            case 'right':
                return [{
                    originX: 'end', originY: 'center',
                    overlayX: 'start', overlayY: 'center',
                }];
            default:
                return [{
                    originX: 'center', originY: 'top',
                    overlayX: 'center', overlayY: 'bottom',
                }];
        }
    }
}

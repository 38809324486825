import { DropdownOption } from '@hq-shared/models/dropdown-option';

export enum DateOption {
    PreviousMonth,
    MonthToDate,
    PreviousYear,
    YearToDate,
    CustomDate
}

export const dateDropdownOptions = [
    new DropdownOption({
        displayName: 'Previous Month',
        id: DateOption.PreviousMonth
    }),
    new DropdownOption({
        displayName: 'Month to Date',
        id: DateOption.MonthToDate
    }),
    new DropdownOption({
        displayName: 'Previous Year',
        id: DateOption.PreviousYear
    }),
    new DropdownOption({
        displayName: 'Year to Date',
        id: DateOption.YearToDate
    }),
    new DropdownOption({
        displayName: 'Custom Date',
        id: DateOption.CustomDate
    })
];

export class DateRange {
    startDate: Date;
    endDate: Date;

    constructor(options?: Partial<DateRange>) {
        Object.assign(this, options);
    }
}

import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AnalyticsService } from '@hq-core/analytics/analytics.service';
import { FeedbackService } from '@hq-core/feedback/feedback.service';
import { AnalyticsAction, AnalyticsCategory, AnalyticsEvent } from '@hq-core/models/analytics';
import { Feedback } from '@hq-core/models/feedback';
import { UserService } from '@hq-core/user/user.service';
import { Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'hq-catalog-upgrade-request',
    templateUrl: './catalog-upgrade-request.component.html',
    styleUrls: ['./catalog-upgrade-request.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CatalogUpgradeRequestComponent implements OnInit, OnDestroy {
    @Input() searchTerm = '';

    private readonly unsubscribe = new Subject<void>();

    constructor(
        private snackBar: MatSnackBar,
        private userService: UserService,
        private feedbackService: FeedbackService,
        private analyticsService: AnalyticsService
    ) {
    }

    ngOnInit(): void {
        const event = new AnalyticsEvent({
            category: AnalyticsCategory.Product,
            action: AnalyticsAction.ReturnNoResults,
            trackedValue: this.searchTerm || 'No search term data'
        });

        this.analyticsService.trackEvent(event);
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    onRequestUpgrade(): void {
        this.userService.getCurrentUser()
            .pipe(
                switchMap(user => {
                    const request = new Feedback({
                        userId: user.id,
                        storeId: user.currentStore.legacyStoreId,
                        content: `Search Term: ${this.searchTerm}`,
                        application: 'Catalog Access Upgrade Request'
                    });
                    return this.feedbackService.createFeedback(request);
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe(() => this.snackBar.open(
                'Request submitted! A sales rep will contact you shortly.',
                'OK',
                { duration: 3500 }
            ));

    }
}

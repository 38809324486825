import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '@hq-core/models/app-settings';
import { ImpersonationToken } from '@hq-core/models/impersonation-token';
import { DataResource } from '@hq-custom-types';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ImpersonationTokenResource implements DataResource<ImpersonationToken> {
    private readonly baseUrl: string;

    constructor(private http: HttpClient, private appSettings: AppSettings) {
        this.baseUrl = `${this.appSettings.apis.user}`;
    }

    get(userId: number): Observable<ImpersonationToken> {
        const url = `${this.baseUrl}/impersonation-tokens/${userId}`;
        return this.http.get<ImpersonationToken>(url);
    }
}

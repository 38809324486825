<mat-card class="card">
    <h2 class="title">
        <div class="timer">
            <div class="icon">
                <mat-icon>lock_clock</mat-icon>
            </div>
            <span>Your Free Trial Ends {{timeRemaining}}</span>
        </div>
    </h2>

    <div class="button-container">
        <a mat-button
                color="accent"
                class="button"
                target="_blank"
                [href]="trainingUrl">
            View Training Materials
        </a>
        <button mat-flat-button
                color="accent"
                (click)="onContactSales()"
                class="button contact-sales">
            Contact Sales
        </button>
    </div>
</mat-card>

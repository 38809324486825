import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BuyerFieldConfiguration } from '@hq-app/vendor-ordering/models/buyer-config';
import { FormsService } from '@hq-core/forms/forms.service';
import { defaultFormChangeDebounceTime } from '@hq-core/models/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'hq-memo',
    templateUrl: './memo.component.html',
    styleUrls: ['./memo.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MemoComponent implements OnInit, OnChanges, OnDestroy {
    @Input() label: string;
    @Input() memo: string;
    @Input() maxLength: number;
    @Input() displayMissingRequirements: boolean;
    @Input() disabled = false;
    @Input() configuration: BuyerFieldConfiguration;
    @Input() isRejected = false;
    @Input() parentFormGroup: FormGroup;
    @Input() formName: string;
    @Output() changedMemo = new EventEmitter<string>();

    formGroup: FormGroup;

    private readonly defaultOrderMessage = '';
    private readonly unsubscribe = new Subject<void>();

    constructor(private formsService: FormsService) { }

    ngOnInit() {
        this.createForm();

        this.formGroup.valueChanges
            .pipe(
                debounceTime(defaultFormChangeDebounceTime),
                takeUntil(this.unsubscribe)
            )
            .subscribe(change => this.changedMemo.emit(change.message));

        this.toggleEditing();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.memo && !changes.memo.firstChange) {
            if (this.formGroup) {
                this.formGroup.get('message').setValue(this.memo || this.defaultOrderMessage, { emitEvent: false });
                this.toggleEditing();
            }
        }

        if (changes.displayMissingRequirements && !changes.displayMissingRequirements.firstChange) {
            if (this.displayMissingRequirements) {
                this.formsService.markGroupAsTouched(this.formGroup);
            }
        }

        if (changes.disabled && !changes.disabled.firstChange) {
            if (this.formGroup) {
                this.toggleEditing();
            }
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    private createForm(): void {
        const formGroup = new FormGroup({
            message: this.formsService.mapFormField({
                initialValue: this.memo || this.defaultOrderMessage,
                validators: [],
                config: this.configuration
            })
        });

        this.formGroup = formGroup;

        if (this.parentFormGroup) {
            this.parentFormGroup.setControl(this.formName, formGroup.get('message'));
        }
    }

    private toggleEditing(): void {
        if (this.formGroup && this.disabled) {
            this.formGroup.disable({ emitEvent: false });
        }
    }
}

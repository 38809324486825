import { DropdownOption } from '@hq-shared/models/dropdown-option';

enum Country {
    US = 'US',
    CA = 'CA',
    AU = 'AU',
    FI = 'FI',
    IE = 'IE',
    NL = 'NL',
    IL = 'IL',
    GB = 'GB',
    GR = 'GR',
    NZ = 'NZ',
    ZA = 'ZA',
    DK = 'DK',
    IT = 'IT'
}

export const selectableCountries = [Country.US, Country.CA];

export const frequentlyUsedVendorCountryOptions: Array<DropdownOption<Country>> = [
    new DropdownOption({
        id: Country.US,
        displayName: 'United States of America'
    }),
    new DropdownOption({
        id: Country.CA,
        displayName: 'Canada'
    })
];

export const allVendorCountryOptions: Array<DropdownOption<string>> = [
    { displayName: 'Afghanistan', id: 'AF' },
    { displayName: 'Åland Islands', id: 'AX' },
    { displayName: 'Albania', id: 'AL' },
    { displayName: 'Algeria', id: 'DZ' },
    { displayName: 'American Samoa', id: 'AS' },
    { displayName: 'Andorra', id: 'AD' },
    { displayName: 'Angola', id: 'AO' },
    { displayName: 'Anguilla', id: 'AI' },
    { displayName: 'Antarctica', id: 'AQ' },
    { displayName: 'Antigua & Barbuda', id: 'AG' },
    { displayName: 'Argentina', id: 'AR' },
    { displayName: 'Armenia', id: 'AM' },
    { displayName: 'Aruba', id: 'AW' },
    { displayName: 'Australia', id: 'AU' },
    { displayName: 'Austria', id: 'AT' },
    { displayName: 'Azerbaijan', id: 'AZ' },
    { displayName: 'Bahamas', id: 'BS' },
    { displayName: 'Bahrain', id: 'BH' },
    { displayName: 'Bangladesh', id: 'BD' },
    { displayName: 'Barbados', id: 'BB' },
    { displayName: 'Belarus', id: 'BY' },
    { displayName: 'Belgium', id: 'BE' },
    { displayName: 'Belize', id: 'BZ' },
    { displayName: 'Benin', id: 'BJ' },
    { displayName: 'Bermuda', id: 'BM' },
    { displayName: 'Bhutan', id: 'BT' },
    { displayName: 'Bolivia', id: 'BO' },
    { displayName: 'Caribbean Netherlands', id: 'BQ' },
    { displayName: 'Bosnia & Herzegovina', id: 'BA' },
    { displayName: 'Botswana', id: 'BW' },
    { displayName: 'Bouvet Island', id: 'BV' },
    { displayName: 'Brazil', id: 'BR' },
    { displayName: 'British Indian Ocean Territory', id: 'IO' },
    { displayName: 'Brunei', id: 'BN' },
    { displayName: 'Bulgaria', id: 'BG' },
    { displayName: 'Burkina Faso', id: 'BF' },
    { displayName: 'Burundi', id: 'BI' },
    { displayName: 'Cambodia', id: 'KH' },
    { displayName: 'Cameroon', id: 'CM' },
    { displayName: 'Canada', id: 'CA' },
    { displayName: 'Cape Verde', id: 'CV' },
    { displayName: 'Cayman Islands', id: 'KY' },
    { displayName: 'Central African Republic', id: 'CF' },
    { displayName: 'Chad', id: 'TD' },
    { displayName: 'Chile', id: 'CL' },
    { displayName: 'China', id: 'CN' },
    { displayName: 'Christmas Island', id: 'CX' },
    { displayName: 'Cocos (Keeling) Islands', id: 'CC' },
    { displayName: 'Colombia', id: 'CO' },
    { displayName: 'Comoros', id: 'KM' },
    { displayName: 'Congo - Brazzaville', id: 'CG' },
    { displayName: 'Congo - Kinshasa', id: 'CD' },
    { displayName: 'Cook Islands', id: 'CK' },
    { displayName: 'Costa Rica', id: 'CR' },
    { displayName: 'Côte d’Ivoire', id: 'CI' },
    { displayName: 'Croatia', id: 'HR' },
    { displayName: 'Cuba', id: 'CU' },
    { displayName: 'Curaçao', id: 'CW' },
    { displayName: 'Cyprus', id: 'CY' },
    { displayName: 'Czechia', id: 'CZ' },
    { displayName: 'Denmark', id: 'DK' },
    { displayName: 'Djibouti', id: 'DJ' },
    { displayName: 'Dominica', id: 'DM' },
    { displayName: 'Dominican Republic', id: 'DO' },
    { displayName: 'Ecuador', id: 'EC' },
    { displayName: 'Egypt', id: 'EG' },
    { displayName: 'El Salvador', id: 'SV' },
    { displayName: 'Equatorial Guinea', id: 'GQ' },
    { displayName: 'Eritrea', id: 'ER' },
    { displayName: 'Estonia', id: 'EE' },
    { displayName: 'Ethiopia', id: 'ET' },
    { displayName: 'Falkland Islands (Islas Malvinas)', id: 'FK' },
    { displayName: 'Faroe Islands', id: 'FO' },
    { displayName: 'Fiji', id: 'FJ' },
    { displayName: 'Finland', id: 'FI' },
    { displayName: 'France', id: 'FR' },
    { displayName: 'French Guiana', id: 'GF' },
    { displayName: 'French Polynesia', id: 'PF' },
    { displayName: 'French Southern Territories', id: 'TF' },
    { displayName: 'Gabon', id: 'GA' },
    { displayName: 'Gambia', id: 'GM' },
    { displayName: 'Georgia', id: 'GE' },
    { displayName: 'Germany', id: 'DE' },
    { displayName: 'Ghana', id: 'GH' },
    { displayName: 'Gibraltar', id: 'GI' },
    { displayName: 'Greece', id: 'GR' },
    { displayName: 'Greenland', id: 'GL' },
    { displayName: 'Grenada', id: 'GD' },
    { displayName: 'Guadeloupe', id: 'GP' },
    { displayName: 'Guam', id: 'GU' },
    { displayName: 'Guatemala', id: 'GT' },
    { displayName: 'Guernsey', id: 'GG' },
    { displayName: 'Guinea', id: 'GN' },
    { displayName: 'Guinea-Bissau', id: 'GW' },
    { displayName: 'Guyana', id: 'GY' },
    { displayName: 'Haiti', id: 'HT' },
    { displayName: 'Heard & McDonald Islands', id: 'HM' },
    { displayName: 'Vatican City', id: 'VA' },
    { displayName: 'Honduras', id: 'HN' },
    { displayName: 'Hong Kong', id: 'HK' },
    { displayName: 'Hungary', id: 'HU' },
    { displayName: 'Iceland', id: 'IS' },
    { displayName: 'India', id: 'IN' },
    { displayName: 'Indonesia', id: 'ID' },
    { displayName: 'Iran', id: 'IR' },
    { displayName: 'Iraq', id: 'IQ' },
    { displayName: 'Ireland', id: 'IE' },
    { displayName: 'Isle of Man', id: 'IM' },
    { displayName: 'Israel', id: 'IL' },
    { displayName: 'Italy', id: 'IT' },
    { displayName: 'Jamaica', id: 'JM' },
    { displayName: 'Japan', id: 'JP' },
    { displayName: 'Jersey', id: 'JE' },
    { displayName: 'Jordan', id: 'JO' },
    { displayName: 'Kazakhstan', id: 'KZ' },
    { displayName: 'Kenya', id: 'KE' },
    { displayName: 'Kiribati', id: 'KI' },
    { displayName: 'North Korea', id: 'KP' },
    { displayName: 'South Korea', id: 'KR' },
    { displayName: 'Kosovo', id: 'XK' },
    { displayName: 'Kuwait', id: 'KW' },
    { displayName: 'Kyrgyzstan', id: 'KG' },
    { displayName: 'Laos', id: 'LA' },
    { displayName: 'Latvia', id: 'LV' },
    { displayName: 'Lebanon', id: 'LB' },
    { displayName: 'Lesotho', id: 'LS' },
    { displayName: 'Liberia', id: 'LR' },
    { displayName: 'Libya', id: 'LY' },
    { displayName: 'Liechtenstein', id: 'LI' },
    { displayName: 'Lithuania', id: 'LT' },
    { displayName: 'Luxembourg', id: 'LU' },
    { displayName: 'Macao', id: 'MO' },
    { displayName: 'North Macedonia', id: 'MK' },
    { displayName: 'Madagascar', id: 'MG' },
    { displayName: 'Malawi', id: 'MW' },
    { displayName: 'Malaysia', id: 'MY' },
    { displayName: 'Maldives', id: 'MV' },
    { displayName: 'Mali', id: 'ML' },
    { displayName: 'Malta', id: 'MT' },
    { displayName: 'Marshall Islands', id: 'MH' },
    { displayName: 'Martinique', id: 'MQ' },
    { displayName: 'Mauritania', id: 'MR' },
    { displayName: 'Mauritius', id: 'MU' },
    { displayName: 'Mayotte', id: 'YT' },
    { displayName: 'Mexico', id: 'MX' },
    { displayName: 'Micronesia', id: 'FM' },
    { displayName: 'Moldova', id: 'MD' },
    { displayName: 'Monaco', id: 'MC' },
    { displayName: 'Mongolia', id: 'MN' },
    { displayName: 'Montenegro', id: 'ME' },
    { displayName: 'Montserrat', id: 'MS' },
    { displayName: 'Morocco', id: 'MA' },
    { displayName: 'Mozambique', id: 'MZ' },
    { displayName: 'Myanmar (Burma)', id: 'MM' },
    { displayName: 'Namibia', id: 'NA' },
    { displayName: 'Nauru', id: 'NR' },
    { displayName: 'Nepal', id: 'NP' },
    { displayName: 'Netherlands', id: 'NL' },
    { displayName: 'Curaçao', id: 'AN' },
    { displayName: 'New Caledonia', id: 'NC' },
    { displayName: 'New Zealand', id: 'NZ' },
    { displayName: 'Nicaragua', id: 'NI' },
    { displayName: 'Niger', id: 'NE' },
    { displayName: 'Nigeria', id: 'NG' },
    { displayName: 'Niue', id: 'NU' },
    { displayName: 'Norfolk Island', id: 'NF' },
    { displayName: 'Northern Mariana Islands', id: 'MP' },
    { displayName: 'Norway', id: 'NO' },
    { displayName: 'Oman', id: 'OM' },
    { displayName: 'Pakistan', id: 'PK' },
    { displayName: 'Palau', id: 'PW' },
    { displayName: 'Palestine', id: 'PS' },
    { displayName: 'Panama', id: 'PA' },
    { displayName: 'Papua New Guinea', id: 'PG' },
    { displayName: 'Paraguay', id: 'PY' },
    { displayName: 'Peru', id: 'PE' },
    { displayName: 'Philippines', id: 'PH' },
    { displayName: 'Pitcairn Islands', id: 'PN' },
    { displayName: 'Poland', id: 'PL' },
    { displayName: 'Portugal', id: 'PT' },
    { displayName: 'Puerto Rico', id: 'PR' },
    { displayName: 'Qatar', id: 'QA' },
    { displayName: 'Réunion', id: 'RE' },
    { displayName: 'Romania', id: 'RO' },
    { displayName: 'Russia', id: 'RU' },
    { displayName: 'Rwanda', id: 'RW' },
    { displayName: 'St. Barthélemy', id: 'BL' },
    { displayName: 'St. Helena', id: 'SH' },
    { displayName: 'St. Kitts & Nevis', id: 'KN' },
    { displayName: 'St. Lucia', id: 'LC' },
    { displayName: 'St. Martin', id: 'MF' },
    { displayName: 'St. Pierre & Miquelon', id: 'PM' },
    { displayName: 'St. Vincent & Grenadines', id: 'VC' },
    { displayName: 'Samoa', id: 'WS' },
    { displayName: 'San Marino', id: 'SM' },
    { displayName: 'São Tomé & Príncipe', id: 'ST' },
    { displayName: 'Saudi Arabia', id: 'SA' },
    { displayName: 'Senegal', id: 'SN' },
    { displayName: 'Serbia', id: 'RS' },
    { displayName: 'Serbia', id: 'CS' },
    { displayName: 'Seychelles', id: 'SC' }, { displayName: 'Sierra Leone', id: 'SL' },
    { displayName: 'Singapore', id: 'SG' },
    { displayName: 'Sint Maarten', id: 'SX' },
    { displayName: 'Slovakia', id: 'SK' },
    { displayName: 'Slovenia', id: 'SI' },
    { displayName: 'Solomon Islands', id: 'SB' },
    { displayName: 'Somalia', id: 'SO' },
    { displayName: 'South Africa', id: 'ZA' },
    { displayName: 'South Georgia & South Sandwich Islands', id: 'GS' },
    { displayName: 'South Sudan', id: 'SS' },
    { displayName: 'Spain', id: 'ES' },
    { displayName: 'Sri Lanka', id: 'LK' },
    { displayName: 'Sudan', id: 'SD' },
    { displayName: 'Suriname', id: 'SR' },
    { displayName: 'Svalbard & Jan Mayen', id: 'SJ' },
    { displayName: 'Eswatini', id: 'SZ' },
    { displayName: 'Sweden', id: 'SE' },
    { displayName: 'Switzerland', id: 'CH' },
    { displayName: 'Syria', id: 'SY' },
    { displayName: 'Taiwan', id: 'TW' },
    { displayName: 'Tajikistan', id: 'TJ' },
    { displayName: 'Tanzania', id: 'TZ' },
    { displayName: 'Thailand', id: 'TH' },
    { displayName: 'Timor-Leste', id: 'TL' },
    { displayName: 'Togo', id: 'TG' },
    { displayName: 'Tokelau', id: 'TK' },
    { displayName: 'Tonga', id: 'TO' },
    { displayName: 'Trinidad & Tobago', id: 'TT' },
    { displayName: 'Tunisia', id: 'TN' },
    { displayName: 'Turkey', id: 'TR' },
    { displayName: 'Turkmenistan', id: 'TM' },
    { displayName: 'Turks & Caicos Islands', id: 'TC' },
    { displayName: 'Tuvalu', id: 'TV' },
    { displayName: 'Uganda', id: 'UG' },
    { displayName: 'Ukraine', id: 'UA' },
    { displayName: 'United Arab Emirates', id: 'AE' },
    { displayName: 'United Kingdom', id: 'GB' },
    { displayName: 'United States', id: 'US' },
    { displayName: 'U.S. Outlying Islands', id: 'UM' },
    { displayName: 'Uruguay', id: 'UY' },
    { displayName: 'Uzbekistan', id: 'UZ' },
    { displayName: 'Vanuatu', id: 'VU' },
    { displayName: 'Venezuela', id: 'VE' },
    { displayName: 'Vietnam', id: 'VN' },
    { displayName: 'British Virgin Islands', id: 'VG' },
    { displayName: 'U.S. Virgin Islands', id: 'VI' },
    { displayName: 'Wallis & Futuna', id: 'WF' },
    { displayName: 'Western Sahara', id: 'EH' },
    { displayName: 'Yemen', id: 'YE' },
    { displayName: 'Zambia', id: 'ZM' },
    { displayName: 'Zimbabwe', id: 'ZW' }
].sort((a, b) => a.displayName.localeCompare(b.displayName));

<mat-icon color="accent"
          class="icon">
    {{icon}}
</mat-icon>

<h3>
    {{title}}
</h3>

{{message}}

import { PackagingType, PackagingUnit } from './shipment';

export class ReadyToShip {
    packagingType: PackagingType;
    packageCount: number;
    weight: number;
    weightUnit: PackagingUnit;
    volume: number;
    volumeUnit: string;

    constructor(options?: Partial<ReadyToShip>) {
        Object.assign(this, options);
    }
}

<div class="chip-bar">
    <label class="chip-label">Filters:</label>
    <mat-chip-listbox selectable="false"
                      hideSingleSelectionIndicator>
        <mat-chip-option *ngFor="let filter of filterChips"
                         (removed)="onRemoveChip(filter)"
                         disableRipple
                         selected
                         selectable="false"
                         color="accent">
            <span>{{filter.description}}</span>
            <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip-option>
    </mat-chip-listbox>
</div>

/**
 * @description Contains methods concerning the type identity of an object or value
 */
export class TypeIdentity {

    /**
     * @description Determines if the given value is a string
     * @param value The value to identify as being a string
     */
    static isString(value: any): boolean {
        return typeof value === 'string';
    }

    /**
     * @description Determines if the given values is an object
     * @param value The value to identify as being an object
     */
    static isObject(value: any): boolean {
        // Have to check if it's not null since: console.log(typeof null) -> "object"
        return value !== null && typeof value === 'object';
    }
}

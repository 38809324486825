import { Address } from '@hq-core/models/contact-information';
import { IndustryTypes } from '@hq-core/models/industry-vertical';

export class CustomerLead {
    name: string;
    company: string;
    address: Address;
    phone: string;
    email: string;
    dateCompleted: string;
    emailTo: EmailTo;
    userName: string;
    password: string;
    license: string;
    distributors: Array<Distributors>;
    industryVerticals: Array<IndustryTypes>;
    otherIndustryVertical: string;

    constructor(options?: Partial<CustomerLead>) {
        Object.assign(this, options);
    }
}

export enum EmailTo {
    Marketing = 0,
    Sales = 1
}

export class Distributors {
    id: number;
    name: string;

    constructor(options?: Partial<Distributors>) {
        Object.assign(this, options);
    }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AuthImpersonationService } from '@hq-core/auth/auth-impersonation.service';
import { UserService } from '@hq-core/user/user.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import moment from 'moment';

@Component({
    selector: 'hq-impersonation-bar',
    templateUrl: './impersonation-bar.component.html',
    styleUrls: ['./impersonation-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImpersonationBarComponent implements OnInit, OnDestroy {
    username: string;
    timeLeft: string;

    private userId: number;
    private interval: any;
    private unsubscribe = new Subject<void>();

    constructor(
        private cdRef: ChangeDetectorRef,
        private userService: UserService,
        private authImpersonationService: AuthImpersonationService
    ) {
    }

    ngOnInit(): void {
        this.userService.getCurrentUser()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(user => {
                this.username = user.profile.username;
                this.userId = user.id;
                this.cdRef.detectChanges();
            });

        const expiration = this.authImpersonationService.getExpiration();
        this.interval = setInterval(() => {
            const currentTime = Date.now();
            const timeLeft = expiration - currentTime;
            if (timeLeft < 1) {
                this.authImpersonationService.redirectToUserEditor();
            }

            const duration = moment.duration(timeLeft, 'milliseconds');
            const minutesLeft = duration.minutes() < 10 ? `0${duration.minutes()}` : duration.minutes();
            const secondsLeft = duration.seconds() < 10 ? `0${duration.seconds()}` : duration.seconds();
            this.timeLeft = `${minutesLeft}:${secondsLeft}`;
            this.cdRef.detectChanges();
        }, 1000);
    }


    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        clearInterval(this.interval);
    }

    onStopImpersonating(): void {
        this.authImpersonationService.redirectToUserEditor();
    }
}

<ng-container [ngSwitch]="layout">
    <mat-card *ngSwitchCase="'standard'"
              class="display-ad">
        <div *ngIf="promotion.imageUrl"
             class="image-container">
            <img [src]="promotion.imageUrl"
                 class="promotion-image"
                 mat-card-image/>
        </div>

        <mat-card-content class="promotion-header">
            <img *ngIf="promotion.advertiser.logo"
                 [src]="promotion.advertiser.logo"
                 class="advertiser-logo"/>
            <div class="promotion-text">
                <h3 class="promotion-title">{{promotion.title}}</h3>
                <p class="promotion-description">{{promotion.description}}</p>
            </div>
        </mat-card-content>

        <mat-card-actions class="promotion-actions">
            <small class="sponsored">SPONSORED</small>
            <ng-container *ngIf="hasExternalUrl else internalLink">
                <a (click)="onTriggerAction()"
                   [href]="routeUrl"
                   class="button"
                   color="accent"
                   mat-button
                   target="_blank">
                    View
                </a>
            </ng-container>
        </mat-card-actions>
    </mat-card>


    <mat-card *ngSwitchCase="'list'"
              [class.column]="shouldBreakToColumn"
              class="display-ad list-item">
        <div *ngIf="promotion.imageUrl"
             class="image-container">
            <img [src]="promotion.imageUrl"
                 class="promotion-image"/>
        </div>

        <div class="promotion-content">
            <div class="promotion-header">
                <img *ngIf="promotion.advertiser.logo"
                     [src]="promotion.advertiser.logo"
                     class="advertiser-logo"/>
                <div class="promotion-text">
                    <h3 class="promotion-title">{{promotion.title}}</h3>
                    <p class="promotion-description">{{promotion.description}}</p>
                </div>
            </div>

            <div class="promotion-actions">
                <small class="sponsored">SPONSORED</small>
                <ng-container *ngIf="hasExternalUrl else internalLink">
                    <a (click)="onTriggerAction()"
                       [href]="routeUrl"
                       class="button"
                       color="accent"
                       mat-button
                       target="_blank">
                        View
                    </a>
                </ng-container>
            </div>
        </div>
    </mat-card>
</ng-container>

<ng-template #internalLink>
    <a mat-button
       (click)="onTriggerAction()"
       [routerLink]="routeUrl"
       class="button"
       color="accent">
        View
    </a>
</ng-template>

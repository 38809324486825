import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
    selector: '[hqDragAndDrop]'
})
export class DndDirective {
    @Output() dropFile: EventEmitter<FileList> = new EventEmitter();

    constructor() { }

    @HostListener('dragover', ['$event']) onDragOver(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();
    }

    @HostListener('dragleave', ['$event']) onDragLeave(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();
    }

    @HostListener('drop', ['$event']) onDrop(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();

        const files = event.dataTransfer.files;
        this.dropFile.emit(files);
    }
}

import { EventEmitter } from '@angular/core';
import {
    Advertiser,
    AdvertiserProduct,
} from '@hq-app/promotions-management/models/advertiser';
import { PromotionData } from '@hq-app/promotions-management/models/promotion-data';
import { PromotionTypeId } from '@hq-app/promotions-management/models/promotion-types';
import { StateId } from '@hq-app/promotions-management/models/targeting';
import { Product } from '@hq-app/search/models/product-search';
import { FormChangeEvent } from '@hq-core/models/forms';
import { ApiCollectionResponse } from '@cia-front-end-apps/shared/api-interaction';

export class PromotionEmail {
    recipient: string;
    subjectLine: string;

    constructor(options?: Partial<PromotionEmail>) {
        Object.assign(this, options);
    }
}

export class PromotionBrand {
    id: number;
    name: string;
    url: string;
    email: PromotionEmail;

    constructor(options?: Partial<PromotionBrand>) {
        Object.assign(this, options);
    }
}

export class PromotionNewProduct {
    sampleEmail: PromotionEmail;

    constructor(options?: Partial<PromotionNewProduct>) {
        Object.assign(this, options);
    }
}

export class PromotionSample {
    maxEmailResponses: number;

    constructor(options?: Partial<PromotionSample>) {
        Object.assign(this, options);
    }
}

export class PromotionMgcAd {
    fullPageAdImageUrl: string;

    constructor(options?: Partial<PromotionMgcAd>) {
        Object.assign(this, options);
    }
}

export class PromotionContactInformation {
    email: PromotionEmail;
    phone: string;

    constructor(options?: Partial<PromotionContactInformation>) {
        Object.assign(this, options);
    }
}

export class Promotion {
    id: number;
    name: string;
    startDate: Date;
    endDate: Date;
    elevated: boolean;
    advertiser: Advertiser;
    description: string;
    title: string;
    displayLocations: Array<PromotionDisplayLocationType>;
    targets: PromotionTargets;
    brand: PromotionBrand;
    newProduct: PromotionNewProduct;
    sample: PromotionSample;
    mgcAd: PromotionMgcAd;
    contactInformation: PromotionContactInformation;
    products: Array<Product>;
    typeId: PromotionTypeId;
    imageUrl: string;
    thumbnailUrl: string;
    videoUrl: string;
    industryVerticals: Array<number>;

    // HQ only
    promotionTypeName: string;
    status: PromotionStatus;
    hasUserResponse = false;
    destinationUrl: string;

    constructor(options?: Partial<Promotion>) {
        Object.assign(this, options);
    }
}

export class PromotionTargets {
    chainIds: Array<number> = [];
    catalogIds: Array<number> = [];
    stateIds: Array<StateId> = [];

    constructor(options?: Partial<PromotionTargets>) {
        Object.assign(this, options);
    }
}

export class PromotionState {
    scheduled: Array<Promotion>;
    active: Array<Promotion>;
    inactive: Array<Promotion>;

    constructor(options?: Partial<PromotionState>) {
        Object.assign(this, options);
    }
}

export interface PromotionFormComponent {
    promotion: Promotion;
    changePromotionData: EventEmitter<FormChangeEvent<PromotionData>>;
}

export interface PromotionPreviewComponent {
    promotionData: PromotionData;
}

export enum PromotionStatus {
    Inactive,
    Active,
    Scheduled,
}

export class ProductAssignmentDialogData {
    advertiserId: number;
    assignedUpcs: Array<string>;
    initialProducts: ApiCollectionResponse<AdvertiserProduct>;
    productLimit: number;

    constructor(options?: Partial<ProductAssignmentDialogData>) {
        Object.assign(this, options);
    }
}

export class PromotionParameters {
    advertiserId: number;
    advertiserIds: Array<number>;
    promoTypes: Array<PromotionTypeId>;
    brandIds: Array<number>;
    storeId: number;
    startDate: Date;
    endDate: Date;
    upc: string;
    displayLocations: Array<PromotionDisplayLocationType>;

    constructor(options?: Partial<PromotionParameters>) {
        Object.assign(this, options);
    }
}

export enum PromotionDestinationType {
    ProductList,
    ExternalUrl,
    None,
}

export const promotionDestinationDisplayName = new Map<
    PromotionDestinationType,
    string
>([
    [PromotionDestinationType.ProductList, 'Product List'],
    [PromotionDestinationType.ExternalUrl, 'External Url'],
]);

export enum MediaType {
    Image,
    Video,
}

export const mediaTypeDisplayName = new Map<MediaType, string>([
    [MediaType.Image, 'Image'],
    [MediaType.Video, 'External Video'],
]);

export enum PromotionDisplayLocationType {
    Search = 1,
    OrderCentralDashboard = 2,
    All = 3,
    GcEdiDashboard = 4,
    OrderListPage = 5,
    OrderDetailsPage = 6,
    AddToOrderProductDetailsPage = 7,
    PromotionsPage = 8,
}

export const promotionDisplayLocationName = new Map<
    PromotionDisplayLocationType,
    string
>([
    [PromotionDisplayLocationType.Search, 'Search'],
    [PromotionDisplayLocationType.OrderCentralDashboard, 'OrderCentral Dashboard'],
    [PromotionDisplayLocationType.GcEdiDashboard, 'GCEDI Dashboard'],
    [PromotionDisplayLocationType.OrderListPage, 'Order List Page'],
    [PromotionDisplayLocationType.OrderDetailsPage, 'Order Details Page'],
    [
        PromotionDisplayLocationType.AddToOrderProductDetailsPage,
        'Add To Order Product Details Page',
    ],
    [
        PromotionDisplayLocationType.PromotionsPage,
        'Promotions Page',
    ],
]);

export type PromotionFilterFn = (promotion: Promotion) => boolean;

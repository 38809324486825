import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { customIcons } from '@hq-core/models/icon-registry';

@Injectable({
    providedIn: 'root'
})
export class CustomIconService {

    constructor(
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer
    ) { }

    initialize(): void {
        Object.keys(customIcons).forEach(key => {
            const icon = customIcons[key];
            this.iconRegistry.addSvgIcon(
                icon.name,
                this.sanitizer.bypassSecurityTrustResourceUrl(icon.location));
        });
    }
}

import { AppRole } from './app-role';

export enum UserGroup {
    SalesGenius = 'salesGenius',
    Edi = 'edi',
    CatalogManagement = 'catalogManagement',
    OrderCentral = 'buyhq'
}

export const userGroupDisplayName = new Map<UserGroup, string>([
    [UserGroup.SalesGenius, 'SalesGenius'],
    [UserGroup.Edi, 'EDI'],
    [UserGroup.CatalogManagement, 'Catalog Management'],
    [UserGroup.OrderCentral, 'OrderCentral']
]);

export const userGroupsForAppRoles = new Map<AppRole, Array<UserGroup>>([
    [
        AppRole.Broker,
        [
            UserGroup.SalesGenius,
            UserGroup.Edi
        ]
    ],
    [
        AppRole.Vendor,
        [
            UserGroup.SalesGenius,
            UserGroup.Edi
        ]
    ],
    [
        AppRole.Retailer,
        [
            UserGroup.OrderCentral
        ]
    ],
    [
        AppRole.Internal,
        []
    ],
    [
        AppRole.Buyer,
        [
            UserGroup.Edi
        ]
    ]
]);

import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export interface User {
    appRole: string;
}

export interface IUserService {
    getCurrentUser(): Observable<User>;
}

export const USER_SERVICE = new InjectionToken<IUserService>('UserService');

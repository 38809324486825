import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { SearchFilter } from '@hq-core/models/search-filter';

@Component({
    selector: 'hq-chip-bar',
    templateUrl: './chip-bar.component.html',
    styleUrls: ['./chip-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChipBarComponent {
    @Input() filterChips: Array<SearchFilter>;
    @Output() removeChip = new EventEmitter<SearchFilter>();

    onRemoveChip(event: SearchFilter): void {
        this.removeChip.emit(event);
    }
}

import { User } from './user';
import { UserGroup } from './user-group';

export enum ThemeColor {
    Blue = 'blue',
    Green = 'green',
    Teal = 'teal'
}

export class Theme {
    logoUrl: string;
    color: ThemeColor;

    constructor(options?: Partial<Theme>) {
        Object.assign(this, options);
    }
}

export const defaultThemeColor = ThemeColor.Blue;
export const defaultThemeLogoUrl = 'assets/horiz-blu-cropped.svg';
export const orderCentralThemeLogoUrl = 'assets/order-central-logo.svg';

const themeColorForUserGroup = new Map<UserGroup, ThemeColor>([
    [UserGroup.SalesGenius, ThemeColor.Teal],
    [UserGroup.Edi, ThemeColor.Green],
    [UserGroup.CatalogManagement, ThemeColor.Green],
    [UserGroup.OrderCentral, ThemeColor.Blue]
]);

export function getUserThemeColor(user: User): ThemeColor {
    return themeColorForUserGroup.get(user.userGroup) ?? defaultThemeColor;
}

const logoForUserGroup = new Map<UserGroup, string>([
    [UserGroup.SalesGenius, 'assets/sales-genius-title-logo.svg'],
    [UserGroup.Edi, 'assets/gc-edi-logo.svg'],
    [UserGroup.CatalogManagement, defaultThemeLogoUrl],
    [UserGroup.OrderCentral, orderCentralThemeLogoUrl]
]);

export function getUserLogoUrl(user: User): string {
    return logoForUserGroup.get(user.userGroup) ?? defaultThemeLogoUrl;
}

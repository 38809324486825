export class InternalIdentifiers {
    buyerId: number;
    buyerName: string;
    vendorId: number;
    documentId: number;
    version: number;
    ediDocumentFileName: string;
    uniqueKey: string;
    applicationReceiverId: string;
    applicationSenderId: string;
    receiverId: string;
    senderId: string;

    constructor(options?: Partial<InternalIdentifiers>) {
        Object.assign(this, options);
    }
}

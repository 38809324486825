<mat-card class="card">
    <hq-accent-card-header>
        <mat-icon class="icon">show_chart</mat-icon>
        Wholesale Order Total
    </hq-accent-card-header>

    <mat-card-content>
        <mat-tab-group mat-stretch-tabs
                       dynamicHeight
                       color="accent">
            <mat-tab label="MTD ({{currentMonth}})">
                <div class="tab-wrapper">
                    <div *ngIf="displayDaily else ordersEmptyState">
                        <hq-line-chart [chartPoints]="dailyChartPoints"
                                       timeScale="day">
                        </hq-line-chart>
                    </div>

                    <mat-divider inset></mat-divider>

                    <div class="summary-header">
                        <h3 class="summary-title">
                            Summary
                        </h3>
                        <small class="load-date">Data Last Updated: {{lastUpdated | date:'short'}}</small>
                    </div>
                    <div class="summary-rows">
                        <div class="summary-row">
                            <span><strong>Month to Date Total</strong></span>
                            <span>{{totalMonthSales | currency}}</span>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="YTD ({{currentYear}})">
                <div class="tab-wrapper">
                    <div *ngIf="displayMonthly else ordersEmptyState">
                        <hq-line-chart [chartPoints]="monthlyChartPoints"
                                       timeScale="month">
                        </hq-line-chart>
                    </div>

                    <mat-divider inset></mat-divider>

                    <div class="summary-header">
                        <h3 class="summary-title">
                            Summary
                        </h3>
                        <small class="load-date">Data Last Updated: {{lastUpdated | date:'short'}}</small>
                    </div>
                    <div class="summary-rows">
                        <div class="summary-row">
                            <span><strong>Year to Date Total</strong></span>
                            <span>{{totalYearSales | currency}}</span>
                        </div>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </mat-card-content>
</mat-card>


<ng-template #ordersEmptyState>
    <hq-empty-state heading="No orders yet"
                    subheading="Stay tuned! When you have submitted orders the sales will show up here."
                    icon="timeline">
    </hq-empty-state>
</ng-template>

import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges, } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactInformation } from '@hq-core/models/contact-information';
import { selectableCountries } from '@hq-shared/models/country-options';

@Component({
    selector: 'hq-contact-form',
    templateUrl: './contact-form.component.html',
    styleUrls: ['./contact-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactFormComponent implements OnInit, OnChanges {
    @Input() contactInformation = new ContactInformation();
    @Input() parentFormGroup: FormGroup;
    @Input() formName: string;
    @Input() maskPhone = false;

    formGroup: FormGroup;
    countryOptions = selectableCountries;

    constructor(private formBuilder: FormBuilder) { }

    ngOnInit() {
        const contactInformation = new ContactInformation(this.contactInformation);
        this.formGroup = this.formBuilder.group({
            contact: contactInformation.name || '',
            email: [contactInformation.email || '', Validators.email],
            phone: [contactInformation.phone || '', Validators.required],
            fax: contactInformation.fax || '',
            address1: [contactInformation.address.address1 || '', Validators.required],
            address2: contactInformation.address.address2 || '',
            city: [contactInformation.address.city || '', Validators.required],
            state: [contactInformation.address.state || '', Validators.required],
            postalCode: [contactInformation.address.postalCode || '', Validators.required],
            country: [contactInformation.address.country || '', Validators.required]
        });

        if (this.parentFormGroup) {
            this.parentFormGroup.setControl(this.formName, this.formGroup);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.contactInformation && !changes.contactInformation.firstChange) {
            const contact = new ContactInformation(this.contactInformation);
            this.formGroup.setValue({
                email: contact.email || '',
                contact: contact.name || '',
                phone: contact.phone || '',
                fax: contact.fax || '',
                address1: contact.address.address1 || '',
                address2: contact.address.address2 || '',
                city: contact.address.city || '',
                state: contact.address.state || '',
                postalCode: contact.address.postalCode || '',
                country: contact.address.country || '',
            }, { emitEvent: false });
        }
    }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'displayFromMap'
})
export class DisplayFromMapPipe implements PipeTransform {

    transform(key: string, map?: Map<any, string>): string {
        let mappedValue = key;

        const isMapType = map && map.has && map.get;
        if (isMapType && map.has(key)) {
            mappedValue = map.get(key);
        }

        return mappedValue;
    }
}

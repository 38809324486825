import { User } from '@hq-core/models/user';

export const emailSubjectBuyNow = 'Order Central Free Trial Yes to Buy Lead';

export class FeedbackData {
    user?: User;
    title: string;
    messagePlaceholder: string;

    constructor(options?: Partial<FeedbackData>) {
        Object.assign(this, options);
    }
}

export class Feedback {
    userId: number;
    storeId: number;
    content: string;
    application: string;

    constructor(options?: Partial<Feedback>) {
        Object.assign(this, options);
    }
}

export class FeedbackFormObject {
    firstName: string;
    lastName: string;
    email: string;
    feedback: string;

    constructor(options?: Partial<FeedbackFormObject>) {
        Object.assign(this, options);
    }
}

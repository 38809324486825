import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ProgressBarMode } from '@angular/material/progress-bar';
import { AuthImpersonationService } from '@hq-core/auth/auth-impersonation.service';
import { AuthService } from '@hq-core/auth/auth.service';
import { ProgressBarService, ProgressBarState } from '@cia-front-end-apps/shared/progress-bar';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'hq-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarComponent implements OnInit, OnDestroy {
    @Output() openNavigationMenu = new EventEmitter<void>();

    isAuthorized = false;
    isImpersonating = false;
    progressBarMode: ProgressBarMode = 'determinate';
    private unsubscribe = new Subject<void>();

    constructor(
        private authImpersonationService: AuthImpersonationService,
        private progressBarService: ProgressBarService,
        private authService: AuthService,
        private cdRef: ChangeDetectorRef
    ) {
    }

    ngOnInit(): void {
        this.progressBarService.getState()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((state: ProgressBarState) => {
                this.progressBarMode = state === 'active' ? 'indeterminate' : 'determinate';
                this.cdRef.detectChanges();
            });

        this.authService.getLoginState()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((isAuthorized: boolean) => {
                this.isAuthorized = isAuthorized;
                if (!this.isAuthorized) {
                    this.isImpersonating = false;
                } else {
                    this.isImpersonating = this.authImpersonationService.isImpersonating();
                }
                this.cdRef.detectChanges();
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    onOpenNavigationMenu() {
        this.openNavigationMenu.emit();
    }
}

import { AnalyticsAction, AnalyticsCategory } from '@hq-core/models/analytics';

export class AnalyticsEvent {
    category: string;
    action: string;
    trackedValue: string;
    measuredValue: number;
    userId: number;
    chainStoreId: number;
    appRole: string;
    releaseRole: string;
    userGroup: string;
    buildNumber: string;

    constructor(options?: Partial<AnalyticsEvent>) {
        Object.assign(this, options);
    }
}

export class AnalyticsEventParameters {
    startDate: Date;
    endDate: Date;
    userIds: Array<number>;
    eventCategories: Array<AnalyticsCategory>;
    eventActions: Array<AnalyticsAction>;
    eventLabels: Array<string>;

    constructor(options?: Partial<AnalyticsEventParameters>) {
        Object.assign(this, options);
    }
}

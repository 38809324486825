import { Injector } from '@angular/core';

/**
 * This is the DI injector used by Angular. It is set when the AppModule is initialized. There few
 * situations where we need to access an injectable service in a context not available to Angular
 * DI. Generally, this is not needed and using it should raise a warning flag.
 */
export class AppInjector {
    static injector: Injector;
}

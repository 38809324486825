import { Injectable } from '@angular/core';
import { releaseRoleDisplayName } from '@hq-app/user-management/models/release-role';
import { GoogleAnalyticsService } from '@hq-core/analytics/google-analytics.service';
import { AuthService } from '@hq-core/auth/auth.service';
import { appRoleDisplayName } from '@hq-core/models/app-role';
import { NavigationState } from '@hq-core/models/navigation';
import { User } from '@hq-core/models/user';
import { userGroupDisplayName } from '@hq-core/models/user-group';
import { NavigationService } from '@hq-core/navigation/navigation.service';
import { routeParts } from '@hq-core/routes';
import { UserService } from '@hq-core/user/user.service';
import { environment } from '@hq-environments/environment';
import { of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, skipWhile, switchMap } from 'rxjs/operators';
import {
    analyticsDefaultDimensionKey,
    AnalyticsDimension,
    AnalyticsDimensionType,
    AnalyticsEvent,
    defaultDimensionValue
} from '../models/analytics';
import { DatadogRumService } from './datadog-rum.service';

@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {
    private ignoredRoutes = [
        `/${routeParts.logout}`,
        `/${routeParts.login}`
    ];

    private isAuthenticated = false;
    private customDimensions: Array<AnalyticsDimension>;

    constructor(
        private navigationService: NavigationService,
        private googleAnalyticsService: GoogleAnalyticsService,
        private datadogRumService: DatadogRumService,
        private userService: UserService,
        private authService: AuthService
    ) { }

    initialize(): void {
        this.googleAnalyticsService.initialize(environment.googleAnalyticsId, environment.ga4MeasurementId);
        this.datadogRumService.initialize();
        this.authService.getLoginState()
            .pipe(
                distinctUntilChanged(),
                skipWhile(isAuthenticated => !isAuthenticated)
            )
            .subscribe(isAuthenticated => {
                this.isAuthenticated = isAuthenticated;
            });

        this.navigationService.currentState
            .pipe(
                filter(() => this.isAuthenticated),
                filter((state: NavigationState) => {
                    return state.sanitizedUrl && !this.ignoredRoutes.includes(state.sanitizedUrl);
                }),
                switchMap((state: NavigationState) => {
                    return this.userService.getCurrentUser()
                        .pipe(
                            map(user => {
                                const pageUrl = state.sanitizedUrl;
                                const userId = user.id;
                                this.trackPageView(pageUrl, userId);
                                this.datadogRumService.setUser(user);
                            })
                        );
                })
            )
            .subscribe();
    }

    trackEvent(event: AnalyticsEvent): void {
        this.userService.getCurrentUser()
            .pipe(
                catchError(() => of(new User())),
            )
            .subscribe(user => {
                const customDimensions = user.id ? this.getCustomDimensions(user) : [];
                this.googleAnalyticsService.trackEvent(event, customDimensions);
            });
    }

    private trackPageView(pageUrl: string, userId: number): void {
        this.googleAnalyticsService.trackPageView(pageUrl);
    }

    private getCustomDimensions(user: User): Array<AnalyticsDimension> {
        let dimensions = this.customDimensions;

        if (!this.customDimensions) {
            dimensions = [
                new AnalyticsDimension({
                    type: AnalyticsDimensionType.AppRole,
                    key: analyticsDefaultDimensionKey.get(AnalyticsDimensionType.AppRole),
                    value: appRoleDisplayName.get(user.appRole) || defaultDimensionValue
                }),
                new AnalyticsDimension({
                    type: AnalyticsDimensionType.ReleaseRole,
                    key: analyticsDefaultDimensionKey.get(AnalyticsDimensionType.ReleaseRole),
                    value: releaseRoleDisplayName.get(user.releaseRole) || defaultDimensionValue
                }),
                new AnalyticsDimension({
                    type: AnalyticsDimensionType.UserGroup,
                    key: analyticsDefaultDimensionKey.get(AnalyticsDimensionType.UserGroup),
                    value: userGroupDisplayName.get(user.userGroup) || defaultDimensionValue
                }),
                new AnalyticsDimension({
                    type: AnalyticsDimensionType.UserId,
                    key: analyticsDefaultDimensionKey.get(AnalyticsDimensionType.UserId),
                    value: `${user.id}`
                }),
                new AnalyticsDimension({
                    type: AnalyticsDimensionType.BuildVersion,
                    key: analyticsDefaultDimensionKey.get(AnalyticsDimensionType.BuildVersion),
                    value: environment.buildVersion.includes('{') ? 'developer' : environment.buildVersion
                }),
                new AnalyticsDimension({
                    type: AnalyticsDimensionType.ChainStoreId,
                    key: analyticsDefaultDimensionKey.get(AnalyticsDimensionType.ChainStoreId),
                    value: `${user.currentStore?.id}`
                })
            ];
        }

        return dimensions;
    }
}

import { Injectable } from '@angular/core';
import {
    AnalyticsDimension,
    AnalyticsEvent,
    BaseAnalyticsService,
    googleAnalyticsDimensionKey
} from '../models/analytics';

@Injectable({
    providedIn: 'root'
})
export class GoogleAnalyticsService extends BaseAnalyticsService {
    private customDimensions: Array<AnalyticsDimension>;
    private anayliticsId: string;

    initialize(id: string, ga4id = ''): void {
        // Google Analytics script is requested in index.html
        ga('create', id, 'auto');
        ga('send', 'pageview');
        // the gtag script needs to loaded dynamically as
        // we have to swap out the GA4 id depending on the environment
        this.loadGtag(ga4id);
    }

    trackPageView(page: string): void {
        ga('set', 'page', page);
        ga('send', 'pageview');
        gtag('config', this.anayliticsId, {
            page_path: page
        });
    }

    trackEvent(event: AnalyticsEvent, customDimensions: Array<AnalyticsDimension>): void {
        const eventData = {
            eventCategory: event.category,
            eventAction: event.action,
            eventLabel: event.trackedValue,
            eventValue: event.measuredValue ?? 1,
            ...event.customData
        };

        const mappedDimensions = this.mapCustomDimensionKeys(customDimensions);
        mappedDimensions.forEach(dimension => {
            eventData[dimension.key] = dimension.value;
        });

        ga('send', 'event', eventData);
        gtag('event', event.action, eventData);
    }

    // In Google Analytics, custom data must be mapped to pre-defined dimensions
    private mapCustomDimensionKeys(userDimensions: Array<AnalyticsDimension>): Array<AnalyticsDimension> {
        let dimensions = this.customDimensions;

        if (!this.customDimensions) {
            dimensions = userDimensions.map(dimension => {
                dimension.key = googleAnalyticsDimensionKey.get(dimension.type);
                return dimension;
            });
        }

        return dimensions;
    }

    private loadGtag(id: string): void {
        this.anayliticsId = id;
        const script = document.createElement('script');
        script.async = true;
        script.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;
        document.head.appendChild(script);

        // eslint-disable-next-line @typescript-eslint/dot-notation
        window['dataLayer'] = window['dataLayer'] || [];
        // eslint-disable-next-line @typescript-eslint/dot-notation,prefer-arrow/prefer-arrow-functions
        window['gtag'] = window['gtag'] || function () {
            // eslint-disable-next-line @typescript-eslint/dot-notation,prefer-rest-params
            window['dataLayer'].push(arguments);
        };
        gtag('js', new Date());
        gtag('config', id);
    }
}

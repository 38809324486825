import { NgModule } from '@angular/core';
import { PreventMenuCloseDirective } from './prevent-menu-close.directive';

@NgModule({
    exports: [
        PreventMenuCloseDirective
    ],
    declarations: [
        PreventMenuCloseDirective
    ]
})
export class PreventMenuCloseModule {
}

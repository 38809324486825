import { HttpClientModule } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@hq-shared/shared.module';
import { AppSettingsModule } from './app-settings/app-settings.module';
import { AuthModule } from './auth/auth.module';
import { AutocompleteDialogComponent } from './dialog/autocomplete-dialog/autocomplete-dialog.component';
import { SimpleDialogComponent } from './dialog/simple-dialog/simple-dialog.component';
import { ImpersonationBarComponent } from './impersonation-bar/impersonation-bar.component';
import { NavigationComponent } from './navigation/navigation.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { PageTitleComponent } from './page-title/page-title.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { LoadingDialogComponent } from './dialog/loading-dialog/loading-dialog.component';
import { ErrorDialogComponent } from './dialog/error-dialog/error-dialog.component';

@NgModule({
    imports: [
        SharedModule,
        AuthModule,
        AppSettingsModule,
        RouterModule,
        HttpClientModule
    ],
    exports: [
        NavigationComponent,
        ToolbarComponent
    ],
    declarations: [
        NavigationComponent,
        ToolbarComponent,
        SimpleDialogComponent,
        PageTitleComponent,
        NotFoundComponent,
        AutocompleteDialogComponent,
        ImpersonationBarComponent,
        LoadingDialogComponent,
        ErrorDialogComponent
    ]
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error('CoreModule is already loaded. Import it in the AppModule only');
        }
    }
}

export class Chain {
    id: number;
    name: string;
    storeCount: number;
    activeStoreCount: number;
    preferredSubmissionType: number;

    // HQ only
    displaySubmissionType: string;

    constructor(options?: Partial<Chain>) {
        Object.assign(this, options);
    }
}

import { SafeHtml } from '@angular/platform-browser';

export enum MessageDestination {
    All = 0,
    BuyIq = 1,
    OrderCentral,
    SalesGenius,
    GcEdi
}

export enum MessageStatus {
    Active,
    Inactive,
    Scheduled
}

export class Message {
    id: number;
    destinations: Array<MessageDestination>;
    title: string;
    body: string;
    dateCreated: Date;
    startDate: Date;
    endDate: Date;
    elevated: boolean;
    sanitizedBody?: SafeHtml;

    // HQ Display field
    statusName?: string;
    destinationNames?: string;

    constructor(options?: Partial<Message>) {
        Object.assign(this, options);
    }
}

export class MessageForm {
    title: string;
    body: string;
    startDate: Date;
    startTime: string;
    endDate: Date;
    endTime: string;
    isElevated: boolean;
    destinations: Array<MessageDestination>;

    constructor(options?: Partial<MessageForm>) {
        Object.assign(this, options);
    }
}

export class MessageParameters {
    elevated: boolean;
    startDate: Date;
    endDate: Date;
    destination: MessageDestination;

    constructor(options?: Partial<MessageParameters>) {
        Object.assign(this, options);
    }
}

export const messageDestinationName = new Map<MessageDestination, string>([
    [MessageDestination.All, 'All'],
    [MessageDestination.OrderCentral, 'OrderCentral'],
    [MessageDestination.BuyIq, 'BUYiQ'],
    [MessageDestination.SalesGenius, 'SalesGenius'],
    [MessageDestination.GcEdi, 'GCEDI']
]);

export const messageStatusName = new Map<MessageStatus, string>([
    [MessageStatus.Active, 'Active'],
    [MessageStatus.Inactive, 'Inactive'],
    [MessageStatus.Scheduled, 'Scheduled']
]);

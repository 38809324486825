import { CommonModule, CurrencyPipe, DatePipe, PercentPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FilterMenuModule } from '@cia-front-end-apps/shared/filter-menu';
import { PreventMenuCloseModule } from '@cia-front-end-apps/shared/prevent-menu-close';
import { SelectOnClickModule } from '@cia-front-end-apps/shared/select-on-click';
import { ActionItemsComponent } from '@hq-shared/action-items/action-items.component';
import { LineChartComponent } from '@hq-shared/line-chart/line-chart.component';
import { QuantityFieldComponent } from '@hq-shared/quantity-field/quantity-field.component';
import { AccentCardHeaderComponent } from './accent-card-header/accent-card-header.component';
import { AutocompleteFormFieldComponent } from './autocomplete-form-field/autocomplete-form-field.component';
import { AutocompleteMultiSelectComponent } from './autocomplete-multi-select/autocomplete-multi-select.component';
import { BarChartComponent } from './bar-chart/bar-chart.component';
import { CalloutComponent } from './callout/callout.component';
import { CatalogUpgradeRequestComponent } from './catalog-upgrade-request/catalog-upgrade-request.component';
import { ChipBarComponent } from './chip-bar/chip-bar.component';
import { CompletedStepDirective } from './completed-step/completed-step.directive';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { ContainsKeyPipe } from './contains-key/contains-key.pipe';
import { DenseFilterMenuComponent } from './dense-filter-menu/dense-filter-menu.component';
import { DisplayAdComponent } from './display-ad/display-ad.component';
import { DisplayFromMapPipe } from './display-from-map/display-from-map.pipe';
import { DndDirective } from './drag-and-drop/drag-and-drop.directive';
import { DropZoneComponent } from './drop-zone/drop-zone.component';
import { EmptyStateComponent } from './empty-state/empty-state.component';
import { EvaluateOnChangePipe } from './evaluate-on-change/evaluate-on-change.pipe';
import { FabContainerComponent } from './fab-container/fab-container.component';
import { FilterMenuComponent } from './filter-menu/filter-menu.component';
import { LegacyOrderToolbarComponent } from './legacy-order-toolbar/legacy-order-toolbar.component';
import { LinkSnackBarComponent } from './link-snackbar/link-snack-bar.component';
import { MaterialModule } from './material/material.module';
import { MemoComponent } from './memo/memo.component';
import { OrderQuantityFieldComponent } from './order-quantity-field/order-quantity-field.component';
import { OrderToolbarComponent } from './order-toolbar/order-toolbar.component';
import { OrderTotalStatusComponent } from './order-total-status/order-total-status.component';
import { PhoneMaskDirective } from './phone-mask/phone-mask.directive';
import { PhoneMaskPipe } from './phone-mask/phone-mask.pipe';
import { PrintContainerComponent } from './print-container/print-container.component';
import { ProductSpecialIconComponent } from './product-special-icon/product-special-icon.component';
import { ProductStatusIconComponent } from './product-status-icon/product-status-icon.component';
import { ProductThumbnailComponent } from './product-thumbnail/product-thumbnail.component';
import { RoutablePageComponent } from './routable-page/routable-page.component';
import { SortMenuOptionComponent } from './sort-menu-option/sort-menu-option.component';
import { SortMenuComponent } from './sort-menu/sort-menu.component';
import { SortMultiColumnsDirective } from './sort-multi-columns/sort-multi-columns.directive';
import { StatusCardComponent } from './status-card/status-card.component';
import { SubPageLinkComponent } from './sub-page-link/sub-page-link.component';
import { SubPageComponent } from './sub-page/sub-page.component';
import { SystemMessagesComponent } from './system-messages/system-messages.component';
import { TextFilterComponent } from './text-filter/text-filter.component';
import { TextInputDialogComponent } from './text-input-dialog/text-input-dialog.component';
import { TimeLeftBannerComponent } from './time-left-banner/time-left-banner.component';
import { TitledPanelComponent } from './titled-panel/titled-panel.component';
import { ToggleFormFieldComponent } from './toggle-form-field/toggle-form-field.component';
import { UpcCorrectionComponent } from './upc-correction/upc-correction.component';
import { VendorFormComponent } from './vendor-form/vendor-form.component';
import { MarkdownPipe } from './markdown/markdown.pipe';
import { WholesaleTotalChartComponent } from '@hq-shared/wholesale-total-chart/wholesale-total-chart.component';
import { CustomTooltipComponent } from './custom-tooltip/custom-tooltip.component';
import { TooltipRendererDirective } from '@hq-shared/custom-tooltip/tooltip-renderer.directive';
import {
    MatSelectRemoveAriaOwnsDirective
} from './mat-select-remove-aria-owns-directive.directive';
import { NgxMaskDirective } from 'ngx-mask';
import { IsVisibleDirective } from './is-visible/is-visible.directive';
import {
    ProductImageProviderComponent
} from '@cia-front-end-apps/shared/product-image-provider/product-image-provider.component';
import { SubscribeDirective } from '@ngneat/subscribe';

export const sharedDeclarations = [
    TitledPanelComponent,
    SubPageLinkComponent,
    SubPageComponent,
    EmptyStateComponent,
    StatusCardComponent,
    TextFilterComponent,
    VendorFormComponent,
    RoutablePageComponent,
    FabContainerComponent,
    SortMenuComponent,
    SortMenuOptionComponent,
    ChipBarComponent,
    LegacyOrderToolbarComponent,
    OrderTotalStatusComponent,
    MemoComponent,
    DropZoneComponent,
    LinkSnackBarComponent,
    ProductThumbnailComponent,
    ContactFormComponent,
    SystemMessagesComponent,
    AutocompleteFormFieldComponent,
    OrderQuantityFieldComponent,
    ProductStatusIconComponent,
    ProductSpecialIconComponent,
    PrintContainerComponent,
    FilterMenuComponent,
    LineChartComponent,
    AccentCardHeaderComponent,
    TextInputDialogComponent,
    BarChartComponent,
    TimeLeftBannerComponent,
    ActionItemsComponent,
    UpcCorrectionComponent,
    ToggleFormFieldComponent,
    AutocompleteMultiSelectComponent,
    CalloutComponent,
    CatalogUpgradeRequestComponent,
    DenseFilterMenuComponent,
    QuantityFieldComponent,
    OrderToolbarComponent,
    DisplayAdComponent,
    WholesaleTotalChartComponent,
    CustomTooltipComponent,
    ProductImageProviderComponent
];

export const sharedDirectives = [
    DndDirective,
    SortMultiColumnsDirective,
    CompletedStepDirective,
    PhoneMaskDirective,
    CustomTooltipComponent,
    TooltipRendererDirective,
    MatSelectRemoveAriaOwnsDirective
];

export const sharedPipes = [
    DisplayFromMapPipe,
    PhoneMaskPipe,
    EvaluateOnChangePipe,
    ContainsKeyPipe,
    MarkdownPipe
];

export const sharedProviders = [
    PhoneMaskPipe,
    CurrencyPipe,
    DatePipe,
    PercentPipe
];

export const sharedImports = [
    FilterMenuModule,
    SelectOnClickModule,
    PreventMenuCloseModule,
    NgxMaskDirective
];

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        ...sharedImports,
        NgxMaskDirective,
        SubscribeDirective
    ],
    exports: [
        CommonModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        ...sharedImports,
        ...sharedDeclarations,
        ...sharedDirectives,
        ...sharedPipes,
        NgxMaskDirective,
        IsVisibleDirective
    ],
    declarations: [
        ...sharedDeclarations,
        ...sharedDirectives,
        ...sharedPipes,
        IsVisibleDirective,
    ],
    providers: [
        ...sharedProviders
    ]
})
export class SharedModule {
}

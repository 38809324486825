import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FreeTrialLogoResolver } from '@hq-app/free-trial/free-trial-logo-resolver.service';
import { AuthModuleGuard } from '@hq-core/auth/auth-module.guard';
import { AuthRoleGuard } from '@hq-core/auth/auth-role.guard';
import { AuthGuard } from '@hq-core/auth/auth.guard';
import { UnauthorizedComponent } from '@hq-core/auth/unauthorized/unauthorized.component';
import { AppModule } from '@hq-core/models/app-module';
import { AppRole } from '@hq-core/models/app-role';
import { NotFoundComponent } from '@hq-core/not-found/not-found.component';
import { pageTitles, routeParts } from '@hq-core/routes';
import { UserResolver } from '@hq-core/user/user.resolver';
import { analyticsTitleResolver } from '@hq-app/retailer-analytics/resolvers/analytics-title.resolver';

const definitionsThatMustBeFirst: Routes = [
    {
        path: routeParts.login,
        data: {
            title: pageTitles.login,
        },
        loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
    },
    {
        path: routeParts.freeTrial,
        data: {
            title: pageTitles.freeTrial,
        },
        resolve: [FreeTrialLogoResolver],
        loadChildren: () => import('./free-trial/free-trial.module').then(m => m.FreeTrialModule),
    },
    {
        path: routeParts.supplierGeniusTraining,
        data: {
            title: pageTitles.supplierGeniusTraining,
        },
        loadChildren: () => import('./supplier-genius-training/supplier-genius-training.module')
            .then(m => m.SupplierGeniusTrainingModule),
    },
];

const definitionsThatMustBeLast: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: routeParts.dashboard
    },
    {
        path: '**',
        pathMatch: 'full',
        component: NotFoundComponent,
        data: {
            title: pageTitles.notFound
        },
        canActivate: [AuthGuard]
    }
];

// The paths with a component specified are eagerly loaded. They are components needed when the app
// loads. They should be part of the CoreModule.
// The paths with loadChildren are lazy-loaded. They should be part of feature modules.
export const routes: Routes = [
    ...definitionsThatMustBeFirst,
    {
        path: '',
        canActivateChild: [AuthGuard],
        resolve: {
            user: UserResolver
        },
        children: [
            {
                path: routeParts.dashboard,
                data: {
                    title: pageTitles.dashboard
                },
                loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
                canLoad: [AuthGuard]
            },
            {
                path: routeParts.products,
                data: {
                    title: pageTitles.productSearch,
                    appModule: AppModule.Search
                },
                loadChildren: () => import('./search/search.module').then(m => m.SearchModule),
                canLoad: [AuthGuard, AuthModuleGuard]
            },
            {
                path: routeParts.orders,
                data: {
                    title: pageTitles.ordering,
                    appModule: AppModule.Ordering
                },
                loadChildren: () => import('./ordering/ordering.module').then(m => m.OrderingModule),
                canLoad: [AuthGuard, AuthModuleGuard]
            },
            {
                path: routeParts.suppliers,
                data: {
                    title: pageTitles.supplierManagement,
                    appModule: AppModule.SupplierManagement
                },
                loadChildren: () => import('./retailer-suppliers/retailer-suppliers.module').then(m => m.RetailerSuppliersModule),
                canLoad: [AuthGuard, AuthModuleGuard]
            },
            {
                path: routeParts.storeManagementTools,
                data: {
                    title: pageTitles.storeManagementTools,
                    appModule: AppModule.StoreManagementTools
                },
                loadChildren: () =>
                    import('./store-management-tools/store-management-tools.module').then(m => m.StoreManagementToolsModule),
                canLoad: [AuthGuard, AuthModuleGuard]
            },
            {
                path: routeParts.brokerCcOrders,
                data: {
                    title: pageTitles.brokerCcOrders,
                    appModule: AppModule.BrokerCcOrders
                },
                loadChildren: () => import('./broker-cc-orders/broker-cc-orders.module').then(m => m.BrokerCcOrdersModule),
                canLoad: [AuthGuard, AuthModuleGuard]
            },
            {
                path: routeParts.brokerStores,
                data: {
                    title: pageTitles.brokerStores,
                    appModule: AppModule.BrokerServices
                },
                loadChildren: () => import('./broker-stores/broker-stores.module').then(m => m.BrokerStoresModule),
                canLoad: [AuthGuard, AuthModuleGuard]
            },
            {
                path: routeParts.brokerDataManagement,
                data: {
                    title: pageTitles.brokerDataManagement,
                    appModule: AppModule.BrokerDataManagement
                },
                loadChildren: () => import('./broker-data-management/broker-data-management.module').then(
                    m => m.BrokerDataManagementModule
                ),
                canLoad: [AuthGuard, AuthModuleGuard]
            },
            {
                path: routeParts.vendorMapping,
                data: {
                    title: pageTitles.vendorMapping,
                    appModule: AppModule.VendorMapping
                },
                loadChildren: () => import('./vendor-mapping/vendor-mapping.module').then(m => m.VendorMappingModule),
                canLoad: [AuthGuard, AuthModuleGuard]
            },
            {
                path: routeParts.detaSynq,
                data: {
                    title: pageTitles.detaSynq,
                    appModule: AppModule.DetaSynq
                },
                loadChildren: () => import('./deta-synq/deta-synq.module').then(m => m.DetaSynqModule),
                canLoad: [AuthGuard, AuthModuleGuard]
            },
            {
                path: routeParts.userManagement,
                data: {
                    title: pageTitles.userManagement,
                    appModule: AppModule.UserManagement

                },
                loadChildren: () => import('./user-management/user-management.module').then(m => m.UserManagementModule),
                canLoad: [AuthGuard, AuthModuleGuard]
            },
            {
                path: routeParts.reports,
                data: {
                    title: pageTitles.reports,
                    appModule: AppModule.Reports
                },
                loadChildren: () => import('./reporting/reporting.module').then(m => m.ReportingModule),
                canLoad: [AuthGuard, AuthModuleGuard]
            },
            {
                path: routeParts.monitoring,
                data: {
                    title: pageTitles.monitoring,
                    appModule: AppModule.Monitoring
                },
                loadChildren: () => import('./monitoring/monitoring.module').then(m => m.MonitoringModule),
                canLoad: [AuthGuard, AuthModuleGuard]
            },
            {
                path: routeParts.promotionsManagement,
                data: {
                    title: pageTitles.promotionsManagement,
                    appModule: AppModule.PromotionsManagement

                },
                loadChildren: () => import('./promotions-management/promotions-management.module').then(m => m.PromotionsManagementModule),
                canLoad: [AuthGuard, AuthModuleGuard]
            },
            {
                path: routeParts.promotions,
                data: {
                    title: pageTitles.promotions,
                    appModule: AppModule.Ordering
                },
                loadChildren: () => import('./promotions/promotions.module').then(m => m.PromotionsModule),
                canLoad: [AuthGuard, AuthModuleGuard]
            },
            {
                path: routeParts.advertising,
                data: {
                    title: pageTitles.advertising,
                    appModule: AppModule.Advertising
                },
                loadChildren: () => import('./advertising/advertising.module').then(m => m.AdvertisingModule),
                canLoad: [AuthGuard, AuthModuleGuard]
            },
            {
                path: routeParts.analytics,
                data: {
                    appModule: AppModule.Analytics
                },
                resolve: {
                  title: analyticsTitleResolver
                },
                loadChildren: () => import('./retailer-analytics/retailer-analytics.module').then(m => m.RetailerAnalyticsModule),
                canLoad: [AuthGuard, AuthModuleGuard]
            },
            {
                path: `${routeParts.analytics}/${routeParts.reports}`,
                data: {
                    title: pageTitles.reports,
                    appModule: AppModule.Analytics
                },
                loadChildren: () => import('./retailer-reporting/retailer-reporting.module').then(m => m.RetailerReportingModule),
                canLoad: [AuthGuard, AuthModuleGuard]
            },
            {
                path: routeParts.savedItems,
                data: {
                    title: pageTitles.savedItems,
                    appModule: AppModule.SavedItems
                },
                loadChildren: () => import('./saved-items/saved-items.module').then(m => m.SavedItemsModule),
                canLoad: [AuthGuard, AuthModuleGuard]
            },
            {
                path: routeParts.systemMessages,
                data: {
                    title: pageTitles.systemMessages,
                    appModule: AppModule.SystemMessages,
                    appRoles: [AppRole.Internal]
                },
                loadChildren: () => import('./system-messages/system-messages.module').then(m => m.SystemMessagesModule),
                canLoad: [AuthGuard, AuthModuleGuard, AuthRoleGuard]
            },
            {
                path: routeParts.trainingResources,
                data: {
                    title: pageTitles.trainingResources,
                    appModule: AppModule.TrainingResources,
                    appRoles: [AppRole.Internal]
                },
                loadChildren: () => import('./training-resources/training-resources.module').then(m => m.TrainingResourcesModule),
                canLoad: [AuthGuard, AuthModuleGuard, AuthRoleGuard]
            },

            {
                path: routeParts.settings,
                data: {
                    title: pageTitles.settings
                },
                loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
                canLoad: [AuthGuard]
            },
            {
                path: routeParts.help,
                data: {
                    title: pageTitles.help
                },
                loadChildren: () => import('./help/help.module').then(m => m.HelpModule),
                canLoad: [AuthGuard]
            },
            {
                path: routeParts.enterpriseSystems,
                data: {
                    title: pageTitles.enterpriseSystems
                },
                loadChildren: () => import('./enterprise-systems/enterprise-systems.module').then(m => m.EnterpriseSystemsModule),
                canLoad: [AuthGuard]
            },
            {
                path: routeParts.internalVendorMapping,
                data: {
                    title: pageTitles.internalVendorMapping
                },
                loadChildren: () => import('./internal-vendor-mapping/internal-vendor-mapping.module')
                            .then(m => m.InternalVendorMappingModule),
                canLoad: [AuthGuard]
            },

            {
                path: routeParts.logout,
                data: {
                    title: pageTitles.logout
                },
                loadChildren: () => import('./logout/logout.module').then(m => m.LogoutModule),
                canLoad: [AuthGuard]
            },
            {
                path: routeParts.unauthorized,
                data: {
                    title: pageTitles.unauthorized
                },
                component: UnauthorizedComponent,
                canLoad: [AuthGuard]
            },
            ...definitionsThatMustBeLast
        ]
    }
];

@NgModule({
    // Set enableTracing to true to view routing events in the console
    imports: [RouterModule.forRoot(routes, { enableTracing: false })],
    exports: [RouterModule]
})
export class AppRoutingModule { }

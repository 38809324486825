import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '@hq-core/models/app-settings';
import { Message, MessageParameters } from '@hq-core/models/messages';
import { DataResource } from '@hq-custom-types';
import { ApiCollectionResponse, SearchState } from '@cia-front-end-apps/shared/api-interaction';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MessageResource implements DataResource<Message> {
    private baseUrl: string;

    constructor(private http: HttpClient, private appSettings: AppSettings) {
        this.baseUrl = `${this.appSettings.apis.promo}`;
    }

    getAll(searchState: SearchState, messageParameters: MessageParameters): Observable<ApiCollectionResponse<Message>> {
        const url = this.buildUrl();
        const params = new HttpParams({
            fromObject: {
                sortBy: searchState.sortBy || 'endDate',
                pageIndex: (searchState.pageIndex || 0).toString(),
                sortDirection: searchState.sortDirection || 'asc',
                pageSize: (searchState.pageSize || 25).toString(),
                search: (searchState.search || '').toString(),
                startDate: (messageParameters.startDate?.toISOString() || ''),
                endDate: (messageParameters.endDate?.toISOString() || ''),
                elevated: (messageParameters.elevated || '').toString(),
                // 0 is a valid option, so we only want a null check here
                destination: (messageParameters.destination ?? '').toString()
            }
        });

        return this.http.get<ApiCollectionResponse<Message>>(url, { params });
    }

    create(message: Message): Observable<Message> {
        const url = this.buildUrl();
        return this.http.post<Message>(url, message);
    }

    replace(messageId: number, message: Message): Observable<Message> {
        const url = this.buildUrl(messageId);
        return this.http.put<Message>(url, message);
    }

    private buildUrl(messageId?: number): string {
        let url = `${this.baseUrl}/system-messages`;

        if (messageId) {
            url = `${url}/${messageId}`;
        }

        return url;
    }
}

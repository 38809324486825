<h1 class="heading">
    {{heading}}
</h1>

<h4 *ngIf="subheading"
    class="subheading">
    {{subheading}}
</h4>

<div class="icon-container">
    <mat-icon class="icon">
        {{icon}}
    </mat-icon>
</div>

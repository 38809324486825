export class Company {
    address1: string;
    address2: string;
    city: string;
    contact: string;
    email: string;
    emailCC: string;
    id: number;
    name: string;
    phone: string;
    fax: string;
    state: string;
    zip: string;
    country?: string;

    constructor(options?: Partial<Company>) {
        Object.assign(this, options);
    }
}

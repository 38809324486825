<ng-content select=".extended-toolbar-actions"></ng-content>

<hq-text-filter [placeholder]="filterDisplayText"
                [textOverride]="textOverride"
                [isDisabled]="!canUseFilter"
                (changeFilter)="onChangeTextFilter($event)">
</hq-text-filter>

<button [matMenuTriggerFor]="filterMenu"
        class="filter-menu-button"
        color="accent"
        mat-icon-button
        type="button">
    <mat-icon>filter_list</mat-icon>
</button>

<mat-menu #filterMenu="matMenu"
          class="filter-menu"
          xPosition="before"
          yPosition="below">
    <ng-template matMenuContent>
        <gc-filter-menu [sortOptions]="sortOptions"
                        [filterGroups]="filterGroups"
                        (changeSort)="onChangeSortOption($event)"
                        (changeFilters)="onChangeFilters($event)"
                        (clearFilters)="onClearFilters($event)">
        </gc-filter-menu>
    </ng-template>
</mat-menu>

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@hq-core/auth/auth.service';
import { AppRole } from '@hq-core/models/app-role';
import { User } from '@hq-core/models/user';
import { routeParts } from '@hq-core/routes';
import { UserService } from '@hq-core/user/user.service';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AuthRoleGuard  {

    constructor(
        private authService: AuthService,
        private userService: UserService,
        private router: Router
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.validateUserAppRole(route.data.appRoles);
    }

    canLoad(route: Route): Observable<boolean> {
        return this.validateUserAppRole(route.data.appRoles);
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.validateUserAppRole(childRoute.data.appRoles);
    }

    private validateUserAppRole(authorizedAppRoles: Array<AppRole>): Observable<boolean> {
        return this.authService.isAuthenticated()
            .pipe(
                switchMap(isLoggedIn => {
                    return isLoggedIn ? this.hasAuthorizedAppRole(authorizedAppRoles) : of(isLoggedIn);
                })
            );
    }

    private hasAuthorizedAppRole(authorizedAppRoles: Array<AppRole> = []): Observable<boolean> {
        return this.userService.getCurrentUser()
            .pipe(
                map((user: User) => {
                    const hasAuthorizedRole = authorizedAppRoles.includes(user.appRole);
                    if (!hasAuthorizedRole) {
                        this.router.navigate([`/${routeParts.unauthorized}`]);
                    }

                    return hasAuthorizedRole;
                })
            );
    }
}

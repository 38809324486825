import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import moment from 'moment/moment';
import { ChartPoint } from 'chart.js';
import { Subject } from 'rxjs';
import { SalesAnalyticsService } from '@hq-app/retailer-analytics/services/sales-analytics.service';
import { SalesChartService } from '@hq-core/sales-chart/sales-chart.service';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'hq-wholesale-total-chart',
    templateUrl: './wholesale-total-chart.component.html',
    styleUrls: ['./wholesale-total-chart.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WholesaleTotalChartComponent implements OnInit, OnDestroy {
    currentMonth = moment().format('MMMM');
    currentYear = moment().format('YYYY');
    displayDaily = true;
    displayMonthly = true;
    totalMonthSales = 0;
    totalYearSales = 0;
    dailyChartPoints: Array<ChartPoint> = [];
    monthlyChartPoints: Array<ChartPoint> = [];
    lastUpdated: Date;

    private readonly unsubscribe = new Subject<void>();

    constructor(
        private salesAnalyticsService: SalesAnalyticsService,
        private salesChartService: SalesChartService,
        private cdRef: ChangeDetectorRef
    ) {
    }

    ngOnInit() {
        this.salesAnalyticsService.getSalesSummary()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(salesSummary => {
                this.lastUpdated = salesSummary.lastUpdated;

                this.dailyChartPoints = this.salesChartService.getDailyChartData(salesSummary.dailySales);
                this.monthlyChartPoints = this.salesChartService.getMonthlyChartData(salesSummary.monthlySales);

                this.totalMonthSales = salesSummary.dailySales.reduce((acc, cur) => acc + cur.total, 0);
                this.totalYearSales = salesSummary.monthlySales.reduce((acc, cur) => acc + cur.total, 0);

                this.displayDaily = salesSummary.dailySales?.length > 0;
                this.displayMonthly = salesSummary.monthlySales?.length > 0;

                this.cdRef.detectChanges();
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}

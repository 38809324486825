<div gcPreventMenuClose
     class="menu-container">
    <div class="menu-header">
        <h3 class="title">{{heading}}</h3>
        <button mat-button
                class="button"
                type="button"
                color="accent"
                (click)="onClearFilters()">
            Clear
        </button>
    </div>

    <div class="filter-controls">
        <mat-button-toggle-group name="letterFilter"
                                 class="button-toggle-group"
                                 [attr.aria-label]="heading"
                                 [value]="selectedFilterGroupId"
                                 (change)="onChangeFilterGroup($event)">
            <div *ngFor="let row of filterGroupRows"
                 class="button-toggle-row">
                <mat-button-toggle *ngFor="let filter of row"
                                   [value]="filter.id"
                                   class="button-toggle">
                    {{filter.displayName}}
                </mat-button-toggle>
            </div>
        </mat-button-toggle-group>
    </div>

    <mat-selection-list gcPreventMenuClose
                        color="accent"
                        class="filter-list"
                        [formControl]="formControl"
                        [compareWith]="isSameFilter"
                        (selectionChange)="onChangeFilter($event)">
        <cdk-virtual-scroll-viewport itemSize="48"
                                     minBufferPx="384"
                                     maxBufferPx="480"
                                     class="viewport"
                                     #viewPort>
            <mat-list-option *cdkVirtualFor="let filter of visibleFilters; trackBy: filterTrackBy"
                             [value]="filter"
                             [selected]="selectedFilterIds | containsKey:filter.key">
                {{filter.description}}
            </mat-list-option>
        </cdk-virtual-scroll-viewport>
    </mat-selection-list>
</div>

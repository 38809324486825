import { Injectable } from '@angular/core';
import { DailySummary, MonthlySummary } from '@hq-app/retailer-analytics/models/sales-summary';
import { ChartPoint } from 'chart.js';
import moment, { Moment } from 'moment';

@Injectable({
    providedIn: 'root'
})
export class SalesChartService {
    getDaysInMonth(): Array<Moment> {
        const daysInMonth = Array.from(
            { length: moment().daysInMonth() },
            (day, index) => moment().clone().startOf('month').add(index, 'days')
        );
        return daysInMonth;
    }

    getMonthsInYear(): Array<Moment> {
        const monthsInYear = 12;
        const months = Array.from(
            { length: monthsInYear },
            (month, index) => moment().clone().month(index).startOf('month')
        );
        return months;
    }

    getDailyChartData(dailySales: Array<DailySummary>): Array<ChartPoint> {
        const daysInMonth = this.getDaysInMonth();

        return daysInMonth.map(day => {
            const total = dailySales.find(dailySale => {
                const date = new Date(dailySale.date);
                return day.isSame(date, 'day');
            })?.total ?? null;

            return {
                y: total,
                x: day
            };
        });
    }

    getMonthlyChartData(monthlySales: Array<MonthlySummary>): Array<ChartPoint> {
        const monthsInYear = this.getMonthsInYear();

        return monthsInYear.map(month => {
            const total = monthlySales.find(monthlySale => {
                return Number(monthlySale.month) === month.month() + 1;
            })?.total ?? null;

            return {
                y: total,
                x: month
            };
        });
    }
}

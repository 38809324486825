<mat-card *ngIf="hasMessages"
          class="card">
    <mat-card-content>
        <div *ngFor="let message of messages;"
             class="message">
            <mat-card-title>{{message.title}}</mat-card-title>
            <div [innerHTML]="message.sanitizedBody"
                 class="message-body">
            </div>
        </div>
    </mat-card-content>
</mat-card>

import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { FormValidators } from '@hq-core/forms/form-validators';
import { FormFieldConfiguration, FormGroupValueFn, FormUpdateTrigger } from '../models/forms';

@Injectable({
    providedIn: 'root'
})
export class FormsService {

    /**
     * @description Returns a form control based on the given options
     * If the field is required, a required validator will be added.
     * If a form group value function is passed for a required field, a conditional required
     * validator will be added.
     * If the field is not configured, it will be set to empty and disabled.
     * If the field is marked readonly, the initial value will be used, but the control will be disabled.
     * @param initialValue The value to default the form control to
     * @param validators Default validators for this control
     * @param config Configuration for the field
     * @param formGroupValueFn A function that returns the value of the form group
     *  to which this control is attached
     * @param updateOn The trigger which will cause the form control to update its
     * value
     */
    mapFormField(options: {
        initialValue: any;
        validators: Array<ValidatorFn>;
        config?: FormFieldConfiguration;
        formGroupValueFn?: FormGroupValueFn;
        updateOn?: FormUpdateTrigger;
    }): FormControl {
        const isFieldUsed = !!(options.config);
        const isReadOnly = options.config?.isReadOnly;
        const additionalValidators = [];

        if (options.config?.isRequired) {
            const validator = this.getRequiredValidatorFn(options.formGroupValueFn);
            additionalValidators.push(validator);
        }

        const allValidators = [
            ...(options.validators || []),
            ...additionalValidators
        ];

        return new FormControl(
            {
                value: isFieldUsed ? options.initialValue : '',
                disabled: !isFieldUsed || isReadOnly
            },
            {
                validators: allValidators,
                updateOn: options.updateOn
            }
        );
    }

    getRequiredValidatorFn(formGroupValueFn?: FormGroupValueFn): ValidatorFn {
        return formGroupValueFn ? this.requiredIfSectionHasValue(formGroupValueFn) : Validators.required;
    }

    revalidateGroup(formGroup: AbstractControl): void {
        const formGroupControls = (formGroup as FormGroup).controls || {};

        Object.values(formGroupControls).forEach(childControl => {
            const childFormGroup = childControl as FormGroup;
            childFormGroup.updateValueAndValidity({
                onlySelf: false,
                emitEvent: false
            });

            if (childFormGroup.controls) {
                this.revalidateGroup(childFormGroup);
            }
        });
    }

    markGroupAsTouched(formGroup: AbstractControl): void {
        const formGroupControls = (formGroup as FormGroup).controls || {};

        Object.values(formGroupControls).forEach(childControl => {
            const childFormGroup = childControl as FormGroup;
            childFormGroup.markAsTouched();

            if (childFormGroup.controls) {
                this.markGroupAsTouched(childFormGroup);
            }
        });
    }

    /**
     * @description Returns true if the given form value is neither null, undefined, or empty
     * @param value the value of a single form property
     */
    hasValueBeenSet(value: any): boolean {
        return value !== null &&
            value !== undefined &&
            value !== '';
    }

    /**
     * @description Determines if the given form has any values set. This will examine the form value
     * recursively if it has child properties
     * @param form the form to be checked for value
     */
    hasFormData(form: any): boolean {
        if (form && (typeof form === 'object')) {
            return Object.values(form).some(childForm => this.hasFormData(childForm));
        }

        return this.hasValueBeenSet(form);
    }

    /**
     * @description Allows a field to be marked required only if a designated section is not empty.
     *  The validator requires a function to be passed
     * @param formGroupValueFn a function which gets the value of the form group
     *  holding this control
     */
    private requiredIfSectionHasValue(formGroupValueFn: FormGroupValueFn): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const formValue = formGroupValueFn();
            const formHasValues = this.hasFormData(formValue);

            // If the form only has one field in use, we shouldn't mark it required on optional sections
            const hasMultipleFields = this.countLeafNodes(formValue) > 1;
            const controlHasValue = this.hasValueBeenSet(control.value);
            const hasError = formHasValues && hasMultipleFields && !controlHasValue;

            return hasError ? { required: true } : null;
        };
    }

    validateWhitespaceIfRequired(
        config: FormFieldConfiguration = new FormFieldConfiguration()
    ): ValidatorFn {
        const { isRequired, isReadOnly } = config;
        const shouldApplyWhitespaceValidator = isRequired && !isReadOnly;
        return shouldApplyWhitespaceValidator ? FormValidators.isNotWhitespace : Validators.nullValidator;
    }

    private countLeafNodes(form: any): number {
        if (!form) {
            return 0;
        }

        return Object.keys(form).reduce((acc, current) => {
            let numberOfProperties = 1;
            const prop = form[current];
            if (prop && (typeof prop === 'object')) {
                numberOfProperties = this.countLeafNodes(current);
            }

            return acc + numberOfProperties;
        }, 0);
    }
}

export class AnalyticsEvent {
    category: AnalyticsCategory;
    action: AnalyticsAction;
    trackedValue: string;
    measuredValue?: number;
    customData?: any;

    constructor(options?: Partial<AnalyticsEvent>) {
        Object.assign(this, options);
    }
}

export class BaseAnalyticsService {
    initialize(id: string): void {
    }

    trackPageView(page: string, userId?: number): void {
    }

    trackEvent(event: AnalyticsEvent, customDimensions: Array<AnalyticsDimension>): void {
    }
}

export enum AnalyticsCategory {
    Authentication = 'authentication',
    Product = 'product',
    Order = 'order',
    Invoice = 'invoice',
    Analytics = 'analytics',
    Search = 'search',
    Receiving = 'receiving',
    OrderPlusTooling = 'orderPlusTooling',
    SalesGenius = 'salesGenius',
    OrderSubmission = 'orderSubmission',
    OrderHistory = 'orderHistory',
    MovementData = 'movementData',
    SupplierManagement = 'supplierManagement',
    SavedItems = 'myFavoriteItems',
    ItemQuantityAlert = 'itemQuantityAlert'
}

export enum AnalyticsAction {
    Login = 'login',
    Logout = 'logout',
    View = 'view',
    Search = 'search',
    Print = 'print',
    PageSize = 'pageSize',
    Acknowledge = 'acknowledge',
    Reject = 'reject ',
    Commit = 'commit',
    Invoice = 'invoice',
    Export = 'export',
    Navigate = 'navigate',
    BulkAcknowledge = 'bulkAcknowledge',
    ChangePassword = 'changePassword',
    ResetPassword = 'resetPassword',
    ReturnNoResults = 'returnNoResult',
    ClickTrendingProducts = 'clickTrendingProducts',
    ClickViewOption = 'clickViewOption',
    ClickReceivingTab = 'clickReceivingTab',
    ExportCsv = 'exportCsv',
    ExportExcel = 'exportExcel',
    Email = 'email',
    LineItemMemo = 'lineItemMemo',
    ExportExceptionReport = 'exportExceptionReport',
    SortExceptionReport = 'sortExceptionReport',
    FilterExceptionReport = 'filterExceptionReport',
    TextFilterAplData = 'textFilterAplData',
    ToggleAplExpansion = 'toggleAplExpansion',
    AplStoreChange = 'aplStoreChange',
    ExportAplData = 'exportAplData',
    ClickOrderPlusStatus = 'clickOrderPlusStatus',
    CopyStore = 'copyStore',
    SubmitOrderFromOrderList = 'submitOrder:orderList',
    SubmitOrderFromOrderDetailDropdown = 'submitOrder:orderDetailDropdown',
    SubmitOrderFromOrderDetail = 'submitOrder:orderDetailButton',
    ViewOrderHistory = 'viewOrderHistory',
    ViewMovementData = 'viewMovementData',
    ClickSupplierManagement = 'clickSupplierManagement',
    DownloadSupplierCatalog = 'downloadSupplierCatalog',
    ClickRankSuppliers = 'clickRankSuppliers',
    ClickSupplierMapping = 'clickSupplierMapping',
    ClickSupplierDetailLink = 'clickSupplierDetailLink',
    ClickAddSupplierAccount = 'clickAddSupplierAccount',
    ClickAddSupplierDiscount = 'clickAddSupplierDiscount',
    ChangeSelectedStore = 'changeSelectedStore',
    ClickSaveSupplierThreshold = 'clickSaveSupplierThreshold',
    ClickRestoreSupplierThreshold = 'clickRestoreSupplierThreshold',
    ClickRemoveSupplierContact = 'clickRemoveSupplierContact',
    ClickAddSupplierContact = 'clickAddSupplierContact',
    ClickRequestSupplierFromMenu = 'clickRequestSupplierFromMenu',
    ClickSubmitRequestSupplier = 'clickSubmitRequestSupplier',
    ClickCancelRequestSupplier = 'clickCancelRequestSupplier',
    ClickRequestSupplierFromSearch = 'clickRequestSupplierFromSearch',
    NoSupplierResults = 'noSupplierResults',
    ClickSavedItemsMenuItem = 'clickMyFavoritesMenuItem',
    ClickAddSavedItemToOrder = 'clickAddSavedItemToOrder',
    ClickOrderSavedItem = 'clickOrderSavedItem',
    ClickSupplierLink = 'clickSupplierLink',
    ClickEditDeadline = 'clickEditDeadline',
    ClickItemQuantityAlertSetting = 'clickItemQuantityAlertSetting',
    ClickSetAlertDialogSave = 'clickSetAlertDialogSave',
    ClickSetAlertDialogCancel = 'clickSetAlertDialogCancel',
    ClickSetAlertDialogDelete = 'clickSetAlertDialogDelete',
    ClickEditItemQuantityAlertSetting = 'clickEditItemQuantityAlertSetting',
    ClickAddItemQuantityAlertSetting = 'clickAddItemQuantityAlertSetting',
    HoverItemQuantityAlertIcon = 'hoverItemQuantityAlertIcon',
}

export enum AnalyticsDimensionType {
    UserGroup,
    AppRole,
    UserId,
    ReleaseRole,
    BuildVersion,
    ChainStoreId
}

export class AnalyticsDimension {
    type: AnalyticsDimensionType;
    key: string;
    value: string;

    constructor(options?: Partial<AnalyticsDimension>) {
        Object.assign(this, options);
    }
}

export const defaultDimensionValue = 'default';

export const googleAnalyticsDimensionKey = new Map<AnalyticsDimensionType, string>([
    [AnalyticsDimensionType.UserGroup, 'dimension1'],
    [AnalyticsDimensionType.AppRole, 'dimension2'],
    [AnalyticsDimensionType.UserId, 'dimension3'],
    [AnalyticsDimensionType.ReleaseRole, 'dimension4'],
    [AnalyticsDimensionType.BuildVersion, 'dimension5'],
    [AnalyticsDimensionType.ChainStoreId, 'dimension6']
]);

export const analyticsDefaultDimensionKey = new Map<AnalyticsDimensionType, string>([
    [AnalyticsDimensionType.UserGroup, 'userGroup'],
    [AnalyticsDimensionType.AppRole, 'appRole'],
    [AnalyticsDimensionType.UserId, 'userId'],
    [AnalyticsDimensionType.ReleaseRole, 'releaseRole'],
    [AnalyticsDimensionType.BuildVersion, 'buildVersion'],
    [AnalyticsDimensionType.ChainStoreId, 'storeId']
]);

<mat-form-field class="example-chip-list">
    <mat-label *ngIf="label">{{label}}</mat-label>
    <mat-chip-grid #chipList
                   [disabled]="formArray.disabled">
        <mat-chip-row *ngFor="let control of formArray.controls; let index = index"
                      [disabled]="formArray.disabled"
                      [removable]="formArray.enabled"
                      (removed)="removeChip(index)">
            {{control.value.displayName}}
            <mat-icon *ngIf="formArray.enabled"
                      matChipRemove>
                cancel
            </mat-icon>
        </mat-chip-row>
        <input #input
               #autoTrigger="matAutocompleteTrigger"
               (click)="onClickTrigger()"
               [placeholder]="placeholder"
               [formControl]="formControl"
               [matAutocomplete]="auto"
               [matChipInputFor]="chipList"
               [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
    </mat-chip-grid>
    <mat-autocomplete #auto="matAutocomplete"
                      (optionSelected)="optionSelected($event)">
        <mat-option *ngFor="let option of filteredOptions"
                    [value]="option">
            {{option.displayName}}
        </mat-option>
    </mat-autocomplete>
</mat-form-field>

export class Icon {
    name: string;
    location: string;

    constructor(options?: Partial<Icon>) {
        Object.assign(this, options);
    }
}

export class IconRegistry {
    [key: string]: Icon;

    constructor(options?: Partial<IconRegistry>) {
        Object.assign(this, options);
    }
}

export const customIcons = new IconRegistry({
    dollarStarBurst: new Icon({
        name: 'dollar_star_burst',
        location: 'assets/dollar-star-burst.svg'
    })
});

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '@hq-core/models/app-settings';
import { DataResource } from '@hq-custom-types';
import { Observable } from 'rxjs';
import { CustomerLead } from '../models/customer-lead';

@Injectable({
    providedIn: 'root'
})
export class FreeTrialCustomerLeadResource implements DataResource<CustomerLead> {
    private readonly baseUrl: string;

    constructor(private http: HttpClient, private appSettings: AppSettings) {
        this.baseUrl = `${this.appSettings.apis.user}`;
    }

    create(customerLead: CustomerLead, emailSubject: string): Observable<CustomerLead> {
        const url = `${this.baseUrl}/send-customer-lead?emailSubject=${emailSubject}`;
        return this.http.post<CustomerLead>(url, customerLead);
    }

    createUser(customerLead: CustomerLead): Observable<CustomerLead> {
        const url = `${this.baseUrl}/free-trial`;
        return this.http.post<CustomerLead>(url, customerLead);
    }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { defaultThemeLogoUrl, orderCentralThemeLogoUrl } from '@hq-core/models/theme';
import { NavigationService } from '@hq-core/navigation/navigation.service';
import { routeParts } from '@hq-core/routes';
import { ThemeService } from '@hq-core/theme/theme.service';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'hq-page-title',
    templateUrl: './page-title.component.html',
    styleUrls: ['./page-title.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageTitleComponent implements OnInit, OnDestroy {
    title: string;
    logoUrl: string;
    dashboardRoute = `${routeParts.dashboard}`;
    isDefaultLogo = false;
    isOrderCentral = false;
    private unsubscribe = new Subject<void>();

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private cdRef: ChangeDetectorRef,
        private themeService: ThemeService,
        private navigationService: NavigationService
    ) { }

    ngOnInit(): void {
        this.logoUrl = defaultThemeLogoUrl;

        this.router.events
            .pipe(
                filter((event: any) => event instanceof NavigationEnd),
                takeUntil(this.unsubscribe)
            )
            .subscribe(() => {
                const routeData = this.navigationService.findRouteData(this.activatedRoute.snapshot);
                if (routeData.hasOwnProperty('title')) {
                    this.title = routeData.title;
                    this.cdRef.detectChanges();
                }
            });

        this.themeService.getTheme()
            .pipe(
                takeUntil(this.unsubscribe)
            )
            .subscribe(theme => {
                this.logoUrl = theme.logoUrl;
                this.isDefaultLogo = this.logoUrl === defaultThemeLogoUrl;
                this.isOrderCentral = theme.logoUrl === orderCentralThemeLogoUrl;
                this.cdRef.detectChanges();
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}

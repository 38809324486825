import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from '@hq-app/app.module';
import { Amplify } from '@aws-amplify/core';
import { environment } from '@hq-environments/environment';

if (environment.useProductionMode) {
    enableProdMode();
}

Amplify.configure({
    Auth: {
        region: environment.region,
        identityPoolId: environment.identityPoolId,
        userPoolId: environment.userPoolId,
        userPoolWebClientId: environment.cognitoClientId,
        authenticationFlowType: 'USER_PASSWORD_AUTH'
    }
});

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => {
        // eslint-disable-next-line no-console
        console.log(err);
    });

import { ClientOrderStatus } from '@hq-app/ordering/models/order-status';
import { FormFieldConfiguration } from '@hq-core/models/forms';
import { ActionGroups } from '@hq-shared/models/action-item';

export enum OrderStepId {
    Acknowledgement = 'acknowledgement',
    ShippingNotice = 'shippingNotice',
    Invoice = 'invoice',
    PurchaseOrder = 'purchaseOrder'
}

export class OrderStepConfiguration {
    id: OrderStepId;
    position = Number.MAX_SAFE_INTEGER;
    disablesAfterCompletion = new Array<OrderStepId>();

    constructor(options?: Partial<OrderStepConfiguration>) {
        Object.assign(this, options);
    }
}

export class OrderStep {
    id: OrderStepId;
    label: string;
    position = Number.MAX_SAFE_INTEGER;
    isCompleted = false;
    isEnabled = false;
    disablesAfterCompletion = new Array<OrderStepId>();

    constructor(options?: Partial<OrderStep>) {
        Object.assign(this, options);
    }
}

export const stepIdToDisplayLabels = new Map<OrderStepId, string>([
    [OrderStepId.Acknowledgement, 'Review'],
    [OrderStepId.ShippingNotice, 'Ship'],
    [OrderStepId.Invoice, 'Invoice'],
    [OrderStepId.PurchaseOrder, 'Order']
]);

export const lastCompletedStepIdForStatus = new Map<ClientOrderStatus, OrderStepId>([
    [ClientOrderStatus.Accepted, OrderStepId.Acknowledgement],
    [ClientOrderStatus.Shipped, OrderStepId.ShippingNotice],
    [ClientOrderStatus.Invoiced, OrderStepId.Invoice]
]);

export class SectionConfiguration {
    isRequired = false;
    isReadOnly = false;

    constructor(options?: Partial<SectionConfiguration>) {
        Object.assign(this, options);
    }
}

export class OptionConstraints {
    options: Array<string>;
    maxUse: number;

    constructor(options?: Partial<OptionConstraints>) {
        Object.assign(this, options);
    }
}

export class BuyerFieldConfiguration extends FormFieldConfiguration {
    disableIncreases?: boolean;
    constraints: Array<OptionConstraints>;

    constructor(options?: Partial<BuyerFieldConfiguration>) {
        super(options);
        Object.assign(this, options);
    }
}

export class ContactInformationConfiguration extends SectionConfiguration {
    name: BuyerFieldConfiguration;
    dunsNumber: BuyerFieldConfiguration;
    address1: BuyerFieldConfiguration;
    address2: BuyerFieldConfiguration;
    city: BuyerFieldConfiguration;
    state: BuyerFieldConfiguration;
    country: BuyerFieldConfiguration;
    postalCode: BuyerFieldConfiguration;
    phone: BuyerFieldConfiguration;

    constructor(options?: Partial<ContactInformationConfiguration>) {
        super(options);
        Object.assign(this, options);
    }
}

export class BillingTermsConfiguration extends SectionConfiguration {
    description: BuyerFieldConfiguration;
    discountDaysDue: BuyerFieldConfiguration;
    discountPercent: BuyerFieldConfiguration;
    netDueDate: BuyerFieldConfiguration;

    constructor(options?: Partial<BillingTermsConfiguration>) {
        super(options);
        Object.assign(this, options);
    }
}

export class AllowanceChargeConfiguration extends SectionConfiguration {
    type = new BuyerFieldConfiguration();
    handling = new BuyerFieldConfiguration();
    amountType = new BuyerFieldConfiguration();
    amount = new BuyerFieldConfiguration();
    description = new BuyerFieldConfiguration();

    constructor(options?: Partial<AllowanceChargeConfiguration>) {
        super(options);
        Object.assign(this, options);
    }
}

export class PriceAdjustmentsConfiguration extends SectionConfiguration {
    allowance = new AllowanceChargeConfiguration();
    charge = new AllowanceChargeConfiguration();
    allowanceOrCharge = new BuyerFieldConfiguration();
    calculatedFromSubTotal = new Array<string>();

    constructor(options?: Partial<PriceAdjustmentsConfiguration>) {
        super(options);
        Object.assign(this, options);
    }
}

export class TransitDetailsConfiguration extends SectionConfiguration {
    transitType: BuyerFieldConfiguration;
    transitDate: BuyerFieldConfiguration;
    transitChangeMessage: BuyerFieldConfiguration;

    constructor(options?: Partial<TransitDetailsConfiguration>) {
        super(options);
        Object.assign(this, options);
    }
}

export class TaxesConfiguration extends SectionConfiguration {
    taxType: BuyerFieldConfiguration;
    amount: BuyerFieldConfiguration;

    constructor(options?: Partial<TaxesConfiguration>) {
        super(options);
        Object.assign(this, options);
    }
}

export class LineItemConfiguration {
    actions: Array<ActionGroups>;
    memo: BuyerFieldConfiguration;
    quantity: BuyerFieldConfiguration;
    rejectionMessages: Array<string>;
    priceAdjustments = new PriceAdjustmentsConfiguration();

    constructor(options?: Partial<LineItemConfiguration>) {
        Object.assign(this, options);
    }
}

export class CarrierConfiguration extends SectionConfiguration {
    name: BuyerFieldConfiguration;

    constructor(options?: Partial<CarrierConfiguration>) {
        super(options);
        Object.assign(this, options);
    }
}

export class ShipmentConfiguration extends SectionConfiguration {
    carrier: BuyerFieldConfiguration;
    shipDate: BuyerFieldConfiguration;
    estimatedDeliveryDate: BuyerFieldConfiguration;
    packagingType: BuyerFieldConfiguration;
    packageCount: BuyerFieldConfiguration;
    weight: BuyerFieldConfiguration;
    weightUnit: BuyerFieldConfiguration;
    trackingNumber: BuyerFieldConfiguration;
    barCodeNumber: BuyerFieldConfiguration;

    constructor(options?: Partial<ShipmentConfiguration>) {
        super(options);
        Object.assign(this, options);
    }
}

export class InvoiceShipmentConfiguration extends SectionConfiguration {
    packageCount: BuyerFieldConfiguration;
    unitOfMeasure: BuyerFieldConfiguration;
    weight: BuyerFieldConfiguration;
    weightUnit: BuyerFieldConfiguration;

    constructor(options?: Partial<InvoiceShipmentConfiguration>) {
        super(options);
        Object.assign(this, options);
    }
}

export class ShippingNoticeConfiguration {
    adminContact = new ContactInformationConfiguration();
    shipFrom = new ContactInformationConfiguration();
    shipment = new ShipmentConfiguration();

    constructor(options?: Partial<ShippingNoticeConfiguration>) {
        Object.assign(this, options);
    }
}

export class InvoiceConfiguration {
    adminContact = new ContactInformationConfiguration();
    remitTo = new ContactInformationConfiguration();
    billingTerms = new BillingTermsConfiguration();
    priceAdjustments = new PriceAdjustmentsConfiguration();
    taxes = new TaxesConfiguration();
    lineItems = new LineItemConfiguration();
    memo: BuyerFieldConfiguration;
    shipmentSummary = new InvoiceShipmentConfiguration();

    constructor(options?: Partial<InvoiceConfiguration>) {
        Object.assign(this, options);
    }
}

export class ReadyToShipConfiguration extends SectionConfiguration {
    packagingType: BuyerFieldConfiguration;
    packageCount: BuyerFieldConfiguration;
    weight: BuyerFieldConfiguration;
    weightUnit: BuyerFieldConfiguration;
    volume: BuyerFieldConfiguration;
    volumeUnit: BuyerFieldConfiguration;

    constructor(options?: Partial<ReadyToShipConfiguration>) {
        super(options);
        Object.assign(this, options);
    }
}

export class AcknowledgementConfiguration {
    shipFrom = new ContactInformationConfiguration();
    lineItems = new LineItemConfiguration();
    transitDetails = new TransitDetailsConfiguration();
    carrier = new CarrierConfiguration();
    readyToShip = new ReadyToShipConfiguration();

    constructor(options?: Partial<AcknowledgementConfiguration>) {
        Object.assign(this, options);
    }
}

export class MetaConfiguration {
    steps = new Array<OrderStepConfiguration>();

    constructor(options?: Partial<MetaConfiguration>) {
        Object.assign(this, options);
    }
}

export class BuyerConfiguration {
    meta = new MetaConfiguration();
    invoice = new InvoiceConfiguration();
    acknowledgement = new AcknowledgementConfiguration();
    shippingNotice = new ShippingNoticeConfiguration();

    constructor(options?: Partial<BuyerConfiguration>) {
        Object.assign(this, options);
    }
}

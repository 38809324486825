import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { defaultFormChangeDebounceTime } from '@hq-core/models/forms';
import { TextFilterChangeEvent } from '@cia-front-end-apps/shared/api-interaction';
import { Subject } from 'rxjs';
import { debounceTime, filter, map, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'hq-text-filter',
    templateUrl: './text-filter.component.html',
    styleUrls: ['./text-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextFilterComponent implements OnInit, OnDestroy, OnChanges {
    @Input() isDisabled: boolean;
    @Input() placeholder: string;
    @Input() textOverride: string;

    @Output() changeFilter = new EventEmitter<TextFilterChangeEvent>();

    readonly filterControl = new FormControl('');
    private currentText: string;
    private readonly defaultText = '';
    private readonly unsubscribe = new Subject<void>();

    ngOnInit(): void {
        this.currentText = this.defaultText;

        this.filterControl.valueChanges
            .pipe(
                debounceTime(defaultFormChangeDebounceTime),
                map((input: string) => {
                    return input.trim();
                }),
                filter((input: string) => input !== this.currentText),
                takeUntil(this.unsubscribe)
            )
            .subscribe((input: string) => {
                this.currentText = input;

                const event = new TextFilterChangeEvent({
                    text: input
                });

                this.changeFilter.emit(event);
            });

        if (this.isDisabled) {
            this.filterControl.disable();
        }

        if (this.textOverride) {
            this.applyTextOverride(this.textOverride);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.isDisabled && !changes.isDisabled.firstChange) {
            this.filterControl.enable();

            if (this.isDisabled) {
                this.filterControl.disable();
            }
        }

        if (changes.textOverride && !changes.textOverride.firstChange) {
            this.applyTextOverride(this.textOverride);
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    onClearFilter(): void {
        this.filterControl.setValue(this.defaultText);
    }

    /** @description Applies a text override without triggering an event. Overrides are ignored if
     * they already match the current text value
     * @param overrideText The text to set as the filter's value
     */
    private applyTextOverride(overrideText: string): void {
        const textOverride = overrideText || this.defaultText;
        if (textOverride !== this.currentText) {
            this.filterControl.setValue(textOverride, { emitEvent: false });
            this.currentText = textOverride;
        }
    }
}

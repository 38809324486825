import { Injectable } from '@angular/core';
import { DateService } from '@hq-core/date/date.service';
import { UserGroup } from '@hq-core/models/user-group';
import { ApiCollectionResponse, SearchState } from '@cia-front-end-apps/shared/api-interaction';
import { Observable } from 'rxjs';
import { Message, MessageDestination, messageDestinationName, MessageParameters, MessageStatus } from '../models/messages';
import { MessageResource } from '../resources/message.resource';

@Injectable({
    providedIn: 'root'
})
export class MessageService {

    constructor(
        private messageResource: MessageResource,
        private dateService: DateService
    ) {
    }

    getMessages(searchState: SearchState, messageParameters: MessageParameters): Observable<ApiCollectionResponse<Message>> {
        return this.messageResource.getAll(searchState, messageParameters);
    }

    createMessage(message: Message): Observable<Message> {
        return this.messageResource.create(message);
    }

    updateMessage(messageId: number, message: Message): Observable<Message> {
        return this.messageResource.replace(messageId, message);
    }

    getStatus(message: Message): MessageStatus {
        let status = MessageStatus.Active;

        if (this.dateService.isPastDate(message.endDate)) {
            status = MessageStatus.Inactive;
        } else if (this.dateService.isFutureDate(message.startDate)) {
            status = MessageStatus.Scheduled;
        }

        return status;
    }

    getDestinationNames(message: Message): string {
        const destinations = message.destinations.length > 0 ?
            message.destinations :
            [MessageDestination.All];

        return destinations
            .filter(destination => MessageDestination[destination] !== undefined)
            .map(destination => messageDestinationName.get(destination))
            .join(', ');
    }

    getDestinationForUserGroup(userGroup: UserGroup): MessageDestination {
        let destination = MessageDestination.All;

        switch (userGroup) {
            case UserGroup.OrderCentral:
                destination = MessageDestination.OrderCentral;
                break;
            case UserGroup.SalesGenius:
                destination = MessageDestination.SalesGenius;
                break;
            case UserGroup.Edi:
                destination = MessageDestination.GcEdi;
                break;
        }

        return destination;
    }
}

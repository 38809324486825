<mat-form-field subscriptSizing="dynamic">
    <mat-label>{{ placeholder }}</mat-label>
    <input matInput
           type="text"
           [formControl]="filterControl" />
    <button *ngIf="filterControl.value"
            mat-icon-button
            matSuffix
            aria-label="Clear"
            (click)="onClearFilter()">
        <mat-icon>close</mat-icon>
    </button>
</mat-form-field>

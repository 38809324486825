import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, RouterStateSnapshot, UrlSegment } from '@angular/router';
import { AuthImpersonationService } from '@hq-core/auth/auth-impersonation.service';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard  {
    constructor(
        private authService: AuthService,
        private authImpersonationService: AuthImpersonationService,
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const url: string = state.url;

        return this.isAuthenticated(url);
    }

    canLoad(route: Route, segments: Array<UrlSegment>): Observable<boolean> {
        const url = segments.map(segment => segment.path).join('/');

        return this.isAuthenticated(url);
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const url: string = state.url;

        return this.isAuthenticated(url);
    }

    isAuthenticated(url: string): Observable<boolean> {
        return forkJoin({
            isAuthenticated: this.authService.isAuthenticated(),
            isImpersonating: of(this.authImpersonationService.isImpersonating())
        })
            .pipe(
                map(({ isAuthenticated, isImpersonating }) => {
                    if (!isAuthenticated) {
                        this.authImpersonationService.clearImpersonationToken();
                        this.authService.redirectToLogin(url);
                        return false;
                    }

                    if (isImpersonating && !this.authImpersonationService.isAuthenticated()) {
                        this.authImpersonationService.redirectToUserEditor();
                        return false;
                    }

                    return true;
                })
            );
    }
}
